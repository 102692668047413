import React, { FC } from 'react';
import { IShopSelector } from '@tapestry/shared/graphql';
import {
  calcBannerColor,
  calcShopOffline,
  calcMinutesSinceLastSynced,
  getReadableDuration,
} from './ShopOfflineBannerUtils';

export const StoreOfflineBanner: FC<{
  shopId: string;
  query: IShopSelector;
}> = ({ shopId, query }) => {
  const activeShop = query.shopSelector?.allShops.find(
    (shop) => shop.id === shopId
  );

  const lastSynced = calcMinutesSinceLastSynced(activeShop);
  const shopIsOffline = calcShopOffline(lastSynced);
  const bannerColor = calcBannerColor(lastSynced);
  const readableDurationSincSync = getReadableDuration(lastSynced);

  return shopIsOffline ? (
    <div className={`${bannerColor} px-4 py-2 text-center text-white`}>
      Store has not been synced for {readableDurationSincSync}
    </div>
  ) : null;
};
