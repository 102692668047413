import { FC, useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import {
  BellIcon,
  CalendarIcon,
  CloseIcon,
  TasksIcon,
  TickIcon,
  ExclamationIcon,
  InfoIcon,
} from '@tapestry/shared/icons';
import { dateTime, isISOString } from '@tapestry/shared/utils';
import {
  IToastNotification,
  ToastNotificationType,
  ToastNotificationTypeOption,
} from '@tapestry/types';
import { Avatar } from '../Avatar';

interface IToastProps {
  notification: IToastNotification;
  removeToast: (id: string) => void;
}

const getIcon = (type: ToastNotificationTypeOption) => {
  const iconMapper = {
    [ToastNotificationType.TaskCreated]: TasksIcon,
    [ToastNotificationType.TaskCompleted]: TickIcon,
    [ToastNotificationType.taskDeleted]: TickIcon,
    [ToastNotificationType.TaskOverdue]: CalendarIcon,
    [ToastNotificationType.Reminder]: BellIcon,
    [ToastNotificationType.Assigned]: TasksIcon,
    [ToastNotificationType.Error]: ExclamationIcon,
    [ToastNotificationType.Info]: InfoIcon,
  };
  return iconMapper[type];
};

const getIconBackgroundColor = (type: ToastNotificationTypeOption) => {
  const colorMapper = {
    [ToastNotificationType.TaskCreated]: 'bg-pink',
    [ToastNotificationType.TaskCompleted]: 'bg-green',
    [ToastNotificationType.taskDeleted]: 'bg-green',
    [ToastNotificationType.TaskOverdue]: 'bg-red',
    [ToastNotificationType.Reminder]: 'bg-pink',
    [ToastNotificationType.Assigned]: 'bg-pink',
    [ToastNotificationType.Error]: 'bg-red',
    [ToastNotificationType.Info]: 'bg-gray-900',
  };
  return colorMapper[type];
};

const Toast: FC<IToastProps> = ({
  notification: { content, type, id, avatarUrl },
  removeToast,
}) => {
  const [timerWidth, settimerWidth] = useState(5);

  const { title, textContent } = getText(type, content);
  const IconComponent = getIcon(type);
  const IconBackgroundColor = getIconBackgroundColor(type);

  // when you mount => start a 5 second timer
  // at the end, remove yourself from the list
  useEffect(() => {
    const timeId = setTimeout(() => {
      if (!id) return;
      removeToast(id);
    }, 5000);

    const intervalId = setInterval(() => {
      settimerWidth((prevWidth) => prevWidth - 1);
    }, 800);

    return () => {
      clearTimeout(timeId);
      clearInterval(intervalId);
    };
  }, []);

  return (
    <Transition
      show
      appear
      enter=" ease-out duration-500 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className={`pointer-events-auto w-full max-w-xs overflow-hidden rounded-lg border border-gray-300 bg-white shadow-lg`}
    >
      <div className="p-4 pb-3">
        <div className="flex items-center justify-between">
          <div className="flex w-full items-center">
            {type === ToastNotificationType.Assigned ? (
              <div className="relative">
                <Avatar src={avatarUrl} />
                <div
                  className={`min-w-6 absolute bottom-0  right-0 flex h-6 w-6 flex-shrink-0 translate-y-2 items-center justify-center rounded-full p-1 ${IconBackgroundColor}`}
                >
                  <IconComponent fillColor="#fff" />
                </div>
              </div>
            ) : (
              <div
                className={`min-w-10 flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full p-2 ${IconBackgroundColor}`}
              >
                <IconComponent
                  fillColor="#fff"
                  light={type === ToastNotificationType.TaskCompleted}
                />
              </div>
            )}

            <div className="ml-2 w-0 flex-1">
              <p className={`text-sm font-bold`}>{title || ''}</p>

              <p className={`text-xxs text-gray-text mt-1`}>
                {textContent || content}
              </p>
            </div>
          </div>

          <div className="flex flex-shrink-0">
            <button
              className="inline-flex h-5 w-5 items-center justify-center rounded-full"
              onClick={() => {
                if (!id) return;
                removeToast(id);
              }}
            >
              <span className="sr-only">Close</span>
              <CloseIcon fillColor="#889aa4" light />
            </button>
          </div>
        </div>
      </div>

      {/* countdown- when updrade to JIT => use dynamic props => w-[timerWidth]/5 */}
      <div
        className={`h-1 rounded-lg bg-gray-700 transition-all ease-linear`}
        style={{ width: `${timerWidth * 20}%`, transitionDuration: '1s' }}
      />
    </Transition>
  );
};

/** -----------------------------------
 *  The Utils for the component
 ------------ */
const getText = (
  type: ToastNotificationTypeOption,
  content: IToastNotification['content']
) => {
  switch (type) {
    case ToastNotificationType.TaskCreated:
      return {
        title: 'Task successfully created',
        textContent: content || 'Just now',
      };

    case ToastNotificationType.TaskCompleted:
      return {
        title: 'Mark as completed',
        textContent: content,
      };
    case ToastNotificationType.taskDeleted:
      return {
        title: 'Task successfully deleted',
        textContent: content,
      };

    case ToastNotificationType.TaskOverdue:
      return {
        title: 'Task overdue',
        textContent: content,
      };

    case ToastNotificationType.Reminder:
      return {
        title: 'Reminder set',
        textContent:
          content && isISOString(content) ? dateTime.format(content) : '',
      };

    case ToastNotificationType.Assigned:
      return {
        title: 'Task assigned',
        textContent: `Assigned to ${content || 'user'}`,
      };

    case ToastNotificationType.Info:
      return {
        title: 'Did you know',
        textContent: content,
      };

    case ToastNotificationType.Error:
      return {
        title: 'Something went wrong',
        textContent: content || 'An error has occured',
      };

    default:
      return {};
  }
};

export { Toast };
