import { Transition } from '@headlessui/react';
import React, { FunctionComponent } from 'react';
import clx from 'classnames';
import { useOverlay, usePreventScroll, useModal } from '@react-aria/overlays';
import { useDialog } from '@react-aria/dialog';
import { FocusScope } from '@react-aria/focus';

interface IModalPanelProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  isDismissable?: boolean;
  className?: string;
  maxWidth?: 'md' | 'lg' | 'xl';
  noOverlay?: boolean;
}

const getMaxWidth = (width: string) => {
  switch (width) {
    case 'md':
      return 'max-w-screen-md';

    case 'lg':
      return 'max-w-screen-lg';

    case 'xl':
      return 'max-w-screen-xl';

    default:
      return 'max-w-screen-lg';
  }
};

// ! Remove the any in the props when you know how to fix it
export const ModalOverlay: FunctionComponent<
  React.PropsWithChildren<IModalPanelProps>
> = (props) => {
  const {
    children,
    className,
    isOpen,
    onClose,
    title,
    maxWidth = '',
    noOverlay = false,
  } = props;

  // Handle interacting outside the dialog and pressing
  // the Escape key to close the modal.
  const modalRef = React.useRef(null);
  const { overlayProps } = useOverlay(props, modalRef);

  // Prevent scrolling while the modal is open, and hide content
  // outside the modal from screen readers.
  usePreventScroll();
  const { modalProps } = useModal();

  // Get props for the dialog and its title
  const { dialogProps, titleProps } = useDialog(props as any, modalRef);

  const _maxWith = getMaxWidth(maxWidth);

  return (
    <Transition appear show={isOpen || false}>
      <Transition.Child
        enter="transition ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="fixed inset-0 flex items-center justify-center overflow-y-scroll"
        style={{
          zIndex: 9998,
        }}
      >
        {/* The overlay */}
        {!noOverlay && <div className="fixed inset-0 bg-black opacity-25" />}

        <div className="z-10 h-full w-full">
          {/* The panel */}
          <Transition.Child
            enter="transition ease-out duration-300"
            enterFrom="opacity-0 -translate-y-32"
            enterTo="opacity-100 -translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 -translate-y-0"
            leaveTo="opacity-0 -translate-y-32"
            className={clx('mx-auto', _maxWith, className)}
            {...dialogProps}
          >
            {/* eslint-disable jsx-a11y/no-autofocus */}
            <FocusScope contain restoreFocus autoFocus>
              <div
                className="w-full"
                {...overlayProps}
                {...modalProps}
                ref={modalRef}
              >
                <h3 {...titleProps} className="sr-only">
                  {title || ''}
                </h3>

                {children}
              </div>
            </FocusScope>
          </Transition.Child>
        </div>
      </Transition.Child>
    </Transition>
  );
};

export default ModalOverlay;
