import React, { FC, FunctionComponent, useEffect, useState } from 'react';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  LoadingSpinnerIcon,
} from '@tapestry/shared/icons';
import { Transition } from '@headlessui/react';
import Skeleton from 'react-loading-skeleton';
import { useAppMediaQuery, useEffectAfterMount } from '@tapestry/shared/hooks';
import isEmpty from 'lodash/isEmpty';
import { Nullable } from '@tapestry/types';

interface IListItemProps {
  onClick?: any;
  title: string;
  logo: string;
  placeholderIfNoImage?: any;
  optionsAsLink?: string;
}

export const HeartbeatHeaderMenuItemDropdownListItem: FC<
  React.PropsWithChildren<IListItemProps>
> = ({ onClick, title, logo, placeholderIfNoImage, optionsAsLink }) => {
  const HtmlTag = optionsAsLink ? 'a' : 'li';
  return (
    <HtmlTag
      href={optionsAsLink}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      className="hover:bg-orange-lightest focus:bg-orange-lightest flex cursor-pointer flex-row items-center rounded-md p-2 text-sm font-bold transition-colors duration-150 ease-in-out focus:outline-none sm:border-b-0"
    >
      {logo && (
        <span className="min-w-6 mr-4 flex h-6 w-6 items-center justify-center overflow-hidden rounded-full bg-gray-200">
          {typeof logo === 'string' ||
          typeof placeholderIfNoImage === 'string' ? (
            <img
              src={logo || placeholderIfNoImage}
              alt="shop's logo"
              className=" bg-gray-400"
            />
          ) : (
            logo || placeholderIfNoImage
          )}
        </span>
      )}

      <span title={title} className="truncate">
        {title}
      </span>
    </HtmlTag>
  );
};

interface IDropdownProps {
  open: boolean;
  floatDropdownRight?: boolean;
  containerClassName?: string;
}

export const HeartbeatHeaderMenuItemDropdown: FunctionComponent<
  React.PropsWithChildren<IDropdownProps>
> = ({
  open,
  floatDropdownRight = false,
  children = null,
  containerClassName,
}) => {
  return (
    <Transition
      show={open}
      enter="transition ease-out duration-500"
      enterFrom="opacity-0 -translate-y-10"
      enterTo="opacity-100 -translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 -translate-y-0"
      leaveTo="opacity-0 -translate-y-10"
      className={`min-w-40  absolute z-50 w-full rounded-b-lg bg-white shadow-md ${
        floatDropdownRight ? 'right-0' : 'left-0'
      }`}
      style={{ zIndex: 60 }}
    >
      <ul
        className={`tapestry-scrollbar max-h-sm overflow-y-scroll p-2 font-semibold ${containerClassName}`}
      >
        {children}
      </ul>
    </Transition>
  );
};

const getDefaultOption = (
  options: any[],
  defaultIdOption: Nullable<string | number> | undefined
) => {
  const defaultObject = defaultIdOption
    ? options.find((option: { id: string }) => option?.id === defaultIdOption)
    : options[0];
  return defaultObject ?? options[0];
};

const getStyles = (status: IHeartbeatHeaderMenuItemProps['status']) => {
  let labelStyle: string;
  let subLabelStyle: string;
  let iconStyle: string;

  switch (status) {
    case 'primary':
      labelStyle = 'text-black font-bold text-base sm:text-lg tracking-wide';
      subLabelStyle = 'text-gray text-xs sm:text-sm font-normal';
      iconStyle = 'h-10 w-10 sm:h-15 sm:w-15 min-w-10 sm:min-w-15';
      break;

    case 'secondary':
      labelStyle = 'text-black font-bold text-base';
      subLabelStyle = 'text-gray text-sm font-normal';
      iconStyle = 'h-8 w-8 min-w-8';
      break;

    case 'tertiary':
      labelStyle = 'text-gray font-normal text-base';
      subLabelStyle = 'text-gray text-xs font-normal';
      iconStyle = 'h-8 w-8 min-w-8';
      break;

    default:
      labelStyle = 'text-black font-bold text-base';
      subLabelStyle = 'text-gray text-sm font-normal';
      iconStyle = 'h-8 w-8 min-w-8';
      break;
  }

  return { labelStyle, subLabelStyle, iconStyle };
};

interface IHeartbeatHeaderMenuItemProps {
  subHeader?: string;
  options: any[];
  defaultIdOption?: string | null | number;
  hasImage?: boolean;
  onChange: (id: null | string | number) => void;
  status?: 'primary' | 'secondary' | 'tertiary';
  resetInternalStateOnDefaultOptionChange?: boolean; // reset internal state whenever defaultIdOption change
  isLoading?: boolean;
  placeholderIfNoImage?: string | Node;
  noMobileStyle?: boolean;
  optionsAsLink?: (id: string) => string;
  noHorizontalPadding?: boolean;
  labelClassName?: string;
  labelOverwriteStyle?: Record<string, unknown>;
  buttonClassName?: string;
  floatDropdownRight?: boolean;
}

export const HeartbeatHeaderMenuItem = ({
  subHeader,
  options = [],
  defaultIdOption,
  onChange,
  hasImage = false,
  status = 'primary',
  resetInternalStateOnDefaultOptionChange = false, // reset internal state whenever defaultIdOption change
  isLoading = false,
  placeholderIfNoImage,
  noMobileStyle,
  optionsAsLink,
  noHorizontalPadding,
  labelClassName,
  labelOverwriteStyle = {},
  buttonClassName,
  floatDropdownRight,
}: IHeartbeatHeaderMenuItemProps) => {
  const { isPhone } = useAppMediaQuery();
  const [open, setOpen] = useState(false);
  const [activeElement, setActiveElement] = useState(
    getDefaultOption(options, defaultIdOption)
  );

  const { labelStyle, subLabelStyle, iconStyle } = getStyles(status);

  // Keeps it in sync whith possible external state
  useEffect(() => {
    if (!resetInternalStateOnDefaultOptionChange) return;
    setActiveElement(getDefaultOption(options, defaultIdOption));
  }, [defaultIdOption, resetInternalStateOnDefaultOptionChange]);

  // Helps with async fetching of options
  useEffect(() => {
    if (isLoading || isEmpty(options)) return;
    setActiveElement(getDefaultOption(options, defaultIdOption));
  }, [isLoading]);

  useEffectAfterMount(() => {
    // the async fetch of options was trigerring the onChange even if the element was the same.
    // This guards against that
    if (
      resetInternalStateOnDefaultOptionChange &&
      activeElement.id === defaultIdOption
    )
      return;

    onChange(activeElement?.id || null);
    setOpen(false);
  }, [activeElement]);

  return (
    <div
      className={`relative w-full bg-transparent transition-colors duration-300 ease-in-out sm:w-auto ${
        open
          ? 'rounded-t-lg sm:bg-white'
          : `border-gray-200 ${
              noMobileStyle ? 'border-none' : 'border-b-2 sm:border-none'
            }`
      } ${noHorizontalPadding ? '' : 'px-4'}`}
      onMouseLeave={() => setOpen(false)}
    >
      <button
        className={`flex flex-row items-center py-4 focus:outline-none ${
          noMobileStyle ? 'w-max' : 'w-full sm:w-max'
        } ${buttonClassName}`}
        onClick={() => setOpen(!open)}
      >
        {!!hasImage && (
          <span
            className={`${iconStyle} mr-2 flex items-center justify-center overflow-hidden rounded-full bg-gray-300 sm:mr-3`}
          >
            {isLoading ? null : typeof activeElement?.logo === 'string' ? (
              <img
                src={activeElement?.logo || placeholderIfNoImage}
                alt="shop's logo"
                className=" bg-gray-400 object-center"
              />
            ) : (
              activeElement?.logo || placeholderIfNoImage
            )}
          </span>
        )}

        <div className={`w-full text-left`}>
          {subHeader && (
            <p className={subLabelStyle}>{subHeader || <Skeleton />}</p>
          )}

          <h5
            className={`flex flex-row flex-nowrap ${
              noMobileStyle
                ? 'justify-center'
                : 'justify-between sm:justify-center'
            } w-full items-center truncate focus:outline-none ${labelStyle} ${labelClassName}`}
            style={labelOverwriteStyle}
          >
            {isLoading ? (
              <Skeleton height={16} width={75} />
            ) : (
              <>
                {activeElement?.title || options[0]?.title}
                <span
                  className={`h-4 ${
                    status === 'primary' ? 'ml-2 sm:ml-3' : 'ml-2'
                  }`}
                >
                  {open ? (
                    <ChevronUpIcon
                      fillColor={
                        status === 'tertiary' && isEmpty(labelOverwriteStyle)
                          ? '#889aa4'
                          : 'currentColor'
                      }
                    />
                  ) : (
                    <ChevronDownIcon
                      fillColor={
                        status === 'tertiary' && isEmpty(labelOverwriteStyle)
                          ? '#889aa4'
                          : 'currentColor'
                      }
                    />
                  )}
                </span>
              </>
            )}
          </h5>
        </div>
      </button>

      {/* -- Tablet and computer menu -- */}
      {(!isPhone || noMobileStyle) && (
        <HeartbeatHeaderMenuItemDropdown
          open={open}
          floatDropdownRight={floatDropdownRight}
        >
          {isLoading ? (
            <div className="flex h-4 justify-center">
              <LoadingSpinnerIcon />
            </div>
          ) : (
            options.map((option, idx) => (
              <HeartbeatHeaderMenuItemDropdownListItem
                key={option?.id || idx}
                title={option?.title}
                logo={hasImage ? option?.logo || null : null}
                onClick={() => setActiveElement(option)}
                placeholderIfNoImage={placeholderIfNoImage}
                optionsAsLink={optionsAsLink && optionsAsLink(option?.id)}
              />
            ))
          )}
        </HeartbeatHeaderMenuItemDropdown>
      )}

      {/* -- Mobile menu -- */}
      {open && isPhone && !noMobileStyle && (
        <>
          {isLoading ? (
            <div className="flex h-4 justify-center">
              <LoadingSpinnerIcon />
            </div>
          ) : (
            options.map((option, idx) => (
              <HeartbeatHeaderMenuItemDropdownListItem
                key={option?.id || idx}
                title={option?.title}
                logo={hasImage ? option?.logo || null : null}
                onClick={() => setActiveElement(option)}
                placeholderIfNoImage={placeholderIfNoImage}
                optionsAsLink={optionsAsLink && optionsAsLink(option?.id)}
              />
            ))
          )}
        </>
      )}
    </div>
  );
};

export default HeartbeatHeaderMenuItem;
