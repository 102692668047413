import { ProductIcon, UsersIcon } from '@tapestry/shared/icons';
import { AnalyseButton, Circle, SliderTray } from '@tapestry/weave';
import * as React from 'react';
import { mockProducts } from '../../../__mock__/products';
import clx from 'classnames';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IMessageCarouselProps {}

const MessageCarousel: React.FC<
  React.PropsWithChildren<IMessageCarouselProps>
> = ({ children }) => {
  return (
    <div className="w-full overflow-hidden rounded-md border border-gray-100 bg-gray-100 ">
      <div className="my-2 flex flex-row items-center gap-2 p-2">
        <div className="bg-cerulean h-10 w-10 overflow-hidden rounded-full p-2">
          <UsersIcon fillColor="white" className="h-full w-full" />
        </div>
        <span className="flex-1 font-bold">18 - 25 Year Olds</span>
        <AnalyseButton variant="icon" />
      </div>
      {children}
      <SliderTray
        spacing="small"
        noEdgeGradients
        listClassName="px-2"
        arrowClassName="mx-4"
        containerClassName="my-2"
      >
        {mockProducts.map(({ img, name }) => (
          <div
            key={name}
            className={clx(
              'text-whitefont-base min-w-56 relative flex h-64 flex-col justify-between rounded-lg border',
              'border-gray-lighter bg-gray-lighter bg-cover bg-center bg-no-repeat text-left text-xs',
              'text-white'
            )}
            style={{ backgroundImage: `url("${img}")` }}
          >
            <div className="absolute inset-0 rounded-lg bg-gradient-to-t from-black to-transparent" />
            <div className="relative flex h-full w-full flex-col justify-end p-4">
              <h6 className="mb-2 text-lg font-bold capitalize">{name}</h6>
              <Circle className="bg-purple-dark">
                <ProductIcon fillColor="currentColor" />
              </Circle>
            </div>
          </div>
        ))}
      </SliderTray>
    </div>
  );
};

export { MessageCarousel };
