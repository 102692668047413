import {
  IUserProfile,
  UpdateUserProfileHookResult,
  useGetProfileLazyQuery,
  useUpdateUserProfile,
} from '@tapestry/shared/graphql';
import { Maybe } from 'graphql/jsutils/Maybe';
import { ApolloError } from '@apollo/client';
import uuid from 'react-uuid';
import { useAuthToken } from '@tapestry/shared/client';
import { useEffect, useMemo } from 'react';
import TagManager from 'react-gtm-module';
import { setUser as setSentryUser } from '@sentry/nextjs';

// TODO[high]: This is a temporary solution to handle the case where the user's token
// is invalid. We should be able to handle this in the Apollo client for all API request
// not just only when trying to get a user profile, but the BE API is not returning
// correct/consistent error codes across all queries/mutations.
// so it's imposible to handle it that way.

export const clearBrowserStorage = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('userId');
  localStorage.removeItem('token_type');
  localStorage.removeItem('expiresIn');
};

/**
 * Hook abstraction of grapql query and mutation for profile
 *
 */
export const useProfile = (): [
  Maybe<IUserProfile>,
  UpdateUserProfileHookResult[0],
  {
    error: ApolloError | undefined;
    loading: boolean;
  }
] => {
  const token = useAuthToken();
  const _token = useMemo(() => token, [token]);

  const [
    fetchProfile,
    { data, loading: loadingGetProfile, error: getProfileError },
  ] = useGetProfileLazyQuery({
    onCompleted: ({ profile }) => {
      TagManager.dataLayer({
        dataLayer: { userId: profile?.id },
      });

      setSentryUser({
        id: profile?.id || undefined,
        email: profile?.email || undefined,
      });
    },
    onError: (error: unknown) => {
      if (
        error instanceof ApolloError &&
        error.graphQLErrors[0]?.path?.[0] === 'profile' &&
        typeof window !== 'undefined'
      ) {
        // Only clear storage in case of invalid token,
        // do not clear the apollo cache for a faster load after login
        clearBrowserStorage();
        setSentryUser(null);

        window.location.href = '/login?code=invalid_token';
      }
    },
  });

  useEffect(() => {
    if (_token) {
      fetchProfile();
    }
  }, [_token, fetchProfile]);

  const profile = data?.profile;

  const [updateAPIProfile, { error: updateError, loading: isLoadingUpdate }] =
    useUpdateUserProfile({
      optimisticResponse: (vars) => {
        return {
          updateUserProfile: {
            ...profile,
            id: profile?.id || uuid(),
            ...vars,
          },
        };
      },
    });

  return [
    profile,
    updateAPIProfile,
    {
      loading: loadingGetProfile || isLoadingUpdate,
      error: getProfileError || updateError,
    },
  ];
};
