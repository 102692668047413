import { useEffect } from 'react';
import { NumberParam, useQueryParam } from '@tapestry/shared/utils';
import { useUIContext } from '@tapestry/shared/client';

export const useSetAsMobileAppIfWebView = () => {
  const [mobileQP] = useQueryParam('mobile', NumberParam);
  const [_, dispatch] = useUIContext();

  useEffect(
    function setMobileBrowsingIfQueryParam() {
      if (!mobileQP) return;
      dispatch({ type: 'SET_MOBILE_BROWSING' });
    },
    [mobileQP, dispatch]
  );
};

export default useSetAsMobileAppIfWebView;
