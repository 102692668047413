// *******************************************
// Module Imports
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
} from 'react';

// import { Toast } from '@tapestry/ui';

// *******************************************
// Types and interface Imports
// ------------------------------------------
import { IToastNotification } from '@tapestry/types';
import Toast from './Toast';

// *******************************************
// Utils Imports
// ------------------------------------------
const MOCK_TOAST = [
  { type: 'taskCreated' },
  { type: 'taskCompleted', content: 'clean cobwebs at Brighton' },
  { type: 'taskOverdue', content: 'Restock the popcorn' },
  { type: 'reminder', content: 'ISODATE' },
  { type: 'assigned', content: 'Adrian Corcoris' },
  { type: 'error', content: 'Something went wrong' },
  { type: 'info', content: 'blah blah blah blah blah' },
];

export type ToastContextState = [
  toastList: IToastNotification[],
  setToastList: Dispatch<SetStateAction<IToastNotification[]>>
];

// *******************************************
// Context
// -------------------------------------------
export const ToastContext = createContext<ToastContextState | undefined>(
  undefined
);

// *******************************************
// Provider
// -------------------------------------------
export function ToastProvider({ children }) {
  const [toastList, setToastList] = useState<Array<IToastNotification>>([]);
  const value: ToastContextState = [toastList, setToastList];

  // NOTE: KNOW PROBLEMS => it currently clear all notification at once,
  // this might be due to the filter function and removeToast function shelf live
  // or
  // because the ToastProvider re-renders, it creates a new removeToast function...
  const removeToast = (id: string) => {
    // const copyOfList = toastList.slice(); // this didnt help
    const newList = toastList.filter((toast) => {
      return toast.id !== id;
    });
    setToastList(newList);
  };

  return (
    <ToastContext.Provider value={value}>
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start"
        style={{ zIndex: 9999 }}
      >
        <div className="flex w-full flex-col items-center space-y-2 sm:items-end">
          {toastList?.map((notification) => (
            <Toast
              key={notification?.id}
              notification={notification}
              removeToast={removeToast}
            />
          ))}
        </div>
      </div>
      {children}
    </ToastContext.Provider>
  );
}
