export function getEventTouchedKeys(event: KeyboardEvent) {
  const keyboardRelatedEventData = {
    // [lowerCaseKey]: true,
    meta: event.metaKey,
    control: event.ctrlKey,
    shift: event.shiftKey,
  };

  if (event?.key) {
    const lowerCaseKey = event?.key?.toLowerCase();
    keyboardRelatedEventData[lowerCaseKey] = true;
  }

  const eventMapObjectKeys = Object.keys(keyboardRelatedEventData);

  const pressedKeysAsObjectMap = eventMapObjectKeys.reduce((obj, item) => {
    if (!keyboardRelatedEventData[item]) return obj;
    return {
      ...obj,
      [item]: true,
    };
  }, {});

  return pressedKeysAsObjectMap;
}

export function transformStringShortcutToMap(shortcutAsString: string): {
  id: string;
} {
  const shortcutAsArray = shortcutAsString.split(' ');
  const shortcutMap = shortcutAsArray.reduce(
    (obj, item) => {
      return {
        ...obj,
        [item]: true,
      };
    },
    { id: shortcutAsString }
  );

  return shortcutMap;
}
