import React, { ReactNode, FC } from 'react';
import { NAVBAR_HEIGHT, ROUTE_PATHS } from '@tapestry/shared/constants';
import { FrameContent, MainHeading, Pill } from '@tapestry/weave';
import { MoreButtonEllipsis } from '../more-button-ellipsis/MoreButtonEllipsis';
import { IDropDownActionItem } from '@tapestry/types';
import { RecommendedTasks } from '../recommendated-tasks/RecommendedTasks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRouter } from 'next/router';

interface IAppletTemplateFrameProps {
  title: string;
  ellipsisMenu?: IDropDownActionItem[];
  filterBar?: ReactNode;
  children: ReactNode;
  releaseBadge?: string;
}

const AppletTemplateFrame: FC<
  React.PropsWithChildren<IAppletTemplateFrameProps>
> = ({ title, ellipsisMenu = [], filterBar, children, releaseBadge }) => {
  const { recommendedTasks: hasRecommendedTasksFlag } = useFlags();
  const { pathname } = useRouter();
  const isSearchPage = pathname === ROUTE_PATHS.search;

  return (
    <div className="bg-gray-100 pb-16">
      <header
        className="sticky z-50 bg-white py-4 shadow-md"
        style={{ top: NAVBAR_HEIGHT + 1 }}
      >
        <FrameContent maxWidth="xl" noVerticalSpacing>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <MainHeading className="text-left md:text-2xl">
                {title}
              </MainHeading>

              {releaseBadge ? (
                <Pill label={releaseBadge || ''} className="ml-4" />
              ) : null}
            </div>

            <div className="flex items-center justify-end">
              <div className="mr-3 hidden md:block">{filterBar}</div>

              <MoreButtonEllipsis items={ellipsisMenu} ellipsisSize="normal" />
            </div>
          </div>

          <div className="mt-4 md:hidden">{filterBar}</div>
        </FrameContent>
      </header>

      {!isSearchPage && hasRecommendedTasksFlag && (
        <RecommendedTasks type={title.toLowerCase()} />
      )}

      <FrameContent maxWidth="xl" noVerticalSpacing>
        <main className="min-h-screen">{children}</main>
      </FrameContent>
    </div>
  );
};

export type { IAppletTemplateFrameProps };
export { AppletTemplateFrame };
