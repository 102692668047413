import { useState, useCallback, useEffect, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';

export interface IUseLongLoadingProps {
  isLoading: boolean;
  threshold?: number;
  mode?: 'timeout' | 'interval';
  messages?: Array<string>;
}

const DEFAULT_MESSAGES = [
  'Hold tight, we are still working on it...',
  'Grab a coffee while we finish loading this...',
];

const getLoadingMessage = (
  messages = DEFAULT_MESSAGES,
  tick: number | null
) => {
  if (tick === null) {
    return null;
  }

  const messageIndex = tick % messages.length; // Ensures the tick index stays within the messages array length
  const loadingMessage = messages[messageIndex];

  return loadingMessage;
};

/**
 * Use it in the cases you want to provide a "still loading" message
 *
 * Default wait time = `8 sec`
 * @param object - { threshold }
 *
 * @example
 * const Component = () => {
 *  const {stillLoading, start, reset} = useLongLoading()
 *
 *  return (
 *    <div>
 *      {stillLoading && <p>Still crunching...</p>}
 *       <button onClick={() => start()}>start work</button>
 *    </div>
 *  )
 * }
 */
export const useLongLoading = ({
  isLoading = false,
  threshold = 8000,
  mode = 'timeout',
  messages,
}: IUseLongLoadingProps) => {
  const [tickCounter, setTickCounter] = useState<number | null>(null);
  const intervalID = useRef<NodeJS.Timer>();

  const loadingMessage = getLoadingMessage(messages, tickCounter);

  const { callback: startTimeout, cancel } = useDebouncedCallback(() => {
    setTickCounter(0);
  }, threshold);

  const startInterval = () => {
    intervalID.current = setInterval(() => {
      if (tickCounter === null) {
        setTickCounter(0);
      } else {
        setTickCounter((counter) => {
          return counter === null ? 0 : counter + 1;
        });
      }
    }, threshold);
  };

  const startLongLoading = useCallback(() => {
    if (mode === 'interval') {
      startInterval();
    } else {
      startTimeout();
    }
  }, []);

  const reset = useCallback(() => {
    setTickCounter(null);
    cancel();
    clearTimeout(intervalID.current);
  }, [cancel]);

  useEffect(
    function startStopTimeout() {
      if (isLoading) {
        startLongLoading();
      } else {
        reset();
      }

      return () => {
        reset();
      };
    },
    [isLoading, startLongLoading, reset]
  );

  return {
    stillLoading: tickCounter === null ? false : true,
    start: startLongLoading,
    reset,
    loadingMessage,
  };
};

export default useLongLoading;
