import { VIEW } from '@tapestry/shared/constants';
import React, { FC } from 'react';

type ThreadViewState = VIEW.CREATE | VIEW.READ | VIEW.UPDATE | VIEW.DELETE;
type ThreadViewAction =
  | { type: VIEW.CREATE }
  | { type: VIEW.READ }
  | { type: VIEW.UPDATE }
  | { type: VIEW.DELETE };
type ThreadViewDispatch = (action: ThreadViewAction) => void;

const ThreadViewStateContext = React.createContext<ThreadViewState | undefined>(
  undefined
);
const ThreadViewDispatchContext = React.createContext<
  ThreadViewDispatch | undefined
>(undefined);

const threadViewReducer = (
  state: ThreadViewState,
  { type }: ThreadViewAction
) => {
  switch (type) {
    case VIEW.CREATE:
      return VIEW.CREATE;
    case VIEW.READ:
      return VIEW.READ;
    case VIEW.UPDATE:
      return VIEW.UPDATE;
    case VIEW.DELETE:
      return VIEW.DELETE;

    default:
      throw new Error(`threadViewReducer: Unhandled action type: ${type}`);
  }
};

const ThreadViewContextProvider: FC<
  React.PropsWithChildren<{
    defaultView?: VIEW.CREATE | VIEW.READ | VIEW.UPDATE | VIEW.DELETE;
  }>
> = ({ children, defaultView = VIEW.READ }) => {
  const [state, dispatch] = React.useReducer(threadViewReducer, defaultView);

  return (
    <ThreadViewStateContext.Provider value={state}>
      <ThreadViewDispatchContext.Provider value={dispatch}>
        {children}
      </ThreadViewDispatchContext.Provider>
    </ThreadViewStateContext.Provider>
  );
};

const useThreadState = () => {
  const context = React.useContext(ThreadViewStateContext);
  if (context === undefined) {
    throw new Error(
      'useThreadState must be used inside ThreadViewContextProvider'
    );
  }
  return context;
};

const useThreadDispatch = () => {
  const context = React.useContext(ThreadViewDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useThreadDispatch must be used inside ThreadViewContextProvider'
    );
  }
  return context;
};

export { ThreadViewContextProvider, useThreadState, useThreadDispatch };
