import * as React from 'react';
import { Dialog, Transition } from '@headlessui/react';

export interface ISlideOver {
  isOpen: boolean;
  onClose: () => void;
  onFinishTransition: () => void;
}

const SlideOver: React.FC<React.PropsWithChildren<ISlideOver>> = ({
  isOpen,
  children,
  onClose,
  onFinishTransition,
}): React.ReactElement => {
  return (
    <Transition.Root show={isOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="z-modal fixed inset-0 overflow-hidden"
        onClose={onClose}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />
          <div className="fixed inset-y-0 right-0 flex max-w-full md:pl-16">
            <Transition.Child
              afterLeave={onFinishTransition}
              as={React.Fragment}
              enter="transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <aside className="flex h-screen max-h-screen w-screen max-w-sm flex-col bg-white shadow-xl">
                {children}
              </aside>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export { SlideOver };
