import { FC, PropsWithChildren, useMemo } from 'react';
import ReactTooltip from 'react-tooltip';
import uuid from 'react-uuid';
import clx from 'classnames';

/**
 * TODO[medium]: create a utility to manage color classes
 * the util should be able to take in a color string,
 * then output the correct color class
 * for example,
 * if the color string is 'text-black', the util should return 'text-black',
 * if the color string is a hex code, the util should return 'text-[hex code]'
 * also css attributes like 'bg-[color]' should be supported
 */

interface InfoPillProps {
  icon: React.ElementType;
  title: string;
  tooltipMessage?: string;
  iconColor?: `${string}-${string}` | `${string}-${string}-${string}`;
  backgroundColor?: `bg-${string}`;
}

const InfoPill: FC<PropsWithChildren<InfoPillProps>> = ({
  icon: Icon,
  title,
  tooltipMessage,
  iconColor = 'tex-black',
  backgroundColor = 'bg-gray-pageBackground',
  children,
}) => {
  const id = uuid();

  const tooltipAttributes = useMemo(
    () =>
      tooltipMessage
        ? {
            'data-tip': true,
            'data-for': id,
          }
        : {},
    [id, tooltipMessage]
  );

  return (
    <div
      className={clx(
        'flex-shrink-0 flex justify-center items-center p-1 rounded-full text-xs',
        backgroundColor
      )}
      {...tooltipAttributes}
    >
      <span className="sr-only">{title}</span>
      <div className={clx('flex-shrink-0 h-4 mx-2', iconColor)}>
        <Icon
          className="h-full max-h-full max-w-full mx-auto"
          fillColor="currentColor"
        />
      </div>
      <div className="rounded-full flex-1 bg-white py-1 px-2">{children}</div>
      {tooltipMessage ? (
        <ReactTooltip id={id} place="bottom" effect="solid">
          {tooltipMessage}
        </ReactTooltip>
      ) : null}
    </div>
  );
};

export { InfoPill };
