import { IDataValue } from '@tapestry/shared/graphql';
import { THREAD_TYPE } from '../thread/thread-type';
export * from './metrics';
export * from './visualizations';
export * from './query-filters';
export * from './comparison';

type offsetDirection = '+' | '-';
export type PrimaryChartDataDate =
  `${string}-${string}-${string} ${string}:${string}:${string}${offsetDirection}${string}:${string}`;
export interface IDataValuePlaceholder {
  name: PrimaryChartDataDate;
  value: null;
}
export interface IParsedDataValue extends IDataValue {
  comparison: number | null;
}

export type IParsedHeartbeatMainChartDataValueList = IParsedDataValue[];

export interface IActiveThreadState {
  type: THREAD_TYPE;
  id: string;
}

export type NormalisedDuration = 'week' | 'month' | 'custom';
