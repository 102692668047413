import React, { FC } from 'react';
import { useNumberFormatter } from 'react-aria';

export const Number: FC<{
  value: number;
  maximumFractionDigits?: number | undefined;
}> = ({ value, maximumFractionDigits = undefined }) => {
  const formatter = useNumberFormatter({ maximumFractionDigits });
  return <span>{formatter.format(value)}</span>;
};
