import React from 'react';
import { CloseIcon } from '@tapestry/shared/icons';
import { Transition } from '@headlessui/react';
import { FocusScope } from 'react-aria';
import ModalOverlay from '../../ModalOverlay';
import { Circle } from '../../Circle/Circle';
import { usePopper } from 'react-popper';

interface IResponsiveRenderingWrapperProps {
  isPhone: boolean;
  children: React.ReactNode;
  shouldShowCalendar: boolean;
  popper: ReturnType<typeof usePopper>;
  onClose: () => void;
}

export const ResponsiveRenderingWrapper = React.forwardRef<
  HTMLDivElement,
  IResponsiveRenderingWrapperProps
>(({ isPhone, children, shouldShowCalendar, popper, onClose }, ref) => {
  if (isPhone) {
    return (
      <ModalOverlay
        title="Choose a date range"
        isOpen={shouldShowCalendar}
        onClose={onClose}
      >
        <div className="flex h-screen flex-col items-center justify-center">
          <div className="flex w-[350px] justify-end rounded-t-md bg-white px-4 pt-4 sm:w-[320px]">
            <button
              title="Close picker"
              onClick={onClose}
              onKeyDown={({ key }) => {
                if (key === 'Enter') {
                  onClose();
                }
              }}
              className="group focus:outline-none"
            >
              <p className="sr-only">Close picker</p>
              <Circle
                size="h-6 w-6 min-w-6 p-1"
                className="group-hover:bg-gray-hover group-focus:bg-gray-hover"
              >
                <CloseIcon />
              </Circle>
            </button>
          </div>
          {children}
        </div>
      </ModalOverlay>
    );
  }

  return (
    <div
      ref={ref}
      style={popper.styles.popper}
      className="z-dropdown"
      {...popper.attributes.popper}
    >
      <Transition
        appear
        show={shouldShowCalendar}
        enter="transition transform duration-200"
        enterFrom="opacity-0 scale-90 -translate-y-8"
        enterTo="opacity-100 scale-100 -translate-y-0"
        leave="transition duration-150"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-90"
      >
        {/* TODO TO BE CONTINUED */}
        <FocusScope contain restoreFocus>
          {children}
        </FocusScope>
      </Transition>
    </div>
  );
});
