import React from 'react';
import clx from 'classnames';
import { useSelect } from 'downshift';
import Stack from '../stack/Stack';
import { ISelectBaseProps, SelectOptionItem } from './types';
import { defaultLabelAccessor } from './SelectUtils';
import { ChevronDownIcon } from '@tapestry/shared/icons';
import { SelectDefaultDropDownListItem } from '../SelectDefaultListItem/SelectDefaultListItem';
import { FormInputBase } from '../FormInputBase/FormInputBase';

const SelectBase = React.forwardRef<HTMLDivElement, ISelectBaseProps>(
  (
    {
      options,
      onChange,
      onBlur,
      iconLeft,
      renderSelectedItem,
      defaultItem = undefined,
      getDropdownProps,
      multiSelectSelectedItems,
      ...props
    },
    ref
  ) => {
    const items = Array.isArray(options) ? options : options.options;
    const isMultiSelect = !!getDropdownProps;

    const {
      isOpen,
      selectedItem,
      getToggleButtonProps,
      getLabelProps,
      getMenuProps,
      highlightedIndex,
      getItemProps,
      closeMenu,
      reset,
    } = useSelect<SelectOptionItem>({
      selectedItem: isMultiSelect ? null : defaultItem,
      defaultHighlightedIndex: 0,
      items,
      defaultSelectedItem: defaultItem,
      itemToString: defaultLabelAccessor,
      onSelectedItemChange({ selectedItem }) {
        onChange(selectedItem);
      },
      stateReducer: (state, actionAndChanges) => {
        const { changes, type } = actionAndChanges;
        switch (type) {
          case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
          case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
          case useSelect.stateChangeTypes.ItemClick:
            if (isMultiSelect) {
              return {
                ...changes,
                isOpen: true, // keep the menu open after selection.
              };
            }

            return changes;
        }
        return changes;
      },
    });

    const handleOnBlur = () => {
      if (isMultiSelect) return;
      onBlur && onBlur(selectedItem);
    };

    return (
      <FormInputBase {...props} getLabelProps={getLabelProps}>
        <div onMouseLeave={closeMenu} ref={ref} onBlur={handleOnBlur}>
          <button
            aria-label="toggle menu"
            className="border-gray-border relative block w-full rounded-md border py-4 text-left"
            type="button"
            {...getToggleButtonProps(
              isMultiSelect
                ? getDropdownProps({ preventKeyAction: isOpen })
                : undefined
            )}
          >
            {iconLeft && (
              <div className="min-w-8 pointer-events-none absolute left-0 top-1/2 ml-2 flex h-8 w-8 -translate-y-1/2 transform items-center justify-center sm:ml-5">
                {iconLeft}
              </div>
            )}
            <span
              className={clx(
                `sm:pr-18 w-full pr-10 text-base font-normal  tracking-wider`,
                iconLeft ? 'pl-18' : 'pl-6',
                selectedItem ? 'text-black' : 'text-gray-400'
              )}
            >
              {selectedItem ? selectedItem.label : 'Pick an item...'}
            </span>
            <div className="absolute right-0 top-1/2 -translate-y-1/2 transform px-5">
              <ChevronDownIcon className="h-4 w-4" fillColor="#889aa4" />
            </div>
          </button>
          <ul
            {...getMenuProps()}
            className={clx(
              isOpen ? 'block' : 'hidden',
              'max-h-80 overflow-y-scroll outline-none'
            )}
          >
            {isOpen && (
              <Stack spacing={'xxsmall'} hasTopMargin>
                {items.map((item, index) => (
                  <li
                    key={item.id}
                    {...getItemProps({
                      item,
                      index,
                    })}
                    className="cursor-pointer"
                  >
                    <SelectDefaultDropDownListItem
                      item={item}
                      isHighlighted={highlightedIndex === index}
                      isChecked={
                        selectedItem === item ||
                        (multiSelectSelectedItems &&
                          multiSelectSelectedItems.includes(item)) ||
                        false
                      }
                      labelAccessor={defaultLabelAccessor}
                    />
                  </li>
                ))}
              </Stack>
            )}
          </ul>
          {selectedItem && renderSelectedItem ? (
            <div className="mt-4 text-left">
              {renderSelectedItem(selectedItem, reset)}
            </div>
          ) : null}
        </div>
      </FormInputBase>
    );
  }
);

export { SelectBase };
