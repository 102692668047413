import React from 'react';
import IIconProp from '../../iconInterface';

export const ProductListItemImgPlaceholder = ({
  className = 'h-full w-auto',
  fillColor,
}: IIconProp) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 400 400"
      className={className}
    >
      <title>product-icon</title>
      <defs>
        <circle id="pliplace-path-1" cx="200" cy="200" r="200" />
        <path
          id="pliplace-path-3"
          d="M200.926 103.333h-28.704v34.445l-11.481-7.643-11.482 7.643v-34.445h-28.703c-3.158 0-5.741 2.584-5.741 5.741v68.889c0 3.157 2.583 5.74 5.74 5.74h80.37c3.158 0 5.742-2.583 5.742-5.74v-68.889c0-3.157-2.584-5.74-5.741-5.74zM63.148 80.37h80.37c3.158 0 5.741-2.583 5.741-5.74V5.74c0-3.157-2.583-5.74-5.74-5.74h-28.704v34.444l-11.482-7.642-11.481 7.642V0H63.148c-3.157 0-5.74 2.583-5.74 5.74v68.89c0 3.157 2.583 5.74 5.74 5.74zm22.963 22.963H57.407v34.445l-11.481-7.643-11.482 7.643v-34.445H5.741c-3.158 0-5.741 2.584-5.741 5.741v68.889c0 3.157 2.583 5.74 5.74 5.74h80.371c3.158 0 5.74-2.583 5.74-5.74v-68.889c0-3.157-2.582-5.74-5.74-5.74z"
        />
      </defs>
      <g
        id="product-icon"
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
      >
        <g id="pliplace-Colours/Pear-#CAD526">
          <mask id="pliplace-mask-2" fill="#fff">
            <use xlinkHref="#pliplace-path-1" />
          </mask>
          <circle cx="200" cy="200" r="199.5" stroke="#EEE" />
          <g id="pliplace-Group" fill="#C33C8E" mask="url(#pliplace-mask-2)">
            <g id="pliplace-Colours/Pear-#CAD526">
              <path id="pliplace-Rectangle" d="M0 0h400v400H0z" />
            </g>
          </g>
        </g>
        <g id="pliplace-icons/shop" transform="translate(96 107)">
          <g id="pliplace-Colours/Black-#000000" transform="translate(.667)">
            <mask id="pliplace-mask-4" fill="#fff">
              <use xlinkHref="#pliplace-path-3" />
            </mask>
            <g id="pliplace-Rectangle" fill="#FFF" mask="url(#pliplace-mask-4)">
              <path
                d="M0 0h454.667v454.667H0z"
                transform="translate(-124 -134.333)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ProductListItemImgPlaceholder;
