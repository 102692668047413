import uuid from 'react-uuid';
import { default as acProfilePic } from '../__mock__/user-profile-pics/ac.png';
import { default as cbProfilePic } from '../__mock__/user-profile-pics/cb.png';
import { default as lhProfilePic } from '../__mock__/user-profile-pics/lh.png';
import { default as lwProfilePic } from '../__mock__/user-profile-pics/lw.png';
import { default as mrProfilePic } from '../__mock__/user-profile-pics/mr.png';
import { default as tmProfilePic } from '../__mock__/user-profile-pics/tm.png';

const mockUsers = {
  adrian: {
    id: uuid(),
    name: 'Adrian Corcoris',
    profileImage: acProfilePic as unknown as string,
  },
  michael: {
    id: uuid(),
    name: 'Michael Reddrop',
    profileImage: mrProfilePic as unknown as string,
  },
  chirstopher: {
    id: uuid(),
    name: 'Christopher Bartlett',
    profileImage: cbProfilePic as unknown as string,
  },
  lincoln: {
    id: uuid(),
    name: 'Lincoln Wymer',
    profileImage: lwProfilePic as unknown as string,
  },
  luke: {
    id: uuid(),
    name: 'Luke Hindson',
    profileImage: lhProfilePic as unknown as string,
  },
  tammy: {
    id: uuid(),
    name: 'Tammy Wall.',
    profileImage: tmProfilePic as unknown as string,
  },
};

export { mockUsers };
