import React, { FunctionComponent } from 'react';
import IIconProp from '../iconInterface';

export const LoyaltyIcon = ({ fillColor = '#000' }: IIconProp) => {
  return (
    <svg
      className="w-auto"
      height="38px"
      viewBox="0 0 38 38"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="LoyaltyIcon"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="LoyaltyIconGroup" fillRule="nonzero">
          <g id="Components/icons/circle-coloured-Copy-7">
            <path
              fill={fillColor}
              d="M32.1944444,6.33333333 L5.80555556,6.33333333 C4.34866898,6.33333333 3.16666667,7.51533565 3.16666667,8.97222222 L3.16666667,28.3240741 C3.16666667,29.7809606 4.34866898,30.962963 5.80555556,30.962963 L32.1944444,30.962963 C33.651331,30.962963 34.8333333,29.7809606 34.8333333,28.3240741 L34.8333333,8.97222222 C34.8333333,7.51533565 33.651331,6.33333333 32.1944444,6.33333333 Z M31.3148148,22.6064815 C31.3148148,22.8483796 31.1168981,23.0462963 30.875,23.0462963 L22.9583333,23.0462963 C22.7164352,23.0462963 22.5185185,22.8483796 22.5185185,22.6064815 L22.5185185,21.7268519 C22.5185185,21.4849537 22.7164352,21.287037 22.9583333,21.287037 L30.875,21.287037 C31.1168981,21.287037 31.3148148,21.4849537 31.3148148,21.7268519 L31.3148148,22.6064815 Z M31.3148148,19.087963 C31.3148148,19.3298611 31.1168981,19.5277778 30.875,19.5277778 L22.9583333,19.5277778 C22.7164352,19.5277778 22.5185185,19.3298611 22.5185185,19.087963 L22.5185185,18.2083333 C22.5185185,17.9664352 22.7164352,17.7685185 22.9583333,17.7685185 L30.875,17.7685185 C31.1168981,17.7685185 31.3148148,17.9664352 31.3148148,18.2083333 L31.3148148,19.087963 Z M31.3148148,15.5694444 C31.3148148,15.8113426 31.1168981,16.0092593 30.875,16.0092593 L22.9583333,16.0092593 C22.7164352,16.0092593 22.5185185,15.8113426 22.5185185,15.5694444 L22.5185185,14.6898148 C22.5185185,14.4479167 22.7164352,14.25 22.9583333,14.25 L30.875,14.25 C31.1168981,14.25 31.3148148,14.4479167 31.3148148,14.6898148 L31.3148148,15.5694444 Z"
              id="LoyaltyIconShape"
            ></path>
            <path
              d="M11.9871663,13.0883991 L10.4411254,16.2230976 L6.98206617,16.7273958 C6.36175574,16.8173644 6.11315805,17.5820983 6.56300149,18.0201037 L9.06555156,20.4587287 L8.4736523,23.9035824 C8.36711044,24.5262604 9.02293482,24.992677 9.57221733,24.7014626 L12.6666667,23.0749234 L15.761116,24.7014626 C16.3103985,24.9903094 16.9662229,24.5262604 16.859681,23.9035824 L16.2677818,20.4587287 L18.7703318,18.0201037 C19.2201753,17.5820983 18.9715776,16.8173644 18.3512672,16.7273958 L14.8922079,16.2230976 L13.346167,13.0883991 C13.0691582,12.5296462 12.2665428,12.5225434 11.9871663,13.0883991 Z"
              id="LoyaltyIconPath"
              fill="#FFFFFF"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default LoyaltyIcon;
