import {
  Grocery1,
  Grocery2,
  Grocery3,
  Grocery4,
  Grocery5,
} from '@tapestry/shared/icons';

export const mockProducts = [
  { name: 'Penne', img: Grocery1, price: '$3.45' },
  { name: 'Aeroplane Jelly', img: Grocery2, price: '$2.35' },
  { name: 'Vege Chips', img: Grocery3, price: '$1.25' },
  { name: 'Tomato Sauce', img: Grocery4, price: '$3.20' },
  { name: 'Oat milk', img: Grocery5, price: '$4.50' },
];
