import { InMemoryCacheConfig } from '@apollo/client';
// import { offsetLimitPagination } from '@apollo/client/utilities';

// const cacheSeparateThreadEntryIfTheseVariablesChange = [
//   'type',
//   'shop_id',
//   'assigned_id',
//   'department_id',
//   'tag_id',
//   'is_repeatable',
//   'assigned_contracts_ids',
//   'components',
// ];

export const CACHE_CONFIG: InMemoryCacheConfig = {
  typePolicies: {
    // Query: {
    //   fields: {
    //     threads: {
    //       // * offsetLimitPagination(): helper func provided by Apollo/client returning a merge function. If it doesnt work for use case, you can replace with our own merge func
    //       // Only cache separate results if these variables change (excludes things like offset, limit, sort_by) => done for pagination
    //       ...offsetLimitPagination(
    //         cacheSeparateThreadEntryIfTheseVariablesChange
    //       ),
    //     },
    //   },
    // },
    DataValueId: {
      // * id can sometime be null
      keyFields: ['id', 'name', 'value'],
    },
    // * caches TwistType as part of the parent object (`thread`) instead of it's own disctind cache entry. Because a given twist as the same ID across diferent thread, this would cause mismatch of twists and thread.
    TwistType: {
      keyFields: false as const,
    },
    ThreadV4Type: {
      // * makes it a bit easier to look thread up in the cache
      keyFields: ['threadType', 'id'],
    },
    ShopByThreadShopType: {
      // disabling normalization for this type because it's not a unique entity
      keyFields: false,
    },
  },
};
