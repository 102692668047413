import { UserSetting } from '@tapestry/types';
import { useCallback } from 'react';
import { useProfile } from '../use-profile/useProfile';

const CURRENT_USER_SETTING_VERSION = 2.0;

export const useUpdateUserSettings = (): ((
  setting: UserSetting,
  newValue
) => void) => {
  const [profile, updateProfile] = useProfile();

  // ? if you were to provide type to updateUserSettings(), could you pass it to new value
  const updateUserSettings = useCallback(
    (setting: UserSetting, newValue: any) => {
      updateProfile({
        variables: {
          user_settings: {
            version: CURRENT_USER_SETTING_VERSION,
            [setting]: newValue,
          },
        },
      });
    },
    []
  );

  return updateUserSettings;
};

export default useUpdateUserSettings;
