import React from 'react';
import { useAppMediaQuery } from '@tapestry/shared/hooks';
import { FrameContent, Stack } from '@tapestry/weave';
import Skeleton from 'react-loading-skeleton';
import TaskListItemSkeleton from '../TaskListItemSkeleton/TaskListItemSkeleton';

export const AppletSplashScreen = () => {
  const { isPhone } = useAppMediaQuery();

  return (
    <div>
      <header className="border-b bg-white py-4">
        <FrameContent
          maxWidth="xl"
          noVerticalSpacing
          className="relative flex items-center justify-between"
        >
          <Skeleton circle height={'2rem'} width={'2rem'} />

          <Skeleton
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full"
            height="2rem"
            width={250}
          />

          <Skeleton circle height={'2rem'} width={'2rem'} />
        </FrameContent>
      </header>

      <main className="bg-gray-pageBackground">
        <div className="mt-4">
          <FrameContent maxWidth="xl">
            <Stack>
              <div className="flex items-center justify-between">
                <Skeleton
                  height="2rem"
                  width={isPhone ? 150 : 340}
                  className="rounded-full"
                />

                <Skeleton height="1rem" width={10} />
              </div>

              <TaskListItemSkeleton count={6} />
            </Stack>
          </FrameContent>
        </div>
      </main>
    </div>
  );
};
