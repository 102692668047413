import React, { FC } from 'react';
import Stack from '../stack/Stack';
import { Label } from '../label/Label';
import { Transition } from '@headlessui/react';
import { UseSelectGetLabelPropsOptions } from 'downshift';

export interface IFormInputBaseProps {
  name: string;
  label: string;
  sublabel?: string;
  error?: string;
  labelSROnly?: boolean;
  // Used with Downshift library
  getLabelProps?: (options?: UseSelectGetLabelPropsOptions) => any;
}

export const FormInputBase: FC<
  React.PropsWithChildren<IFormInputBaseProps>
> = ({
  name,
  label,
  sublabel,
  error,
  children,
  labelSROnly = false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  getLabelProps = () => {},
}) => {
  return (
    <div className="text-left">
      {labelSROnly ? (
        <label className="sr-only" htmlFor="name">
          {label}
        </label>
      ) : (
        <Stack spacing="xsmall">
          <Label htmlFor={name} {...getLabelProps()}>
            {label}
          </Label>

          {sublabel && (
            <p className="text-gray-text text-left text-sm capitalize">
              {sublabel}
            </p>
          )}
        </Stack>
      )}

      <div className={labelSROnly ? '' : 'mt-4'}>{children}</div>

      {error ? (
        <Transition
          appear
          show={!!error}
          enter="transition-opacity duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className=" text-red mt-2 text-sm"
          as="p"
        >
          {error}
        </Transition>
      ) : null}
    </div>
  );
};
