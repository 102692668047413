import { Transition } from '@headlessui/react';
import { CloseIcon } from '@tapestry/shared/icons';
import React, { FunctionComponent, useCallback, useEffect } from 'react';
import clx from 'classnames';
import { useOverlay, usePreventScroll, useModal } from '@react-aria/overlays';
import { useDialog } from '@react-aria/dialog';
import { FocusScope } from '@react-aria/focus';

interface IModalPanelProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  isDismissable?: boolean;
  className?: string;
  maxWidth?: 'md' | 'lg' | 'xl';
  spacing?: 'none';
  noOverlay?: boolean;
  hasError?: boolean;
}

const getMaxWidth = (width: string) => {
  switch (width) {
    case 'md':
      return 'max-w-screen-md';

    case 'lg':
      return 'max-w-screen-lg';

    case 'xl':
      return 'max-w-screen-xl';

    default:
      return 'max-w-screen-lg';
  }
};

// ! Remove the any in the props when you know how to fix it
export const ModalOverlayAndPanel: FunctionComponent<
  React.PropsWithChildren<IModalPanelProps>
> = (props) => {
  const {
    children,
    className,
    isOpen,
    onClose,
    title,
    maxWidth = 'md',
    noOverlay = false,
    hasError,
    spacing,
  } = props;

  // Handle interacting outside the dialog and pressing
  // the Escape key to close the modal.
  const modalRef = React.useRef(null);
  const { overlayProps } = useOverlay(props, modalRef);

  // Prevent scrolling while the modal is open, and hide content
  // outside the modal from screen readers.
  usePreventScroll();
  const { modalProps } = useModal();

  // Get props for the dialog and its title
  const { dialogProps, titleProps } = useDialog(props as any, modalRef);

  const _maxWith = getMaxWidth(maxWidth);

  useEffect(
    function attachCloseOnESCEventListener() {
      if (!props.isDismissable) return;

      const handleCloseOnESC = (e: KeyboardEvent) => {
        if (e.key !== 'Escape') return;
        onClose();
      };

      window.addEventListener('keydown', handleCloseOnESC);

      return () => {
        window.removeEventListener('keydown', handleCloseOnESC);
      };
    },
    [onClose, props.isDismissable]
  );

  return (
    <Transition appear show={isOpen || false}>
      <Transition.Child
        enter="transition ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="z-modal fixed inset-0 top-0 flex items-center justify-center overflow-y-scroll p-4"
      >
        {/* The overlay */}
        {!noOverlay && <div className="fixed inset-0 bg-black opacity-25" />}

        <div className="z-10 h-full w-full">
          {/* The panel */}
          <Transition.Child
            enter="transition ease-out duration-300"
            enterFrom="opacity-0 -translate-y-32"
            enterTo="opacity-100 -translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 -translate-y-0"
            leaveTo="opacity-0 -translate-y-32"
            className={clx('mx-auto pb-32 md:px-4', _maxWith, className)}
            {...dialogProps}
          >
            {/* eslint-disable jsx-a11y/no-autofocus */}
            {/* * Could make a prop to turn on/off `autofocus` but when turned on it makes the modal jump the the focus input when clicked anywhere else, which could be annoying  */}
            <FocusScope contain restoreFocus>
              <div
                className={clx(
                  'w-full rounded-lg bg-white',
                  hasError && 'border-red border',
                  spacing === 'none' ? 'p-0' : 'p-4'
                )}
                {...overlayProps}
                {...modalProps}
                ref={modalRef}
              >
                <div className="text-right">
                  <button
                    title="Close modal"
                    aria-label="Close modal"
                    className={clx(
                      'hover:bg-gray-hover focus:bg-gray-hover inline-flex h-6 w-6 items-center justify-center rounded-full p-1 disabled:cursor-wait',
                      spacing === 'none' && 'mr-4 mt-4'
                    )}
                    onClick={onClose}
                  >
                    <CloseIcon light />
                  </button>
                </div>

                <h3 {...titleProps} className="sr-only">
                  {title || ''}
                </h3>

                <div
                  className={clx(
                    'text-center',
                    spacing !== 'none' && 'md:px-4'
                  )}
                >
                  {children}
                </div>
              </div>
            </FocusScope>
          </Transition.Child>
        </div>
      </Transition.Child>
    </Transition>
  );
};

export default ModalOverlayAndPanel;
