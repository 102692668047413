/**
 * Device width aligned to Tailwind standards
 */
export const DEVICE_WIDTH = {
  phone: {
    minWidth: 0,
    maxWidth: 640,
  },
  tablet: {
    minWidth: 640,
    maxWidth: 1024,
  },
  laptopSmall: { minWidth: 769 },
  laptop: {
    minWidth: 1024,
    maxWidth: 1536,
  },
  largeScreen: {
    minWidth: 1536,
  },
};
