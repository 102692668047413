import {
  useAppMediaQuery,
  useDimensionsOnResize,
} from '@tapestry/shared/hooks';
import { Stack } from '@tapestry/weave';
import React, { useRef } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

export const ThreadLoadingSkeletonScreen = () => {
  const panelWidthRef = useRef(null);
  const { width } = useDimensionsOnResize(panelWidthRef);
  const { isPhone } = useAppMediaQuery();

  const lineSkeletonWidth = (width / 4) * (isPhone ? 3 : 2);
  const lineSkeletonWidthInContainer = (width / 4) * 2;

  return (
    <div ref={panelWidthRef}>
      <Stack spacing="medium" withBottomSpacing>
        <div className="flex items-center justify-between">
          <Skeleton width={lineSkeletonWidth} />
          <Skeleton width={10} />
        </div>

        <div>
          <div className="mb-2 flex items-center justify-between">
            <Skeleton width={lineSkeletonWidth} />
            <Skeleton width={10} />
          </div>
          <SkeletonTheme baseColor="#DDD" highlightColor="#eeeeee">
            <div className="flex items-center justify-between rounded-md bg-gray-200 p-2">
              <div className="flex items-center">
                <Skeleton circle width={50} height={50} className="mr-3" />
                <Skeleton width={lineSkeletonWidthInContainer} />
              </div>

              <Skeleton width={10} />
            </div>
          </SkeletonTheme>
        </div>

        <div>
          <div className="mb-2 flex items-center justify-between">
            <Skeleton width={lineSkeletonWidth} />
            <Skeleton width={10} />
          </div>
          <Skeleton height={200} />
          <Skeleton
            height={46}
            width={isPhone ? width : width / 5}
            className="mt-2"
          />
        </div>
      </Stack>
    </div>
  );
};
