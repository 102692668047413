import React, { VFC } from 'react';
import {
  Combobox,
  MainHeading,
  ModalOverlayAndPanel,
  Stack,
} from '@tapestry/weave';
import { OverlayContainer } from '@react-aria/overlays';
import ModalActionButton from '../modal-action-button/ModalActionButton';
import { ErrorBoundary } from '@tapestry/shared/client';

export interface IFilterModalProps {
  show: boolean;
  onClose: VoidFunction;
  onFilterSubmit: (event) => void;
  filters: any[];
}

const mapFilterToInputType = (inputType) => {
  const InputMap = {
    combobox: Combobox,
  };

  return InputMap[inputType] || null;
};

export const FilterModal: VFC<IFilterModalProps> = ({
  onClose,
  onFilterSubmit,
  filters,
}) => {
  const onSubmit = (event) => {
    onFilterSubmit(event);
    onClose();
  };

  return (
    <ErrorBoundary errorView={null}>
      <OverlayContainer>
        <ModalOverlayAndPanel isOpen title="filters" onClose={onClose}>
          <form onSubmit={onSubmit}>
            <MainHeading>Filters</MainHeading>
            <Stack spacing="large" hasTopMargin withBottomSpacing>
              {filters.map((filter) => {
                const FieldComponent = mapFilterToInputType(filter.inputType);
                return (
                  <FieldComponent key={filter.key} {...filter.inputConfig} />
                );
              })}
            </Stack>
            {/* TODO asap */}
            {/* <Checkbox>Set as default filters</Checkbox> */}
            <ModalActionButton
              isLoading={false}
              primaryButtonText="Apply Filters"
              secondaryButtonText="Reset"
              onCancel={onClose}
            />
          </form>
        </ModalOverlayAndPanel>
      </OverlayContainer>
    </ErrorBoundary>
  );
};

export default FilterModal;
