import { IShopSelector, IThreadV4Type } from '@tapestry/shared/graphql';
import React, { VFC } from 'react';
import {
  calcShopOffline,
  calcMinutesSinceLastSynced,
  getReadableDuration,
} from './ShopOfflineBannerUtils';
import { useOverlayTriggerState } from '@react-stately/overlays';
import {
  MainHeading,
  ModalOverlayAndPanel,
  Pill,
  Stack,
} from '@tapestry/weave';
import { Twist } from '@tapestry/types';
import { getTwistByKey } from '@tapestry/shared/utils';
import ModalActionButton from '../../../modal-action-button/ModalActionButton';

interface IStoreOfflineModalProps {
  isOpen: boolean;
  onClose: () => void;
  offlineShops: IThreadV4Type[];
}

const ByLastSyncedAscending = (shopA: IThreadV4Type, shopB: IThreadV4Type) => {
  const lastSyncedA = calcMinutesSinceLastSynced(shopA);
  const lastSyncedB = calcMinutesSinceLastSynced(shopB);

  if (lastSyncedA === null) return 1;
  if (lastSyncedB === null) return -1;

  return lastSyncedA - lastSyncedB;
};

const StoreOfflineModal: VFC<IStoreOfflineModalProps> = ({
  isOpen,
  onClose,
  offlineShops,
}) => {
  return (
    <ModalOverlayAndPanel
      isOpen={isOpen}
      onClose={onClose}
      title="Currently Offline Stores"
      maxWidth="md"
    >
      <MainHeading className="mb-8">Currently Offline Stores</MainHeading>

      <Stack spacing={'xxsmall'}>
        {offlineShops?.sort(ByLastSyncedAscending)?.map((shop) => {
          const lastSynced = calcMinutesSinceLastSynced(shop);
          const readableDurationSincSync = getReadableDuration(lastSynced);

          const shopLogo = getTwistByKey(Twist.Logo, shop)?.value;

          return (
            <div
              key={shop?.id}
              className="border-gray-border flex flex-row items-center justify-between rounded-md border p-2 text-lg font-normal sm:p-5"
            >
              <div className="flex flex-row items-center">
                <span className="min-w-6 mr-4 flex h-6 w-6 items-center justify-center overflow-hidden rounded-full bg-gray-200">
                  {shopLogo ? (
                    <img
                      src={shopLogo}
                      alt="shop's logo"
                      className="bg-gray-400"
                    />
                  ) : null}
                </span>

                <span title={shop?.title || ''} className="truncate">
                  {shop?.title || ''}
                </span>
              </div>

              <p className="text-base">
                Last Synced:{' '}
                <time className="text-gray-text">
                  {readableDurationSincSync || 'unknown'}
                </time>{' '}
                ago
              </p>
            </div>
          );
        })}
      </Stack>

      <ModalActionButton
        isLoading={false}
        primaryButtonText="Close"
        onPrimaryButtonClick={onClose}
      />
    </ModalOverlayAndPanel>
  );
};

export const GroupStoreOfflineBanner: VFC<{
  groupId: string;
  query: IShopSelector;
}> = ({ groupId, query }) => {
  const storeOfflineModalState = useOverlayTriggerState({});
  const activeGroup = query.shopSelector?.groups.find(
    (group) => group.id === groupId
  );

  // filter by shop offline
  const offlineShops =
    activeGroup?.shops?.filter((shop) => {
      const lastSynced = calcMinutesSinceLastSynced(shop);
      const shopIsOffline = calcShopOffline(lastSynced);

      return shopIsOffline;
    }) || [];

  return offlineShops.length ? (
    <>
      <div
        className={`flex flex-row flex-nowrap items-center justify-center  px-4 py-2 text-center text-white ${
          offlineShops.length < 3 ? 'bg-orange-tangerine-dark' : 'bg-red-dark'
        }`}
      >
        <p>Group has {offlineShops.length} offline shops</p>

        <button
          className="ml-2"
          title="Show offline stores"
          onClick={storeOfflineModalState.open}
          onKeyPress={({ key }) => {
            if (key === 'Enter') {
              storeOfflineModalState.open();
            }
          }}
        >
          <Pill
            size="xsmall"
            textStyle="capitalize"
            label={'learn more'}
            bgColor="bg-orange-dark"
          />
        </button>
      </div>

      {storeOfflineModalState.isOpen && (
        <StoreOfflineModal
          isOpen={storeOfflineModalState.isOpen}
          onClose={storeOfflineModalState.close}
          offlineShops={offlineShops}
        />
      )}
    </>
  ) : null;
};
