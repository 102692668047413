import * as React from 'react';
import clx from 'classnames';

interface IMessageTextProps {
  isCurrentUserMessage?: boolean;
}

const MessageText: React.FC<React.PropsWithChildren<IMessageTextProps>> = ({
  children,
  isCurrentUserMessage = false,
}) => (
  <div
    className={clx(
      'w-full rounded-md p-2',
      isCurrentUserMessage ? 'bg-blue text-white' : 'bg-gray-100'
    )}
  >
    {children}
  </div>
);

export { MessageText };
