// *******************************************************
// RecommendedTasks
// -------------------------------------------------------
// Component Description
//
// -------------------------------------------
import React from 'react';
import {
  MeritScoreDiamondIcon,
  MinusIcon,
  PlusIcon,
} from '@tapestry/shared/icons';
import { Disclosure } from '@headlessui/react';
import { Divider, FrameContent } from '@tapestry/weave';
import { MOCK_RECOMMENDED_TASKS } from './mock';
import { TasksList } from '../threads/TaskList/TaskList';

interface IRecommendedTasksProps {
  type?: string;
  replacementWord?: string;
}

export const RecommendedTasks = ({
  type = 'all',
  replacementWord,
}: IRecommendedTasksProps) => {
  //hooks & state
  let recommendedTasks =
    MOCK_RECOMMENDED_TASKS[type] || MOCK_RECOMMENDED_TASKS['all'];

  // functions
  // ! this is only for mock period
  if (replacementWord) {
    recommendedTasks = recommendedTasks.map((task) => ({
      ...task,
      title: task?.title?.replace('__word__', replacementWord),
    }));
  }

  return (
    <FrameContent maxWidth="xl" noVerticalSpacing>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="bg-orange mb-1.5 mt-8 flex w-full items-center justify-center rounded-lg py-4 px-4 text-center font-bold text-white sm:px-7">
              <span className="mx-auto flex items-center justify-center">
                <span className="mr-2">
                  <MeritScoreDiamondIcon fillColor="#fff" />
                </span>
                {recommendedTasks.length} Recommendations
              </span>

              {open ? (
                <MinusIcon light fillColor="#fff" className="h-6 w-auto" />
              ) : (
                <PlusIcon light fillColor="#fff" className="h-6 w-auto" />
              )}
            </Disclosure.Button>
            <Disclosure.Panel>
              <TasksList listData={recommendedTasks} asRecommendedTasks />
              <Divider className="mt-8" />
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </FrameContent>
  );
};
