import React, { FC, KeyboardEvent, useRef, useState } from 'react';
import { CalendarIcon } from '@tapestry/shared/icons';
import { Calendar } from '../../Calendar';
import { usePopper } from 'react-popper';
import { VariationPlacement as PopperJSVariationPlacement } from '@popperjs/core';
import { useAppMediaQuery, useOnClickOutside } from '@tapestry/shared/hooks';
import {
  CalDateRange,
  ICalProps,
  ISOStringsDateRange,
} from '../../Calendar/Calendar';
import isEmpty from 'lodash/isEmpty';
import { ResponsiveRenderingWrapper } from '../utils/ResponsiveRenderingWrapper';
import { WithPresetsWrapper } from './presets';
import clx from 'classnames';
import { formatDatesToDisplay } from './date-range-picker-utils';

/**
 * TODO
 * - Accessibility - https://www.w3.org/WAI/ARIA/apg/example-index/dialog-modal/datepicker-dialog
 */

interface IToggleButtonProps {
  'aria-label': string;
  onClick: () => void;
  onKeyPress: (e: KeyboardEvent) => void;
  ref: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
}

export interface IRenderPropsProps {
  toggleButtonProps: IToggleButtonProps;
  value: CalDateRange | null;
  displayValue: string | null;
}

interface IDateRangePickerProps extends Omit<ICalProps, 'value' | 'onChange'> {
  children?: ((props: IRenderPropsProps) => React.ReactNode) | React.ReactNode;
  value: CalDateRange | null;
  onChange: (date: ISOStringsDateRange) => void;
  withPresets?: boolean;
  withPresetsDefaultView?: 'calendar' | 'presets';
  popperPosition?: PopperJSVariationPlacement;
}

/**
 * A date range picker
 *
 * Use to pick a date range
 *
 * If you are looking to pick a single date, use `DatePicker`
 *
 * @return ISOstring[] - ['2022-11-03T00:00:00.000Z', '2022-11-06T23:59:59.999Z']
 */
export const DateRangePicker: FC<IDateRangePickerProps> = ({
  children,
  withPresets = false,
  withPresetsDefaultView = 'presets',
  popperPosition,
  ...calendarProps
}) => {
  const { value, onChange } = calendarProps;
  const { isPhone, isTablet } = useAppMediaQuery();
  const [shouldShowPresets, setShouldShowPresets] = useState(
    withPresets && withPresetsDefaultView === 'presets' ? true : false
  );
  const [shouldShowCalendar, setShouldShowCalendar] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  );
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  // Currently unused, but we may want to use this in the future
  // const [arrowElement, setArrowElement] = useState(null);
  const popper = usePopper(referenceElement, popperElement, {
    placement: popperPosition || (isTablet ? 'bottom' : 'bottom-start'),
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          padding: { left: isPhone ? 8 : 16, right: isPhone ? 8 : 16 },
        },
      },
    ],
  });

  /**
   * Functions
   */
  const handleTogglePresetsPanelAndRecalcPositionning = (choice: boolean) => {
    // toggle panels
    setShouldShowPresets(choice);
    // update popper positionning
    popper.update && popper.update();
  };

  const closeCalendarAndReset = () => {
    setShouldShowCalendar(false);
    setShouldShowPresets(
      withPresets && withPresetsDefaultView === 'presets' ? true : false
    );
  };

  const handleToggleCalendar = () => {
    setShouldShowCalendar((currState) => !currState);
  };

  const handleSelectDate = (dates: ISOStringsDateRange) => {
    onChange(dates);
    closeCalendarAndReset();
  };

  /**
   * Button props to spread over the toggle button element
   */
  const toggleButtonProps: IToggleButtonProps = {
    'aria-label': `Change Dates, ${
      value ? formatDatesToDisplay(value) : new Date()
    }`,
    onClick: handleToggleCalendar,
    onKeyPress({ key }) {
      if (key === 'Enter') {
        handleToggleCalendar();
      }
    },
    ref: setReferenceElement,
  };

  const childrenProps: IRenderPropsProps = {
    toggleButtonProps,
    value,
    displayValue: value ? formatDatesToDisplay(value) : '',
  };

  useOnClickOutside(wrapperRef, closeCalendarAndReset);

  return (
    <div ref={wrapperRef}>
      {/* If children is simple react node, render children */}
      {children && typeof children !== 'function' ? children : null}

      {/* If children are a render prop, call children with internal props */}
      {children && typeof children === 'function'
        ? children(childrenProps)
        : null}

      {/* Else if no children, render default input */}
      {!children ? (
        <button
          className="border-gray-border pl-15 text-gray-text placeholder-gray-text ring-orange-hank focus-within:border-orange-hank relative inline-flex w-full items-center rounded-md border p-3 text-base uppercase focus-within:ring-1 focus:outline-none sm:text-xl"
          {...toggleButtonProps}
        >
          <time
            dateTime={
              !value || isEmpty(value.filter(Boolean))
                ? undefined
                : formatDatesToDisplay(value)
            }
          >
            {!value || isEmpty(value.filter(Boolean))
              ? 'DD/MM/YYYY - DD/MM/YYYY'
              : formatDatesToDisplay(value)}
          </time>

          <div className="absolute left-0 top-1/2  ml-3 flex h-7 w-7 -translate-y-1/2 items-center justify-center p-2 text-black sm:h-10 sm:w-10 sm:p-3">
            <CalendarIcon fillColor="currentColor" />
          </div>
        </button>
      ) : null}

      {/* Calendar Popover */}
      {shouldShowCalendar && (
        <ResponsiveRenderingWrapper
          isPhone={isPhone}
          shouldShowCalendar={shouldShowCalendar}
          popper={popper}
          onClose={closeCalendarAndReset}
          ref={setPopperElement}
        >
          <WithPresetsWrapper
            withPresets={withPresets}
            shouldShowPresets={shouldShowPresets}
            handleTogglePresetsPanelAndRecalcPositionning={
              handleTogglePresetsPanelAndRecalcPositionning
            }
            handleSelectDate={handleSelectDate}
            isPhone={isPhone}
          >
            <Calendar
              {...calendarProps}
              onChange={handleSelectDate as ICalProps['onChange']}
              isRange
              showDoubleView={isPhone ? false : calendarProps.showDoubleView}
              containerClassName={clx(
                withPresets
                  ? 'sm:rounded-lg sm:rounded-t-none lg:rounded-r-none'
                  : 'rounded-lg',
                isPhone && 'rounded-t-none'
              )}
              flat={withPresets}
            />
          </WithPresetsWrapper>
        </ResponsiveRenderingWrapper>
      )}
    </div>
  );
};

export default DateRangePicker;
