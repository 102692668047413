import { IIconProp } from '@tapestry/shared/icons';

export enum Scopes {
  devMode = 'devMode',
}

export enum Env {
  Local = 'development',
  Staging = 'staging',
  PreRelease = 'pre-release',
  Production = 'production',
  Test = 'test',
}

export type TAppName = 'supply' | 'retail' | 'collective' | 'market';

export interface ITapestryAppConfigurations {
  domains: { [index in Env]: string };
  homePath: string;
  title: string;
  slug: TAppName;
  LDAppKey: string; //DO NOT MODIFY
  icon: React.FC<IIconProp>;
  color: string;
  order: number;
  hidden?: boolean;
}
