/* eslint-disable jsx-a11y/anchor-is-valid */
import { DepartmentsIcon } from '@tapestry/shared/icons';
import { AnalyseButton } from '@tapestry/weave';
import * as React from 'react';

const MessageWithThread = ({ children }) => {
  return (
    <div className="w-full overflow-hidden rounded-md border border-gray-100 bg-gray-100">
      {children}
      <div className="flex flex-row items-center gap-2 bg-white p-4">
        <div className="bg-blue h-10 w-10 overflow-hidden rounded-full p-3">
          <DepartmentsIcon fillColor="white" className="h-full w-full" />
        </div>
        <span className="flex-1 font-bold">Deli</span>
        <a href="#">
          <AnalyseButton variant="icon" />
        </a>
      </div>
    </div>
  );
};

export { MessageWithThread };
