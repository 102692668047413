import { ROUTE_PATHS } from '@tapestry/shared/constants';
import {
  IShopSelectorGroupType,
  IThreadV4Type,
} from '@tapestry/shared/graphql';
import { IAppMediaQueries } from '@tapestry/shared/hooks';
import { LocationPathname, Nullable, THREAD_TYPE } from '@tapestry/types';
import { Maybe } from 'graphql/jsutils/Maybe';

export const LETTERS_AND_NUMBERS_KEYS = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '0',
  'q',
  'w',
  'e',
  'r',
  't',
  'y',
  'u',
  'i',
  'o',
  'p',
  'a',
  's',
  'd',
  'f',
  'g',
  'h',
  'j',
  'k',
  'l',
  'z',
  'x',
  'c',
  'v',
  'b',
  'n',
  'm',
];

export const getSearchPlaceholder = (
  label: string | null | undefined,
  appQueries: IAppMediaQueries
): string => {
  const { isPhone, isTablet } = appQueries;

  if (!label || isPhone || isTablet) return 'Search...';

  // if (isTablet) return `Search ${label}...`;

  return `Search ${label} and more...`;
};

export const mapAppletRoutesToThreadType = (
  pathname: LocationPathname
): { type: THREAD_TYPE; label: string } | null => {
  if (!pathname) return null;

  const mapper: { [k: string]: { type: THREAD_TYPE; label: string } } = {
    [ROUTE_PATHS.tasks]: { type: THREAD_TYPE.TASK, label: 'tasks' },
    [ROUTE_PATHS.products]: { type: THREAD_TYPE.PRODUCT, label: 'products' },
    [ROUTE_PATHS.departments]: {
      type: THREAD_TYPE.DEPARTMENT,
      label: 'departments',
    },
    [ROUTE_PATHS.spaces]: {
      type: THREAD_TYPE.SPACE,
      label: 'spaces',
    },
    [ROUTE_PATHS.categories]: {
      type: THREAD_TYPE.CATEGORY,
      label: 'categories',
    },
    [ROUTE_PATHS.tags]: {
      type: THREAD_TYPE.COLLECTION,
      label: 'collections',
    },
  };

  return mapper[pathname] ?? null;
};

export const getActiveShopFromGroupedShop = (
  groupList: IShopSelectorGroupType[],
  currentActiveShopId: Maybe<string>,
  activeGroupId: Maybe<string>
): IThreadV4Type | IShopSelectorGroupType | null => {
  if (activeGroupId) {
    return groupList?.find((group) => group.id === activeGroupId) || null;
  }

  if (!currentActiveShopId) return null;

  let currentActiveShop: Nullable<IThreadV4Type> = null;

  groupList?.forEach((group) => {
    const possibleActiveShop = group?.shops?.find(
      (shop) => shop?.id === currentActiveShopId
    );

    if (possibleActiveShop) {
      currentActiveShop = possibleActiveShop;
    }
  });

  return currentActiveShop;
};
