/**
 * ! This is our wrapper around the calendar third-party library: react-calendar
 */
import React, { FC, useEffect } from 'react';
import ReactCalendar from 'react-calendar';
import clx from 'classnames';
import {
  parseDateStringToJSDate,
  parseJSDateToISOString,
} from './calendar-utils';
import { Maybe } from 'graphql/jsutils/Maybe';
import { IsoString } from '@tapestry/types';

export type NullableDateString = `${string}-${string}-${string}` | null;
export type CalDateRange = NullableDateString[];
export type ISOStringsDateRange = [IsoString | null, IsoString | null];

export interface ICalProps {
  value: NullableDateString | CalDateRange;
  onChange: (date: IsoString | ISOStringsDateRange) => void;
  isRange?: boolean;
  showDoubleView?: boolean;
  containerClassName?: string;
  minDate?: Maybe<Date>;
  maxDate?: Maybe<Date>;
  /**
   * Renders the calendar without shadow
   */
  flat?: boolean;
}

const shouldTestDate = (date: string | null) => {
  if (!date) return;

  if (date.split('T').length !== 1) {
    throw new Error(
      '<Calendar />: wrong value format provide, make sure your date string, if any, only include the date like so 2023-03-02'
    );
  }

  return;
};

const testValues = (value: ICalProps['value']) => {
  if (process.env.NODE_ENV === 'production') return;

  if (Array.isArray(value)) {
    value.forEach((testDate) => {
      shouldTestDate(testDate);
    });
    return;
  }

  shouldTestDate(value);
};

/**
 * Calendar primitive
 *
 * Displays a calendar, defaulting to monthView
 *
 * Value must be a date string or array of date string like this:
 * '2023-02-23'
 *
 * @see https://github.com/wojtekmaj/react-calendar
 */
export const Calendar: FC<React.PropsWithChildren<ICalProps>> = ({
  value,
  onChange,
  isRange = false,
  showDoubleView = false,
  minDate,
  maxDate,
  containerClassName = 'rounded-lg',
  flat = false,
}) => {
  const handleOnChange = (jsDates: Date | [Date, Date]) => {
    const parsedDates = parseJSDateToISOString(jsDates);
    onChange(parsedDates);
  };

  useEffect(
    function testForCorrectValueType() {
      testValues(value);
    },
    [value]
  );

  return (
    <ReactCalendar
      value={parseDateStringToJSDate(value)}
      onChange={handleOnChange}
      selectRange={isRange}
      showDoubleView={showDoubleView}
      minDate={minDate}
      maxDate={maxDate}
      // this is same level then css classname .react-calendar--doubleView
      className={clx(
        'text-gray-text w-[350px] max-w-full bg-white p-4 sm:w-[600px]',
        containerClassName,
        !flat && 'shadow-xl'
      )}
    />
  );
};
