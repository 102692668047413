export const SHORTCUT = {
  HEARTBEAT: {
    PREV_PERIOD: ',',
    NEXT_PERIOD: '.',
    PREV_METRIC: '[',
    NEXT_METRIC: ']',
    FILTER_RESET: 'r',
  },
  THREAD: {
    CREATE_NEW_THREAD: '',
  },
  APP: {
    FOCUS_SEARCH: 'meta k',
    VIEWS: {
      MENU: '`',
      HEARTBEAT: 'control a',
      TASKS: 'control t',
      PRODUCTS: 'control p',
      DEPARTMENTS: 'control d',
      COLLECTIONS: 'control m',
      SPACES: 'control s',
      CATEGORIES: 'control g',
    },
  },
};
