var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"6aa5394ea4ea3b51d91f3eb9bf95ee33331f773c"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN =
  process.env.NX_SENTRY_SUPPLYPLUS_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

/**
 * -- Replays --
 *
 * Sentry plan has a limit of 500 replays per months
 * that's 16 replays a day
 * As of 14 April 2023, we have 30 users
 * Sampling half would fill the quota
 * but we need to leave room for error Replay
 * might get 13 error replay / day
 */
const chooseSessionSampleRateByEnv = () => {
  if (
    process.env.NX_APP_ENV === 'development' ||
    process.env.NODE_ENV === 'test'
  ) {
    return 0;
  }

  return 0.07;
};

/**
 * -- Trace Sample Rate --
 *
 * - Does not track on development
 * - Track half on staging and production
 */
const chooseTracesSampleRateByEnv = () => {
  switch (process.env.NX_APP_ENV) {
    case 'development':
      return 0;

    default:
      return 0.5;
  }
};

Sentry.init({
  dsn: SENTRY_DSN,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: chooseTracesSampleRateByEnv(),

  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  environment: process.env.NX_APP_ENV,

  // Replay
  replaysSessionSampleRate: chooseSessionSampleRateByEnv(),
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  integrations: [
    new Sentry.BrowserTracing({
      // * you can use regex
      tracePropagationTargets: [
        'localhost',
        process.env.NX_API_REST_URL,
        process.env.NX_API_URL,
      ],
      beforeNavigate: (context) => {
        // Lighthouse-ci tests html files instead of urls.
        // by returning `undefined`, we drop any transactions that would be happening in lighthouse-ci
        if (context.name.includes('.html')) {
          return undefined;
        }

        return context;
      },
    }),
    new Sentry.Replay(),
  ],
});
