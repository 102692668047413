import React from 'react';
import IIconProp from '../iconInterface';

interface IHamburgerProps extends IIconProp {
  IsOpen?: boolean;
}

export const HamburgerIcon = ({
  IsOpen = false,
  className = 'h-full w-auto',
  fillColor = '#000',
}: IHamburgerProps) => {
  return IsOpen ? (
    <svg
      className={className}
      fill={fillColor}
      viewBox="0 0 24 24"
      stroke="black"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill={fillColor}
      viewBox="0 0 24 24"
      stroke={fillColor}
    >
      <path strokeLinejoin="round" strokeWidth="3" d="M4 6h16M4 12h8m-8 6h16" />
    </svg>
  );
};

export default HamburgerIcon;
