import * as React from 'react';

interface IMessageDateProps {
  date: string;
}

const MessageDate: React.VFC<IMessageDateProps> = ({ date }) => {
  return (
    <div className="relative flex h-10 w-full items-center justify-center py-4">
      <span className="absolute block h-[1px] w-full bg-gray-border" />
      <span className="absolute z-10 m-auto block rounded-full border border-gray-border bg-white px-2 text-sm text-gray-text">
        {date}
      </span>
    </div>
  );
};

export { MessageDate };
