import { StaticImageData } from 'next/image';
import React, { FC } from 'react';

interface ILogoProps {
  src: StaticImageData['src'] | null | undefined;
  alt: string | null | undefined;
  size?: 'xsmall' | 'sm' | 'md' | 'lg' | 'full';
}

const resolveSizing = (size: ILogoProps['size']) => {
  switch (size) {
    case 'xsmall':
      return 'h-4 w-4 min-w-4';

    case 'sm':
      return 'h-6 w-6 min-w-6';

    case 'md':
      return 'h-8 w-8 min-w-8';

    case 'lg':
      return 'h-10 w-10 min-w-10';

    case 'full':
      return 'h-full w-auto';

    default:
      return 'h-8 w-8 min-w-8';
  }
};

export const Logo: FC<ILogoProps> = ({ src, alt, size }) => {
  const sizing = resolveSizing(size);

  return (
    <span
      className={`${sizing} flex-shrink-0 overflow-hidden rounded-full bg-gray-400 text-xs`}
    >
      {src && (
        <img
          src={(src as unknown as string) ?? ''}
          alt={alt || ''}
          className="h-full w-full rounded-full"
        />
      )}
    </span>
  );
};
