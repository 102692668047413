import { IShopByThreadShopType, IThreadV4Type } from '@tapestry/shared/graphql';
import { IThreadExtendedType, Nullable, Twist } from '@tapestry/types';
import { getTwistByKey } from '@tapestry/shared/utils';

export interface IProductListItemProps {
  item: Nullable<IThreadV4Type | IThreadExtendedType>;
  isLoading?: boolean;
  asDiv?: boolean;
  onMessageButtonClick?: () => void;
}

export const concatUnitSizeAndMeasure = (
  product: IProductListItemProps['item']
) => {
  const size = getTwistByKey(Twist.UnitSize, product)?.value;
  const unitOfMeasure = getTwistByKey(Twist.UnitOfMeasure, product)?.value;

  return size + unitOfMeasure;
};

export const calculateTotalQuantityInGroup = (
  shopsInGroup: IShopByThreadShopType[] | null | undefined
) => {
  if (!shopsInGroup) return 0;

  return shopsInGroup.reduce((acc, shop) => {
    if (!shop.qty) return acc;
    return acc + shop.qty;
  }, 0);
};

export const filterByCurrentlyStocking = (
  shops: IShopByThreadShopType[] | null | undefined
) => {
  if (!shops) return null;
  return shops.filter((shop) => shop.currentlyStock);
};

export const highestPriceInGroup = (
  shopsInGroup: IShopByThreadShopType[] | null | undefined
) => {
  if (!shopsInGroup || shopsInGroup.length === 0) {
    return 0;
  }

  const unitPriceCounts = shopsInGroup.reduce(
    (acc, shop) => {
      if (!shop.sellPrice) return acc;

      return { ...acc, [shop.sellPrice]: (acc[shop.sellPrice] || 0) + 1 };
    },
    { 0: 0 }
  );

  const maxRepetition = Math.max(...Object.values(unitPriceCounts));

  const highestRepPrice = Object.entries(unitPriceCounts)
    .map(([price, reps]) => (reps === maxRepetition ? Number(price) : null))
    .filter(Boolean) as number[];

  // return the highest price if there are multiple
  return Math.max(...highestRepPrice);
};
