import {
  ChevronLeftIcon,
  PaperPlanIcon,
  PlusIcon,
} from '@tapestry/shared/icons';
import * as React from 'react';
import {
  MessageText,
  MessageContainer,
  MessageDate,
  ConversationTypes,
  MessageImage,
  MessageWithThread,
  MessageCarousel,
} from './components';
import { default as storePic } from '../__mock__/store.png';
import { mockUsers } from '../__mock__/users';
import { AnalyseButton } from '@tapestry/weave';
import uuid from 'react-uuid';

interface IHeartbeatInboxProps {
  onClose: () => void;
  title?: string;
  subTitle?: string;
}

const MockMessage = [
  {
    user: {
      id: uuid(),
      name: 'Hank',
      profileImage: '',
    },
    timestamp: '2hrs ago',
    type: ConversationTypes.System,
    messages: [
      {
        id: uuid(),
        text: 'The Soup Category has been growing each week by 5% over the past 4 weeks.',
      },
    ],
  },
  {
    user: mockUsers.adrian,
    timestamp: '1hrs ago',
    type: ConversationTypes.Conversation,
    messages: [
      {
        id: uuid(),
        text: (
          <p>
            <span className="text-blue">@Christopher</span> Can you look into
            why the deli was down yesterday afternoon?
          </p>
        ),
      },
    ],
  },
  {
    user: {
      id: uuid(),
      name: 'You',
    },
    timestamp: '38min ago',
    type: ConversationTypes.Conversation,
    isCurrentUserMessage: true,
    messages: [
      {
        id: uuid(),
        text: (
          <p>
            <span className="text-white">@Adrian</span> going to check in with{' '}
            <span className="text-white">@lincoln</span> also. Seems to be
            something to do with promotions on Sopressor.
          </p>
        ),
      },
    ],
  },
];

const MessageThread: React.VFC<IHeartbeatInboxProps> = ({
  onClose,
  title,
  subTitle,
}) => {
  const endOfMessagesRef = React.useRef<HTMLDivElement | null>(null);

  const scrollToBottom = React.useCallback(() => {
    endOfMessagesRef.current?.scrollIntoView(true);
  }, []);

  React.useEffect(() => {
    scrollToBottom();
  }, [scrollToBottom]);

  return (
    <div className="flex h-full w-full flex-col">
      <div>
        <div className="flex-shink-0 p-b border-t-gray-border flex items-center border-b p-4 drop-shadow-lg">
          <button
            title="Close Sidebar"
            aria-label="Close Sidebar"
            onClick={onClose}
            className="mr-2 h-4 w-4"
          >
            <ChevronLeftIcon fillColor="#000" />
          </button>
          <h2 className="truncate text-lg font-bold">
            {title || 'Reddrop Group'}
          </h2>
          <div className="flex-1"></div>

          <AnalyseButton />
        </div>
        {subTitle ? (
          <div className="border-t-gray-border border-b bg-gray-100 px-4 py-2 drop-shadow-lg">
            <span className="font-xs">{subTitle}</span>
          </div>
        ) : null}
      </div>
      <div className="flex flex-1 flex-col gap-6 overflow-y-auto p-4">
        <MessageDate date="1st Sep" />
        <MessageContainer
          user={mockUsers.adrian}
          timestamp="4:45pm"
          type={ConversationTypes.Conversation}
        >
          <MessageImage title="store" imageUrl={storePic as unknown as string}>
            <p className="p-2">
              <span className="text-blue">@Tim</span> Great job with the facing
            </p>
          </MessageImage>
        </MessageContainer>
        <MessageDate date="2nd Sep" />
        {MockMessage.map(
          (
            { user, timestamp, messages, type, isCurrentUserMessage },
            index
          ) => (
            <MessageContainer
              key={`${user.id}-${index}`}
              user={user}
              timestamp={timestamp}
              type={type}
              isCurrentUserMessage={isCurrentUserMessage}
            >
              {messages.map(({ id, text }) => (
                <MessageText
                  key={id}
                  isCurrentUserMessage={isCurrentUserMessage}
                >
                  {text}
                </MessageText>
              ))}
            </MessageContainer>
          )
        )}
        <MessageContainer
          user={{ name: 'Hank' }}
          timestamp="8min ago"
          type={ConversationTypes.System}
        >
          <MessageWithThread>
            <p className="p-2">
              HANK the deli department gross margin is below 22% for the past 2
              weeks down by 1.6%.
            </p>
          </MessageWithThread>
        </MessageContainer>

        <MessageContainer
          user={{ name: 'Hank' }}
          timestamp="8min ago"
          type={ConversationTypes.System}
        >
          <MessageCarousel>
            <p className="p-2">
              These products are trending with 18 - 25 year olds across the
              group
            </p>
          </MessageCarousel>
        </MessageContainer>

        <div ref={endOfMessagesRef} className="mb-1" />
      </div>
      <div className="flex-shink-0 border-t-gray-border flex items-center gap-4 border-t p-4">
        <button className="text-blue h-6 w-6 flex-shrink-0">
          <PlusIcon fillColor="currentColor" />
        </button>
        <input
          type="text"
          placeholder="Enter message"
          className="flex-1 rounded-md bg-gray-100 px-4 py-2"
        />
        <button className="text-blue h-6 w-6">
          <PaperPlanIcon fillColor="currentColor" />
        </button>
      </div>
    </div>
  );
};

export { MessageThread };
