// *******************************************************
// ModalActionButton
// -------------------------------------------------------
// A pair of buttons used at the bottom of modals
//
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import React, { ReactElement } from 'react';
// *******************************************
// Component Imports
// -------------------------------------------
import { Button } from '@tapestry/weave';
import clx from 'classnames';

// *******************************************
// Local Interface
// -------------------------------------------
/* eslint-disable-next-line */
export interface IModalActionButtonProps {
  isLoading: boolean;
  primaryButtonText: string;
  secondaryButtonText?: string;
  onCancel?: VoidFunction | null;
  onPrimaryButtonClick?: () => void;
  primaryButtonStatus?: 'primary' | 'danger';
  disabled?: boolean;
  extend?: ReactElement;
  horizontal?: boolean;
  secondaryButtonAs?: ReactElement;
}

// *******************************************
// Main Component
// -------------------------------------------
export const ModalActionButton = ({
  isLoading,
  primaryButtonText,
  secondaryButtonText,
  onCancel = null,
  onPrimaryButtonClick,
  primaryButtonStatus = 'primary',
  disabled = false,
  extend,
  horizontal = false,
  secondaryButtonAs,
}: IModalActionButtonProps) => {
  return (
    <div
      className={clx(
        horizontal
          ? 'flex flex-col space-y-4 sm:flex-row sm:space-x-6 sm:space-y-0'
          : 'flex flex-col space-y-6',
        'items-center justify-center py-8'
      )}
    >
      <Button
        status={primaryButtonStatus}
        type={onPrimaryButtonClick ? 'button' : 'submit'}
        loading={isLoading}
        onClick={onPrimaryButtonClick}
        disabled={disabled}
      >
        {primaryButtonText || 'Create Task'}
      </Button>

      {onCancel && !secondaryButtonAs && (
        <button
          className="active:text-gray-active block underline"
          onClick={onCancel}
          disabled={disabled}
        >
          {secondaryButtonText || 'Cancel'}
        </button>
      )}

      {secondaryButtonAs && secondaryButtonAs}

      {extend && extend}
    </div>
  );
};

export default ModalActionButton;
