import React, { Children, useRef, useState, useEffect, FC } from 'react';
import useSmoothScroll from 'use-smooth-scroll';
import { ChevronLeftIcon, ChevronRightIcon } from '@tapestry/shared/icons';
import { Transition } from '@headlessui/react';
import clx from 'classnames';
import { twMerge } from 'tailwind-merge';

// *******************************************
// Local Interface
// -------------------------------------------
export interface ISliderTrayProps {
  spacing?: 'none' | 'small' | 'medium' | 'large';
  scrollDistance?: number;
  containerClassName?: string;
  listClassName?: string;
  noEdgeGradients?: boolean;
  gradientsColor?: string;
  hideArrowsBelowTablet?: boolean;
  hideArrowsOnMobile?: boolean;
  noArrows?: boolean;
  inline?: boolean;
  arrowClassName?: string;
  scrollStart?: 'start' | 'center' | 'end';
}

// *******************************************
// Action / Utils / Functions Imports
// -------------------------------------------
const getitemSpacing = (spacing: ISliderTrayProps['spacing']) => {
  switch (spacing) {
    case 'none':
      return '';

    case 'small':
      return 'space-x-2';

    case 'medium':
      return 'space-x-3';

    case 'large':
      return 'space-x-6';

    default:
      return 'space-x-3';
  }
};

const getArrowsDisplay = (
  hideOnMobile?: boolean,
  hideBelowTablets?: boolean
) => {
  if (hideBelowTablets) {
    return 'hidden lg:block';
  }
  if (hideOnMobile) {
    return 'hidden xs:block';
  }
  return '';
};

const getDefaultState = () => {
  if (typeof window !== 'undefined') {
    return {
      height: window.innerHeight,
      width: window.innerWidth,
    };
  }

  return {
    height: 0,
    width: 0,
  };
};

// *******************************************
// Main Component
// -------------------------------------------
/**
 * Slider Tray
 *
 * A horizontal sliding tray with arrow on both sides. You can scroll it or click the arrows to move it along
 *
 * @param spacing - controls spacing between items - default: `medium`
 * @param scrollDistance - how much the tray slides left/right per event
 * @param containerClassName - forwards className that you want to apply to the container
 * @param arrowClassName - forwards className that you want to apply to arrow
 * @param listClassName - forwards className to list wrapper around the list items
 * @param noEdgeGradients - hides the edge gradients
 * @param gradientsColor - provide a color for the gradients (i.e. #eee, #fff, etc...)
 * @param noArrows - hides arrows
 * @param hideArrowsBelowTablet - hide arrows below on certain breakpoint
 * @param hideArrowsOnMobile - hide arrows below on certain breakpoint
 * @param scrollStart - Sets the starting position of the slider
 */
export const SliderTray: FC<React.PropsWithChildren<ISliderTrayProps>> = ({
  children,
  spacing = 'medium',
  containerClassName = '',
  scrollDistance = 250,
  listClassName = 'px-px py-px',
  noEdgeGradients = false,
  gradientsColor = 'rgba(255,255,255,1)',
  hideArrowsBelowTablet,
  hideArrowsOnMobile,
  noArrows,
  inline = false,
  arrowClassName = '',
  scrollStart = 'start',
}) => {
  const numberOfChildren = Children.count(children);
  const [dimensions, setDimensions] = useState(getDefaultState());
  const [position, setPosition] = useState(0);
  const [sliderMaxScroll, setSliderMaxScroll] = useState(1);
  const listRef = useRef<HTMLUListElement>(null);
  const menuScrollTo = useSmoothScroll('x', listRef);

  const itemSpacing = getitemSpacing(spacing);
  const arrowDisplay = getArrowsDisplay(
    hideArrowsOnMobile,
    hideArrowsBelowTablet
  );

  const handleManualScrolling = (e: any) => {
    const maxScroll = e.currentTarget.scrollWidth - e.currentTarget.clientWidth;
    const val = Math.ceil(e.currentTarget.scrollLeft);
    setSliderMaxScroll(maxScroll);
    setPosition(maxScroll - val < 5 ? maxScroll : val);
  };

  const handleArrowsScrolling = (isLeftArrow?: boolean) => {
    const maxScroll =
      listRef && listRef.current
        ? listRef?.current?.scrollWidth - listRef?.current?.clientWidth
        : sliderMaxScroll;
    let newPos;
    let newScrollPos;

    if (isLeftArrow) {
      newPos = position - scrollDistance;
      newScrollPos = newPos > 0 ? newPos : 0;
    } else {
      newPos = position + scrollDistance;
      newScrollPos = newPos < maxScroll ? newPos : maxScroll;
    }

    menuScrollTo(newScrollPos);
    setSliderMaxScroll(maxScroll);
    setPosition(newScrollPos);
  };

  useEffect(
    function setMaxScroll() {
      if (!numberOfChildren) return;

      if (listRef && listRef.current) {
        const maxScroll =
          listRef?.current?.scrollWidth - listRef?.current?.clientWidth;

        setSliderMaxScroll(maxScroll);

        const setScrollContainerStartPosition = () => {
          if (scrollStart === 'start') return;

          if (scrollStart === 'center') {
            setPosition(maxScroll / 2);
            menuScrollTo(maxScroll / 2, { duration: 0 });
          }

          if (scrollStart === 'end') {
            setPosition(maxScroll);
            menuScrollTo(maxScroll, { duration: 0 });
          }
        };

        setScrollContainerStartPosition();
      }
    },
    [listRef, dimensions.width, numberOfChildren, scrollStart, menuScrollTo]
  );

  useEffect(() => {
    // Set dimensions to allow scroll logic to re-trigger on screen resize
    const resetDimensionsOnResize = () => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };

    window.addEventListener('resize', resetDimensionsOnResize);
    return window.removeEventListener('resize', resetDimensionsOnResize);
  }, []);

  return (
    <div className={clx('relative', containerClassName, !inline && 'w-full')}>
      {!noArrows && (
        <span
          className={clx(
            arrowDisplay,
            twMerge(
              'absolute left-0 top-1/2 z-10 -translate-y-1/2 -translate-x-1/4',
              arrowClassName
            )
          )}
        >
          <button
            type="button"
            title="Slide left"
            disabled={position === 0}
            className={clx(
              'border-gray-150 min-w-10 flex h-10 w-10 items-center justify-center rounded-full border bg-white p-2 shadow-lg',
              position === 0 && 'hidden'
            )}
            onClick={() => handleArrowsScrolling(true)}
            onKeyPress={({ key }) => {
              if (key === 'Enter') {
                handleArrowsScrolling(true);
              }
            }}
          >
            <ChevronLeftIcon className="h-full w-auto" />
          </button>
        </span>
      )}

      {/* Edge gradient */}
      {!noEdgeGradients && (
        <Transition
          appear
          show={position !== 0}
          enter="transition-opacity duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="absolute top-0 bottom-0 left-0 h-full w-10"
            style={{
              backgroundImage: `linear-gradient(to right, ${gradientsColor}, rgba(255,255,255,0))`,
            }}
          />
        </Transition>
      )}

      <ul
        ref={listRef}
        className={`${itemSpacing} no-scrollbar flex flex-row overflow-x-scroll  ${listClassName}`}
        onScroll={(e) => handleManualScrolling(e)}
      >
        {children}
      </ul>

      {/* Edge gradient */}
      {!noEdgeGradients && (
        <Transition
          appear
          show={position !== sliderMaxScroll}
          enter="transition-opacity duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="absolute top-0 bottom-0 right-0 h-full w-10"
            style={{
              backgroundImage: `linear-gradient(to left, ${gradientsColor}, rgba(255,255,255,0))`,
            }}
          />
        </Transition>
      )}

      {!(noArrows || position >= sliderMaxScroll) && (
        <span
          className={clx(
            arrowDisplay,
            twMerge(
              'absolute right-0 top-1/2 -translate-y-1/2 translate-x-1/4',
              arrowClassName
            )
          )}
        >
          <button
            type="button"
            title="Slide right"
            disabled={position >= sliderMaxScroll}
            className={`border-gray-150 min-w-10 z-10 flex h-10 w-10 items-center justify-center rounded-full border bg-white p-2 shadow-lg ${
              position >= sliderMaxScroll &&
              'pointer-event-none cursor-not-allowed'
            }`}
            onClick={() => handleArrowsScrolling()}
            onKeyPress={({ key }) => {
              if (key === 'Enter') {
                handleArrowsScrolling();
              }
            }}
          >
            <ChevronRightIcon
              className={clx(
                'h-full w-auto',
                position >= sliderMaxScroll && 'opacity-50'
              )}
            />
          </button>
        </span>
      )}
    </div>
  );
};

export default SliderTray;
