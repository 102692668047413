// *******************************************************
// OverlayCarousel
// -------------------------------------------------------
// Component Description
//
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import React, { useRef } from 'react';
import { OverlayContainer } from '@react-aria/overlays';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonNext,
  Dot,
} from 'pure-react-carousel';
// *******************************************
// Component Imports
// -------------------------------------------
import { Button } from '@tapestry/weave';

// *******************************************
// Hook Imports
// -------------------------------------------
import { useOverlay, usePreventScroll, useModal } from '@react-aria/overlays';
import { useDialog } from '@react-aria/dialog';
import { useAppMediaQuery } from '@tapestry/shared/hooks';
import { Url } from '@tapestry/types';

// *******************************************
// Constant Imports
// -------------------------------------------

// *******************************************
// Action / Utils / Functions Imports
// -------------------------------------------

// *******************************************
// Interface Imports
// -------------------------------------------

// *******************************************
// Local Interface
// -------------------------------------------
export interface IOverlayCarouselProps {
  isOpen: boolean;
  isDismissible: boolean;
  hide: () => void;
  slides: {
    image?: string;
    header: string;
    text?: string;
    link?: {
      label?: string;
      url: Url;
    };
  }[];
}

// *******************************************
// Main Component
// -------------------------------------------
/**
 * OverlayCarousel
 *
 * Create an overlayed carousel whereever in are in HANK style
 *
 * @param slides - an array of slide
 * @param hide - callback function
 */
export const OverlayCarousel = ({
  isOpen,
  hide,
  slides,
  ...props
}: IOverlayCarouselProps) => {
  /**
   * Hooks
   */
  const { isPhone } = useAppMediaQuery();
  const containerRef = useRef<HTMLDivElement>(null);

  // Handle interacting outside the dialog and pressing
  // the Escape key to close the modal.
  const modalRef = React.useRef(null);
  const { overlayProps } = useOverlay(props as any, modalRef);

  // Prevent scrolling while the modal is open, and hide content
  // outside the modal from screen readers.
  usePreventScroll();
  const { modalProps } = useModal();

  // Get props for the dialog and its title
  const { dialogProps, titleProps } = useDialog(props as any, modalRef);

  /**
   * Const
   */
  const isLastSlide = (idx: number) => slides.length - 1 === idx;

  const handleScrollToTop = () => {
    if (containerRef && containerRef.current) {
      containerRef.current.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }
  };

  return (
    <OverlayContainer>
      <div
        className="fixed inset-0 flex min-h-screen w-screen flex-col items-center justify-start overflow-y-scroll"
        style={{ zIndex: 9998 }}
        id="testscroll"
        ref={containerRef}
      >
        {/* The overlay */}
        <div className="fixed inset-0 bg-black opacity-50" {...overlayProps} />

        {/* <div className="text-right">
            <button
              title="Close modal"
              aria-label="Close modal"
              className="h-8 w-8 rounded-full inline-flex items-center justify-center hover:bg-gray-hover focus:bg-gray-hover disabled:cursor-wait"
              onClick={hide}
            >
              <CloseIcon />
            </button>
          </div> */}

        <CarouselProvider
          naturalSlideWidth={728}
          naturalSlideHeight={724}
          totalSlides={slides?.length || 0}
          orientation="horizontal"
          className="mt-8 w-full sm:mt-16"
          lockOnWindowScroll
          dragEnabled={false}
          // touchEnabled
        >
          {/* <FocusScope contain restoreFocus autoFocus> */}
          {/* <div {...dialogProps} {...modalProps} ref={modalRef}> */}
          <Slider className="mx-4 mb-8" moveThreshold={0.5}>
            {slides?.map((slide, index) => (
              <Slide
                key={slide.header}
                index={index}
                className="inline-block rounded-lg bg-white"
              >
                <div>
                  <img
                    src={slide.image}
                    alt="illustration"
                    style={{ height: isPhone ? '200px' : '300px' }}
                    className="w-full rounded-t-lg object-cover object-center"
                    aria-hidden="true"
                  />

                  <div className="py-8 px-5 text-center sm:py-12 sm:px-12">
                    <h6 className="mb-3 text-3xl font-bold leading-9 text-black">
                      {slide.header}
                    </h6>

                    <p className="text-gray-text text-base">{slide.text}</p>

                    {slide?.link?.label && (
                      <a
                        href={slide?.link?.url}
                        className="text-blue mt-3 inline-block underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {slide?.link?.label || 'learn more'}
                      </a>
                    )}

                    {/* TODO */}
                    {/* eslint-disable */}
                    <div onClick={handleScrollToTop}>
                      {isLastSlide(index) ? (
                        <Button
                          status="primary"
                          className="my-8 sm:my-12"
                          onClick={hide}
                          fullWidth={isPhone}
                        >
                          Done
                        </Button>
                      ) : (
                        <ButtonNext
                          className={`bg-blue border-blue hover:bg-blue-dark hover:border-blue-dark focus:border-blue-light disabled:bg-button-disabled my-8 rounded-lg border-2 py-2 px-6 text-white transition-colors duration-300 ease-out focus:outline-none disabled:cursor-not-allowed disabled:border-gray-700 sm:my-12 ${
                            isPhone ? 'w-full' : ''
                          }`}
                        >
                          Next
                        </ButtonNext>
                      )}

                      <div className="space-x-3">
                        {slides.map((slide, idx) => (
                          <Dot
                            key={slide.header}
                            slide={idx}
                            className="active:bg-blue h-5 w-5 rounded-full bg-gray-200"
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
            ))}
          </Slider>
          {/* </div> */}
          {/* </FocusScope> */}
        </CarouselProvider>
      </div>
    </OverlayContainer>
  );
};

export default OverlayCarousel;
