// *******************************************************
// DeleteThreadModal
// -------------------------------------------------------
// Component Description
//
// -------------------------------------------
import React, { useState } from 'react';
import { MainHeading, ModalOverlayAndPanel, TextInput } from '@tapestry/weave';
import ModalActionButton from '../modal-action-button/ModalActionButton';
import { Transition } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import { dasherize } from '@tapestry/shared/utils';
import { Nullable } from '@tapestry/types';
import { useToast } from '@tapestry/shared/client';
import { IThreadV4Type, useDeleteThread } from '@tapestry/shared/graphql';
export interface IDeleteThreadModalProps {
  show: boolean;
  hide: (deleted?: { deleted: boolean }) => void;
  thread: Nullable<IThreadV4Type>;
  onDeleteComplete?: VoidFunction;
}

type FormData = {
  threadName: string;
};

// *******************************************
// Main Component
// -------------------------------------------
export const DeleteThreadModal = ({
  show,
  hide,
  onDeleteComplete,
  thread,
}: IDeleteThreadModalProps) => {
  /**
   * Hooks
   */
  const { addToast } = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const [shouldShowErrorMessage, setShouldShowErrorMessage] = useState<
    string | null
  >(null);
  const [handleDeleteThread, { loading }] = useDeleteThread({
    variables: {
      uid: thread?.id || '',
    },
    onCompleted: () => {
      addToast({
        type: 'taskDeleted',
        content: 'Successfully deleted',
      });

      if (onDeleteComplete) {
        onDeleteComplete();
      }

      hide({ deleted: true });
    },
    onError: () => {
      addToast({
        type: 'error',
        content: `Error deleting ${thread?.threadType}`,
      });
      setShouldShowErrorMessage(
        'A Error has occurred whilst trying to delete this task'
      );
    },
    // refetchQueries: ['getThreads'],
  });

  /**
   * Const
   */
  const deletionTextValue = `delete-${dasherize(thread?.title).toLowerCase()}`;

  const onSubmit = ({ threadName }: FormData) => {
    if (threadName === deletionTextValue && thread?.id) {
      setShouldShowErrorMessage(null);
      handleDeleteThread();
    } else {
      setShouldShowErrorMessage(
        'The input value does not match the task title'
      );
    }
  };

  return (
    <ModalOverlayAndPanel
      isOpen={show}
      onClose={hide}
      title={`Delete ${thread?.threadType}`}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="px-8 md:px-32">
        <MainHeading className="mb-3">Delete {thread?.threadType}</MainHeading>
        <p className="text-gray mb-6">
          You are about to delete this {thread?.threadType}. This cannot be
          undone. All related information to the {thread?.threadType} will be
          deleted including historical data related to the {thread?.threadType}.
        </p>

        <p className="text-gray mb-2">
          Confirm by typing{' '}
          <span className="text-red">{deletionTextValue}</span> below
        </p>

        <TextInput
          name="threadName"
          register={register}
          errors={errors}
          placeholder={`${deletionTextValue}`}
        />

        {shouldShowErrorMessage && (
          <Transition
            appear
            show
            enter="transition-opacity duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="text-red mt-2 -mb-4 text-sm"
            as="p"
          >
            {shouldShowErrorMessage || 'sorry, an error has happened'}
          </Transition>
        )}

        <ModalActionButton
          isLoading={loading}
          primaryButtonText={`Delete ${thread?.threadType} permanently`}
          primaryButtonStatus="danger"
          onCancel={hide}
        />
      </form>
    </ModalOverlayAndPanel>
  );
};

export default DeleteThreadModal;
