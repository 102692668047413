import React from 'react';
import { Control } from 'react-hook-form';
import {
  FormInputBase,
  IFormInputBaseProps,
} from '../FormInputBase/FormInputBase';
import { DynamicPhoneNumberInput } from '../PhoneNumberInput';

interface IFormPhoneNumberInput extends IFormInputBaseProps {
  control: Control;
}

export const FormPhoneNumberInput = React.forwardRef<
  unknown,
  IFormPhoneNumberInput
>((props, ref) => {
  return (
    <FormInputBase {...props}>
      <DynamicPhoneNumberInput name={props.name} control={props.control} />
    </FormInputBase>
  );
});
