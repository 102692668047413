import { captureException } from '@sentry/nextjs';
import * as React from 'react';

interface IErrorBondaryProps {
  errorView?: React.ReactNode;
  onError?: (error: any) => void;
  children: React.ReactNode;
}

interface IState {
  hasError: boolean;
  errorMessage: any;
}

export class ErrorBoundary extends React.Component<IErrorBondaryProps, IState> {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { errorMessage: error, hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV !== 'test') {
      captureException(error);

      this.props.onError && this.props.onError(error);
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      if (this.props.errorView) {
        return this.props.errorView;
      }

      return <ErrorUI />;
    }

    return this.props.children;
  }
}

interface IErrorView {
  error?: Error;
  info?: React.ErrorInfo; // Gives you the stack
  clearError?: () => void;
}

export const ErrorUI = ({ error }: IErrorView) => {
  return (
    <div className="mx-auto mt-12 max-w-xl">
      <div className="bg-white">
        <div className="mx-auto max-w-7xl py-12 px-4 text-center sm:px-6 lg:py-16 lg:px-8">
          <h2 className="text-gray-900">
            <span className="block text-2xl font-extrabold tracking-tight sm:text-3xl">
              Something went wrong.
            </span>
            <span className="block text-xl">
              We have been notified of the issue and are will be looking into
              the&nbsp;issue.
            </span>
            {/*{JSON.stringify(this.state.errorMessage)}*/}
          </h2>
          <div className="mt-8 flex justify-center">
            <div className="inline-flex rounded-md shadow">
              <a
                href="/"
                className="bg-button-primary hover:bg-button-primary-hover inline-flex items-center justify-center rounded-md border border-transparent px-5 py-3 text-base font-medium text-white"
              >
                Return to home
              </a>
            </div>
            <div className="ml-3 inline-flex">
              <a
                href="#"
                className="inline-flex items-center justify-center px-5 py-3 text-base font-medium"
              >
                Learn more
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
