import { encodeObject } from 'use-query-params';
import { VIEW } from '..';

export const ROUTE_PATHS = {
  homepage: '/',
  // Auth
  login: '/login',
  logout: '/logout',
  register: '/register',
  recovery: '/password-recovery',
  password_reset: '/password-reset',
  ManageAccount: '/account',
  // Core
  menu: '/menu',
  search: '/search',
  shop: '/shop',
  // Applets
  tasks: '/tasks',
  tags: '/manufacturers',
  manufacturers: '/manufacturers',
  categories: '/categories',
  departments: '/departments',
  heartbeat: '/rta',
  analytics: '/rta',
  products: '/products',
  spaces: '/spaces',
  deals: '/deals',
  transactions: '/transactions',
  transaction: '/transactions/:transactionId',
  suppliers: '/suppliers',
  staff: '/staff',
  thread: '/thread/:threadId',
  createNewThread: '/thread/create',
  threadSummary: '/thread/:threadId/summary',
  // Misc
  safetyCulture: 'https://safetyculture.com/',
  afterpay: 'https://www.afterpay.com/en-AU',
  canva: 'https://www.canva.com/en_gb/',
  // Buildable
  build: {
    shop: (id: string): string => `/shop?shopId=${id}`,
    thread: (id: string, defaultView?: VIEW): string =>
      `/thread?id=${id}${defaultView ? `&view=${defaultView}` : ''}`,
    transaction: (id: string, defaultView?: VIEW): string =>
      `/transaction?id=${id}${defaultView ? `&view=${defaultView}` : ''}`,
    threadSummary: (id: string, qpObject?: Record<string, any>): string => {
      return `/thread/summary${
        qpObject
          ? `?id=${encodeURIComponent(id)}&${encodeObject(qpObject, '=', '&')}`
          : `?id=${encodeURIComponent(id)}`
      }`;
    },
    heartbeat: ({
      threadType = null,
      qp = '',
    }: {
      threadType?: string | null;
      qp?: string;
    }) => `/rta${threadType ? `/${threadType}` : ''}?${qp}`,
    deal: (id: string): string => `/deals?shopId=${id}`,
  },
};
