import React, { FunctionComponent } from 'react';
import IIconProp from '../iconInterface';

export const StockLevelIcon = ({ fillColor = '#000' }: IIconProp) => {
  return (
    <svg
      className="w-auto"
      height="38px"
      viewBox="0 0 38 38"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          fill={fillColor}
          d="M30.787037,15.8333333 L26.3888889,15.8333333 L26.3888889,21.1111111 L24.6296296,19.9401042 L22.8703704,21.1111111 L22.8703704,15.8333333 L18.4722222,15.8333333 C17.9884259,15.8333333 17.5925926,16.2291667 17.5925926,16.712963 L17.5925926,27.2685185 C17.5925926,27.7523148 17.9884259,28.1481481 18.4722222,28.1481481 L30.787037,28.1481481 C31.2708333,28.1481481 31.6666667,27.7523148 31.6666667,27.2685185 L31.6666667,16.712963 C31.6666667,16.2291667 31.2708333,15.8333333 30.787037,15.8333333 Z M9.67592593,12.3148148 L21.9907407,12.3148148 C22.474537,12.3148148 22.8703704,11.9189815 22.8703704,11.4351852 L22.8703704,0.87962963 C22.8703704,0.395833333 22.474537,0 21.9907407,0 L17.5925926,0 L17.5925926,5.27777778 L15.8333333,4.10677083 L14.0740741,5.27777778 L14.0740741,0 L9.67592593,0 C9.19212963,0 8.7962963,0.395833333 8.7962963,0.87962963 L8.7962963,11.4351852 C8.7962963,11.9189815 9.19212963,12.3148148 9.67592593,12.3148148 Z M13.1944444,15.8333333 L8.7962963,15.8333333 L8.7962963,21.1111111 L7.03703704,19.9401042 L5.27777778,21.1111111 L5.27777778,15.8333333 L0.87962963,15.8333333 C0.395833333,15.8333333 0,16.2291667 0,16.712963 L0,27.2685185 C0,27.7523148 0.395833333,28.1481481 0.87962963,28.1481481 L13.1944444,28.1481481 C13.6782407,28.1481481 14.0740741,27.7523148 14.0740741,27.2685185 L14.0740741,16.712963 C14.0740741,16.2291667 13.6782407,15.8333333 13.1944444,15.8333333 Z"
          id="StockLevelIconpath-1"
        ></path>
      </defs>
      <g
        id="StockLevelIcon"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="StockLevelIconColours/Black-#000000"
          transform="translate(3.166667, 4.750000)"
        >
          <mask id="StockLevelIconmask-2" fill="white">
            <use xlinkHref="#StockLevelIconpath-1"></use>
          </mask>
          <use
            id="StockLevelIconMask"
            fill={fillColor}
            fillRule="nonzero"
            xlinkHref="#StockLevelIconpath-1"
          ></use>
          <g id="StockLevelIconGroup" mask="url(#StockLevelIconmask-2)">
            <g
              transform="translate(-19.000000, -20.583333)"
              id="StockLevelIconColours/Black-#000000"
            >
              <rect
                id="StockLevelIconRectangle"
                fill={fillColor}
                x="0"
                y="0"
                width="69.6666667"
                height="69.6666667"
              ></rect>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default StockLevelIcon;
