import {
  CategoriesIcon,
  CloseIcon,
  DepartmentsIcon,
  HankHLogoIcon,
  RetailLogoIcon,
  StockLevels,
  UsersIcon,
} from '@tapestry/shared/icons';
import * as React from 'react';
import { mockUsers } from '../__mock__/users';
import clx from 'classnames';
import Link from 'next/link';
import Image from 'next/image';
import { Avatar } from '@tapestry/weave';

type ThreadInfo = {
  icon: React.ReactNode;
  colorClassName: string;
  textClassName: string;
};

const saleThreadInfo = {
  icon: <RetailLogoIcon fillColor="#FFFFFF" className="h-full w-full" />,
  colorClassName: 'orange',
  textClassName: 'text-orange',
};

const departmentThreadInfo = {
  icon: <DepartmentsIcon fillColor="#FFFFFF" className="h-full w-full" />,
  colorClassName: 'blue-dark',
  textClassName: 'text-blue-dark',
};

const demographicThreadInfo = {
  icon: <UsersIcon fillColor="#FFFFFF" className="h-full w-full" />,
  colorClassName: 'cerulean',
  textClassName: 'text-cerulean',
};

const categoryThreadInfo = {
  icon: <CategoriesIcon fillColor="#FFFFFF" className="h-full w-full" />,
  colorClassName: 'pink',
  textClassName: 'text-pink',
};

const productThreadInfo = {
  icon: <StockLevels fillColor="#FFFFFF" className="h-full w-full" />,
  colorClassName: 'purple',
  textClassName: 'text-purple',
};

const messages: Array<{
  user: {
    id?: string;
    name: string;
    profileImage?: string;
  };
  message: string;
  threadInfo: ThreadInfo;
  threadTitle: string;
  threadSubtitle?: string;
  threadLink?: string;
  isSystemMessage?: boolean;
  isNew?: boolean;
}> = [
  {
    user: mockUsers.michael,
    message:
      'Tagged you in a conversation @Christopher “Can you look into why this is down yesterday"',
    threadInfo: saleThreadInfo,
    threadTitle: 'Brighton Grocer',
    isNew: true,
    threadLink: 'analytics?shopId=b1b9d92f-ee82-4aeb-8d98-fb6cf1ea77e3',
  },
  {
    user: {
      name: 'Hank',
    },
    isSystemMessage: true,
    message: '18 - 25 year olds are buying alot of these products…',
    threadInfo: demographicThreadInfo,
    threadTitle: '18 - 25 Year Old Females',
    threadSubtitle: 'Reddrop Group',
    threadLink:
      'analytics?departmentId=53c9bfcf-c757-4979-bb5e-91d4544deb35&duration=custom&endDate=2022-07-03T23:59:59%2B10:00&groupId=8114733b-10fc-4c6e-861e-dba80714607c&returnTo=%2Fsearch%3FactiveThreadType%3Ddepartment%26groupId%3D8114733b-10fc-4c6e-861e-dba80714607c%26searchTerm%3DDeli&searchPagination=product-1_task-1_department-3_category-1_collection-1&startDate=2022-06-27T00:00:00%2B10:00',
    isNew: true,
  },

  {
    user: mockUsers.chirstopher,
    message:
      'Tagged you in a conversation @Christopher “Can you look into why this is down yesterday"',
    threadInfo: departmentThreadInfo,
    threadTitle: 'Deli Department',
    threadSubtitle: 'Benella Grocer',
    threadLink:
      'analytics?departmentId=53c9bfcf-c757-4979-bb5e-91d4544deb35&duration=custom&endDate=2022-07-10T23:59:59%2B10:00&returnTo=%2Fsearch%3FactiveThreadType%3Ddepartment%26searchTerm%3Ddeli%26shopId%3D614047aa-c8c7-4f6d-bb6d-d0a67338499c&shopId=614047aa-c8c7-4f6d-bb6d-d0a67338499c&startDate=2022-07-04T00:00:00%2B10:00',
    isNew: true,
  },
  {
    user: mockUsers.lincoln,
    message:
      'Added a conversation - These pretzzels are making me thirsty. Is there…',
    threadInfo: departmentThreadInfo,
    threadTitle: 'Deli Department',
    threadSubtitle: 'Wye River Grocer',
    threadLink:
      'analytics?departmentId=5965e6c4-a7ec-4160-8de2-1dca905f0884&duration=custom&endDate=2022-07-10T23:59:59%2B10:00&returnTo=%2Fsearch%3FactiveThreadType%3Ddepartment%26searchTerm%3Ddeli%26shopId%3D53baf613-bca9-4df8-a9d9-7261c5427af6&shopId=53baf613-bca9-4df8-a9d9-7261c5427af6&startDate=2022-07-04T00:00:00%2B10:00',
  },
  {
    user: mockUsers.luke,
    message:
      'Tagged you in a conversation @Christopher “Can you look into why this is down yesterday"',
    threadInfo: categoryThreadInfo,
    threadTitle: 'Grocery',
    threadSubtitle: 'Brighton Grocer',
    threadLink:
      'analytics?departmentId=be1585fe-0b2b-4b42-a18a-6d87393a8c5b&duration=custom&endDate=2022-07-10T23:59:59%2B10:00&returnTo=%2Fsearch%3FactiveThreadType%3Ddepartment%26searchTerm%3Dgrocer%26shopId%3Db1b9d92f-ee82-4aeb-8d98-fb6cf1ea77e3&shopId=b1b9d92f-ee82-4aeb-8d98-fb6cf1ea77e3&startDate=2022-07-04T00:00:00%2B10:00',
  },
  {
    user: {
      name: 'Hank',
    },
    isSystemMessage: true,
    message: 'Units Sold has dropped below the normal amount for this item',
    threadInfo: productThreadInfo,
    threadTitle: 'TIP TOP BUN GOURMET - Reddrop Group',
  },
  {
    user: mockUsers.lincoln,
    message:
      'Those new non-alchoholics seem to be moving well. Should we do a…',
    threadInfo: categoryThreadInfo,
    threadTitle: 'Liquor',
    threadSubtitle: 'Alexandra FoodWorks',
    threadLink:
      'analytics?departmentId=b60caa9c-ff1f-40ae-9253-abdc275c9ddc&duration=custom&endDate=2022-07-10T23:59:59%2B10:00&returnTo=%2Fsearch%3FactiveThreadType%3Ddepartment%26searchTerm%3DLiquor%26shopId%3Dafd27827-6b46-4512-90f0-72af7de6aa91&shopId=afd27827-6b46-4512-90f0-72af7de6aa91&startDate=2022-07-04T00:00:00%2B10:00',
  },
  {
    user: mockUsers.adrian,
    message:
      'Tagged you in a conversation @Christopher “Can you look into why this is down yesterday"',
    threadInfo: productThreadInfo,
    threadTitle: 'YOUFOODZ JCE GETCHA ',
    threadSubtitle: 'Brighton Grocer',
    threadLink:
      'analytics?duration=trailingMonth&endDate=2022-07-10T23:59:59%2B10:00&productId=8c7d62a1-8975-400c-8a5b-c2178bba76ce&returnTo=%2Fsearch%3FactiveThreadType%3Dproduct%26searchTerm%3DYOUFOODZ%2520JCE%2520GETCHA%26shopId%3Db1b9d92f-ee82-4aeb-8d98-fb6cf1ea77e3&shopId=b1b9d92f-ee82-4aeb-8d98-fb6cf1ea77e3&startDate=2022-07-04T00:00:00%2B10:00',
  },
  {
    user: mockUsers.tammy,
    message:
      'Tagged you in a conversation @Christopher “Can you look into why this is down yesterday"',
    threadInfo: departmentThreadInfo,
    threadTitle: 'Meat Department',
    threadSubtitle: ' Alexandra FoodWorks',
    threadLink:
      'analytics?departmentId=36c89f56-f20a-4507-93a0-7818994552b6&duration=custom&endDate=2022-07-10T23:59:59%2B10:00&returnTo=%2Fsearch%3FactiveThreadType%3Ddepartment%26searchTerm%3DMeat%26shopId%3Dafd27827-6b46-4512-90f0-72af7de6aa91&shopId=afd27827-6b46-4512-90f0-72af7de6aa91&startDate=2022-07-04T00:00:00%2B10:00',
  },
  {
    user: {
      name: 'Hank',
    },
    isSystemMessage: true,
    message: 'Units Sold has dropped below the normal amount for this item',
    threadInfo: demographicThreadInfo,
    threadTitle: '18 - 25 Year Old Females',
    threadSubtitle: 'Reddrop Group',
    threadLink:
      'analytics?departmentId=53c9bfcf-c757-4979-bb5e-91d4544deb35&duration=custom&endDate=2022-07-03T23:59:59%2B10:00&groupId=8114733b-10fc-4c6e-861e-dba80714607c&returnTo=%2Fsearch%3FactiveThreadType%3Ddepartment%26groupId%3D8114733b-10fc-4c6e-861e-dba80714607c%26searchTerm%3DDeli&searchPagination=product-1_task-1_department-3_category-1_collection-1&startDate=2022-06-27T00:00:00%2B10:00',
  },
];

const Conversations = ({ onClose, onMessageThreadClick }) => {
  return (
    <div className="flex h-full w-full flex-col">
      <div className="flex-shink-0 p-b border-t-gray-border flex items-center border-b p-4 drop-shadow-lg">
        <h2 className="text-lg font-bold">Conversations</h2>
        <div className="flex-1"></div>
        <button
          title="Close Sidebar"
          aria-label="Close Sidebar"
          onClick={onClose}
          className="text-gray-text flex h-8 w-8 items-center justify-center rounded-full p-1 transition-colors duration-150 ease-in hover:text-black  focus:text-black focus:outline-none"
        >
          <CloseIcon light fillColor="currentColor" />
        </button>
      </div>
      <div className="flex flex-1 flex-col overflow-y-auto">
        {messages.map(
          (
            {
              user,
              message,
              threadInfo,
              threadTitle,
              threadSubtitle,
              threadLink = '#',
              isNew,
            },
            index
          ) => (
            // Message Items
            <div
              key={index}
              className={clx(
                'border-b-gray-border flex cursor-pointer flex-row gap-x-2 border-b p-2',
                isNew && 'bg-orange-lightest'
              )}
              onClick={() =>
                onMessageThreadClick({ threadTitle, threadSubtitle })
              }
            >
              {/* Avatar Component*/}
              <div className="relative h-20 w-20 flex-shrink-0">
                <div className="h-full w-full overflow-hidden rounded-full">
                  {!user.profileImage ? (
                    <div className="bg-button-primary h-full w-full p-2 ">
                      <HankHLogoIcon fillColor="white " />
                    </div>
                  ) : (
                    <Avatar
                      alt={`${user.name}`}
                      src={user.profileImage}
                      size="2xl"
                    />
                  )}
                </div>
                {threadInfo ? (
                  <Link
                    href={threadLink}
                    onClick={(e) => {
                      e.stopPropagation();
                      onClose();
                    }}
                    className={clx(
                      'absolute right-0 bottom-0 h-8 w-8 rounded-full p-2',
                      `bg-${threadInfo.colorClassName}` || 'bg-gray-100'
                    )}
                  >
                    {threadInfo.icon}
                  </Link>
                ) : null}
              </div>

              {/* Message details*/}
              <div className="flex flex-1 flex-col overflow-hidden">
                <div className="flex items-center justify-between">
                  <span className="font-bold capitalize">{user.name}</span>
                  <div className="flex items-center">
                    <span className="text-gray-text text-xs">35 min </span>
                    {isNew ? (
                      <span className="bg-button-primary ml-1 inline-block h-3 w-3 rounded-full" />
                    ) : null}
                  </div>
                </div>
                <p className="text-gray-text h-8 overflow-hidden text-ellipsis text-xs leading-4">
                  {message}
                </p>
                <Link
                  href={threadLink}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClose();
                  }}
                  className={clx(
                    'text-xs',
                    threadInfo.textClassName || 'text-gray-text'
                  )}
                >
                  {[threadTitle, threadSubtitle].filter(Boolean).join(' - ')}
                </Link>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export { Conversations };
