import React, { FC, ReactElement } from 'react';
import { CloseIcon } from '@tapestry/shared/icons';
import clx from 'classnames';

const getSizing = (
  size: IPillProps['size'],
  fontWeight: IPillProps['fontWeight']
) => {
  switch (size) {
    case 'xsmall':
      return `text-xxs px-2 py-1 ${fontWeight || 'font-medium'}`;

    case 'small':
      return `text-xs px-2 py-1 ${fontWeight || 'font-bold'}`;

    case 'big':
      return `text-sm px-3 py-px ${fontWeight || 'font-medium'}`;

    case 'xxl':
      return `text-base px-6 py-4 ${fontWeight || 'font-bold'}`;

    default:
      return `text-sm px-3 py-px ${fontWeight || 'font-medium'}`;
  }
};

interface IPillProps {
  label: string | number;
  className?: string;
  bgColor?: string;
  textColor?: string;
  fontWeight?: string;
  size?: 'xsmall' | 'small' | 'big' | 'xxl';
  iconLeft?: ReactElement | null;
  iconRight?: ReactElement | null;
  onClear?: (event: React.MouseEvent) => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  title?: string;
  textStyle?: 'uppercase' | 'capitalize' | 'lowercase';
}

/**
 * Pill
 *
 * A pill shape label
 *
 * alt: Tag
 *
 * @param {string} label - provide the pill label,
 * @param {string} title - a onHover title
 * @param className - tailwind classname
 * @param bgColor - as Css prop
 * @param textColor - default:'text-white' - use to change the text color
 * @param fontWeight - as string
 * @param size - default = 'big' - 'big | 'small',
 * @param iconLeft - as FC,
 * @param iconRight - as FC,
 * @param onClear
 */
export const Pill: FC<React.PropsWithChildren<IPillProps>> = ({
  label,
  title,
  className,
  bgColor,
  textColor = 'text-black',
  size = 'big',
  iconLeft = null,
  iconRight = null,
  onClear,
  onKeyDown,
  fontWeight,
  textStyle = 'uppercase',
}) => {
  const sizing = getSizing(size, fontWeight);
  const tailwindBGColor = bgColor && bgColor.startsWith('bg') ? bgColor : null;
  const cssBGColor = bgColor && !bgColor.startsWith('bg') ? bgColor : '';
  return (
    <span
      className={`${sizing} ${
        tailwindBGColor || 'bg-gray-300'
      } inline-flex items-center justify-center rounded-full text-center transition-shadow duration-150 focus-within:shadow ${textColor} ${className}`}
      style={{ backgroundColor: cssBGColor }}
      title={title}
    >
      {iconLeft && (
        <span
          className={clx(
            'flex items-center justify-center',
            size === 'xxl' ? 'mr-4 h-6' : 'mr-2 h-4'
          )}
        >
          {iconLeft}
        </span>
      )}

      <p className={textStyle}>{label}</p>

      {iconRight && (
        <span
          className={clx(
            'flex items-center justify-center',
            size === 'xxl' ? 'ml-4 h-6' : 'ml-2 h-4'
          )}
        >
          {iconRight}
        </span>
      )}

      {!!onClear && (
        <span
          tabIndex={-1}
          aria-label="remove"
          role="button"
          title={`remove ${label}`}
          className="ml-2 flex h-4 w-4 items-center justify-center rounded-full focus:outline-none"
          onClick={onClear}
          onKeyDown={(e) => {
            onKeyDown && onKeyDown(e);
          }}
        >
          <span className="sr-only">Remove {label}</span>
          <CloseIcon light fillColor="currentColor" />
        </span>
      )}
    </span>
  );
};

export default Pill;
