import React, { useState, VFC } from 'react';
import { Avatar, Button, Pill } from '@tapestry/weave';
import { Transition } from '@headlessui/react';
import { useRouter } from 'next/router';
import { useAppMediaQuery, useProfile } from '@tapestry/shared/hooks';
import { getTwistByKey, getTwistID } from '@tapestry/shared/utils';
import {
  getHumanizedDuration,
  getsubLabel,
  renderDatetime,
} from './taskListItemUtils';
import {
  IDropDownActionItem,
  IMockRecommendedTask,
  Nullable,
  Twist,
  Url,
} from '@tapestry/types';
import {
  IThreadV4Type,
  UpdateThreadHookResult,
} from '@tapestry/shared/graphql';
import {
  MeritScoreDiamondIcon,
  PlusIcon,
  TrashIcon,
  CloseIcon,
  ExternalLinkIcon,
  CogIcon,
} from '@tapestry/shared/icons';
import Link from 'next/link';
import { HANK_UNI_URLS, ROUTE_PATHS, VIEW } from '@tapestry/shared/constants';
import { TaskCompletionRadioGroup } from '../task-completion-radio-group';
import MoreButtonEllipsis from '../more-button-ellipsis/MoreButtonEllipsis';
import DeleteThreadModal from '../delete-thread-modal/DeleteThreadModal';

interface ITaskListItemProps {
  task: Nullable<IThreadV4Type | IMockRecommendedTask>;
  mutateThread: UpdateThreadHookResult[0];
  asRecommendedTasks?: boolean;
  onDeleteComplete?: VoidFunction;
}

const getBackgroundColor = (
  taskCompletion: Nullable<boolean>,
  asRecommendedTask: boolean
) => {
  if (asRecommendedTask) return 'bg-orange-lighter';

  switch (taskCompletion) {
    case true:
      return 'bg-green-lightest';

    case false:
      return 'bg-gray-200';

    default:
      return '';
  }
};

/**
 * A Task List Item
 *
 */
const TaskListItem: VFC<ITaskListItemProps> = ({
  task,
  mutateThread,
  asRecommendedTasks = false,
  onDeleteComplete,
}) => {
  /**
   * Hooks
   */
  const [shouldShowDeleteThreadModal, setShouldShowDeleteThreadModal] =
    useState(false);
  const { query, push } = useRouter();
  const { isPhone } = useAppMediaQuery();
  const [profile] = useProfile();

  /**
   * Functions and constants
   */
  // * Extracting Twists
  // Completed task
  const taskCompletionTwist = getTwistByKey(Twist.CompletedTask, task);
  const hasActedOnTask = taskCompletionTwist?.value !== null;
  const hasDeclinedTask = taskCompletionTwist?.value === false;

  // Due date
  const dueDate = getTwistByKey(Twist.DueDate, task)?.value;
  const dateTime = renderDatetime(dueDate, taskCompletionTwist?.value);

  // Shop, dep, product
  const shopTitle = getTwistByKey(Twist.Shop, task)?.extraData?.title;
  const departmentTitle = getTwistByKey(Twist.DepartmentId, task)?.extraData
    ?.title;
  // const productsContract = getTwistByKey(Twist.Products, task);
  const subLabel = getsubLabel(
    shopTitle,
    departmentTitle,
    null // Product title or list
  );

  // Duration
  const estimatedDuration = getTwistByKey(Twist.EstimateDuration, task)?.value;
  const humanizedDuration = getHumanizedDuration(estimatedDuration);

  // Assignable
  const assignedToData = getTwistByKey(Twist.Assignable, task)?.extraData;

  // Tags
  const tags = getTwistByKey(Twist.Tags, task)?.value;

  const backgroundColor = getBackgroundColor(
    taskCompletionTwist?.value,
    asRecommendedTasks
  );

  const handlePressOnTaskCompletion = (value: boolean | null) => {
    if (!task) return;

    mutateThread({
      variables: {
        uid: task?.id,
        twists: [{ id: getTwistID(Twist.CompletedTask, task), value }],
      },
    });
  };

  const ellipsisMenuItems: IDropDownActionItem[] = [
    {
      label: 'Edit',
      action: () => {
        if (!task?.id) return;
        const route = ROUTE_PATHS.build.thread(task?.id, VIEW.UPDATE);
        push(route);
      },
      icon: <CogIcon />,
      hide: task?.ownerId !== profile?.id,
    },
    {
      label: 'Delete Task',
      action: () => setShouldShowDeleteThreadModal(true),
      icon: <TrashIcon />,
      // hide: task?.ownerId !== profile?.id,
    },
    {
      label: 'About Tasks',
      subLabel: 'Tell me how tasks work',
      asLink: true,
      url: HANK_UNI_URLS.agenda.overview as Url,
      icon: <ExternalLinkIcon />,
    },
  ];

  return (
    <>
      <Transition
        appear
        show
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        as="li"
      >
        <div
          className={`mb-2 box-border w-full rounded-md bg-white p-5 shadow sm:flex sm:flex-row sm:items-center sm:justify-between sm:py-5 sm:px-6 ${backgroundColor}`}
        >
          {/* TOP BLOCK */}
          <div className="flex w-full items-center">
            {/* tablet and computer view */}
            {!isPhone &&
              (asRecommendedTasks ? (
                <MeritScoreDiamondIcon
                  className="h-10 w-auto"
                  importance={task && 'merit' in task ? task.merit : ''}
                />
              ) : (
                taskCompletionTwist?.enabled && (
                  <TaskCompletionRadioGroup
                    completionValue={taskCompletionTwist.value}
                    onTaskStatusChange={handlePressOnTaskCompletion}
                  />
                )
              ))}

            <Link
              href={ROUTE_PATHS.build.thread(task?.id)}
              className="w-full sm:ml-6 sm:flex sm:w-auto sm:grow sm:items-center sm:justify-between"
            >
              <>
                {/* The left side */}
                <div className="text-left">
                  <h5
                    className={`text-base font-bold leading-7 text-gray-900 sm:text-xl ${
                      hasDeclinedTask ? 'line-through' : ''
                    }`}
                  >
                    {task?.title}
                  </h5>
                  <p className="text-gray-text text-sm leading-5">{subLabel}</p>

                  {tags && (
                    <div className="my-4 space-y-1 pr-2 sm:mb-0 sm:mt-2 md:space-x-1">
                      {tags?.metadata?.map((tag) => (
                        <Pill
                          key={tag?.id}
                          label={tag?.name}
                          bgColor="#e7ebed"
                          textColor="text-gray-text"
                          size="small"
                        />
                      ))}
                    </div>
                  )}
                  {/* Mobile view */}
                  <p className="mt-2 leading-6 sm:hidden">{dateTime}</p>
                  {estimatedDuration && !hasActedOnTask && (
                    <p className="text-gray-text text-sm leading-6 sm:hidden">
                      Usually takes {humanizedDuration}
                    </p>
                  )}
                  {/* -- */}
                </div>

                {/* The Right Side */}
                <div className="flex items-center justify-end">
                  <div className="flex-shrink-0 text-right">
                    <p className="hidden sm:inline-block">{dateTime}</p>

                    {estimatedDuration && !hasActedOnTask && (
                      <p className="text-gray-text -mt-1 hidden text-sm sm:block">
                        Usually takes {humanizedDuration}
                      </p>
                    )}
                  </div>
                  {!asRecommendedTasks &&
                    (assignedToData ? (
                      <Avatar
                        src={assignedToData?.photo_url}
                        alt={`${assignedToData?.first_name} ${assignedToData?.last_name}`}
                        className="ml-4 mr-1"
                      />
                    ) : (
                      // Empty placeholder
                      <div className="ml-4 mr-1 h-8 w-8 sm:h-10 sm:w-10" />
                    ))}

                  {asRecommendedTasks && (
                    <button
                      title="Dismiss recommended task"
                      className="ml-4 mr-4 h-6"
                    >
                      <p className="sr-only">Dismiss recommended task</p>
                      <CloseIcon light />
                    </button>
                  )}
                </div>
              </>
            </Link>

            <div>
              {asRecommendedTasks ? (
                <Button
                  spacing="small"
                  overwriteColors="bg-orange border-orange hover:bg-orange-dark focus:bg-orange-dark hover:border-orange-dark focus:border-orange-dark  text-white"
                >
                  <PlusIcon
                    light
                    fillColor="#fff"
                    className={isPhone ? 'mr-2 h-6 w-auto' : ''}
                  />{' '}
                  Add
                </Button>
              ) : (
                <MoreButtonEllipsis items={ellipsisMenuItems} />
              )}
            </div>
          </div>

          {/* MOBILE BOTTOM BLOCK */}
          <div className="mt-4 text-left sm:hidden">
            {asRecommendedTasks ? (
              <MeritScoreDiamondIcon
                importance={task && 'merit' in task ? task.merit : ''}
              />
            ) : (
              taskCompletionTwist?.enabled && (
                <TaskCompletionRadioGroup
                  completionValue={taskCompletionTwist.value}
                  onTaskStatusChange={handlePressOnTaskCompletion}
                />
              )
            )}
          </div>
        </div>
      </Transition>

      {shouldShowDeleteThreadModal && (
        <DeleteThreadModal
          show={shouldShowDeleteThreadModal}
          hide={() => setShouldShowDeleteThreadModal(false)}
          thread={task}
          onDeleteComplete={onDeleteComplete}
        />
      )}
    </>
  );
};

export default TaskListItem;
