import React, { FunctionComponent } from 'react';
import clx from 'classnames';
interface IFrameContentProps {
  maxWidth?: 'xl'; // ! add when you need more
  className?: string;
  reference?: any;
  noVerticalSpacing?: boolean;
  as?: keyof JSX.IntrinsicElements;
  style?: any;
  align?: 'left' | 'center' | 'right';
}

const getMaxSize = (maxWidth: IFrameContentProps['maxWidth']) => {
  let maxSize = '';
  switch (maxWidth) {
    case 'xl':
      maxSize = 'max-w-screen-xl px-4 xl:px-0';
      break;

    default:
      maxSize = 'w-11/12 lg:w-9/12';
      break;
  }
  return maxSize;
};

const getAlignement = (side: IFrameContentProps['align']) => {
  switch (side) {
    case 'left':
      return 'text-left';

    case 'center':
      return 'text-center';

    case 'right':
      return 'text-right';

    default:
      return 'text-center';
  }
};

/**
 * FrameContent
 *
 * Use to give content a given maxwidth define by the define framework
 * note: Add more width as needed
 *
 * @param maxWidth - change the default maxWidth of the frame. `xl`: 1200px
 * @param className - forwarded className for the frame
 * @param align - 'left' | 'center' | 'right'
 * @param reference - if you need to attach a ref to the frame
 * @param noVerticalSpacing - removes vertical margin
 * @param as - for a different html tag
 * @param style - Css style
 */
export const FrameContent: FunctionComponent<
  React.PropsWithChildren<IFrameContentProps>
> = ({
  children,
  maxWidth,
  className,
  reference,
  noVerticalSpacing,
  as = 'div',
  style,
  align,
}) => {
  const Component = as;
  const maxSize = getMaxSize(maxWidth);
  const alignment = getAlignement(align);

  return (
    <Component
      // @ts-ignore
      ref={reference || null}
      className={clx(
        'mx-auto',
        maxSize,
        alignment,
        !noVerticalSpacing && 'py-4',
        className
      )}
      style={style}
    >
      {children}
    </Component>
  );
};

export default FrameContent;
