// *******************************************************
// MoreButtonEllipsis
// -------------------------------------------------------
// Component Description
// The MoreButtonEllipsis is used to feed more actions to a certain component
// you can give it and items[] to render inside the dropdown menu
// if you are not happy with the look of the item you can pass it in own render component
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import isEmpty from 'lodash/isEmpty';
import { DropdownMenu, DropdownMenuItem } from '@tapestry/weave';
import { DotsThreeVerticalIcon } from '@tapestry/shared/icons';
import Skeleton from 'react-loading-skeleton';
import { IDropDownActionItem } from '@tapestry/types';

// *******************************************
// Action / Utils / Functions Imports
// -------------------------------------------
const getButtonSize = (size: IMoreButtonEllipsisProps['ellipsisSize']) => {
  switch (size) {
    case 'small':
      return 'h-7 w-7 p-1';
    case 'normal':
      return 'h-8 w-8 sm:h-10 sm:w-10 p-2';

    default:
      return 'h-8 w-8 sm:h-10 sm:w-10 p-2';
  }
};

// *******************************************
// Local Interface
// -------------------------------------------
export interface IMoreButtonEllipsisProps {
  items: IDropDownActionItem[];
  ellipsisSize?: 'normal' | 'small';
  invertBackground?: boolean;
  isLoading?: boolean;
  whiteDots?: boolean;
  classname?: string;
}

// *******************************************
// Main Component
// -------------------------------------------
/**
 * MoreButtonEllipsis
 *
 * Use when added a vertical ellipsis with a dropdown menu of options
 * for the shape of the menu item {@link IDropDownActionItem}
 *
 * @param items - an array of {@link IDropDownActionItem}
 * @param ellipsisSize - 'normal' | 'small' (default='normal'),
 *
 */
export const MoreButtonEllipsis: React.VFC<IMoreButtonEllipsisProps> = ({
  items,
  ellipsisSize,
  invertBackground = false,
  isLoading = false,
  whiteDots = false,
  classname,
}) => {
  const [showMenu, setShowMenu] = React.useState(false);
  const dropdownRef = React.useRef<HTMLDivElement | null>(null);
  const anchorElementRef = React.useRef<HTMLButtonElement | null>(null);

  const itemsToShow = items.filter((item) => !item.hide);
  const buttonSize = getButtonSize(ellipsisSize);

  const handleDropdownOpen = () => {
    setShowMenu(true);
  };

  const handleDropdownClose = () => {
    setShowMenu(false);
  };

  const handleDropdownItemClick = React.useCallback(
    (handler: any) => () => {
      if (handler) {
        handler();
      }

      setShowMenu(false);
    },
    []
  );

  if (itemsToShow.length === 0) {
    return null;
  }

  return (
    <div
      className={`relative flex items-center justify-center ${classname}`}
      ref={dropdownRef}
    >
      {isLoading ? (
        <Skeleton height={32} width={32} />
      ) : (
        <button
          title="More"
          type="button"
          name="options"
          ref={anchorElementRef}
          disabled={isEmpty(itemsToShow)}
          className={`${buttonSize} hover:bg-gray-hover focus:border-blue-light focus:ring-blue active:bg-gray-active ml-px inline-flex items-center justify-center rounded-full font-medium leading-5 transition-colors duration-300 ease-in-out hover:text-white focus:text-white focus:outline-none ${
            invertBackground
              ? 'bg-gray-pageBackground active:bg-gray-active hover:bg-white focus:bg-white'
              : 'hover:bg-gray-hover focus:bg-gray-hover active:bg-gray-active bg-transparent'
          } ${isEmpty(itemsToShow) ? 'cursor-not-allowed' : ''}`}
          onClick={handleDropdownOpen}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleDropdownOpen();
            }
          }}
        >
          <DotsThreeVerticalIcon fillColor={whiteDots ? '#fff' : '#000'} />
        </button>
      )}

      <DropdownMenu
        show={showMenu}
        position="top-right"
        anchorElementRef={anchorElementRef}
        clickOutsideRef={dropdownRef}
        onClickOutside={handleDropdownClose}
        noTopMargin
        menuClassName="mt-4"
      >
        {itemsToShow.map(
          ({
            label,
            subLabel = '',
            action,
            icon,
            asLink,
            url,
            hasSeparator,
          }) => (
            <div key={label}>
              <DropdownMenuItem
                // key={label}
                label={label}
                subLabel={subLabel}
                onClick={handleDropdownItemClick(action)}
                icon={icon}
                asLink={asLink || false}
                url={url || ''}
              />
              {hasSeparator ? (
                <hr className="border-gray-border border" />
              ) : null}
            </div>
          )
        )}
      </DropdownMenu>
    </div>
  );
};

export default MoreButtonEllipsis;
