// *******************************************************
// DevModeModal
// -------------------------------------------------------
// Component Description
// Modal to activate the developer mode after entering a password
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';
import * as yup from 'yup';

// *******************************************
// Component Imports
// -------------------------------------------
import { OverlayContainer } from '@react-aria/overlays';
import { ModalOverlayAndPanel, TextInput } from '@tapestry/weave';
import { ModalActionButton } from '../modal-action-button/ModalActionButton';

// *******************************************
// Hook Imports
// -------------------------------------------
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// *******************************************
// Interface Imports
// -------------------------------------------
import { TapestryLogoIcon } from '@tapestry/shared/icons';

// *******************************************
// Action / Utils / Functions Imports
// -------------------------------------------
const schema = yup.object().shape({
  password: yup.string().matches(/TheCamelNoseBest/, 'Sorry, wrong password'),
});

// *******************************************
// Local Interface
// -------------------------------------------
export interface IDevModeModalProps {
  hide: () => void;
  activateDevMode: () => void;
}

type FormFields = {
  password: string;
};

// *******************************************
// Main Component
// -------------------------------------------
export const DevModeModal = ({ hide, activateDevMode }: IDevModeModalProps) => {
  /**
   * Hooks
   */
  const {
    register,
    formState: { errors, isValid, isValidating },
    handleSubmit,
  } = useForm<FormFields>({ resolver: yupResolver(schema), mode: 'onBlur' });

  /**
   * Const
   */
  const attemptDevMode = () => {
    if (isValid) {
      activateDevMode();
      hide();
    }
  };

  return (
    <OverlayContainer>
      <ModalOverlayAndPanel
        title="Toggle Developer mode"
        isOpen
        onClose={hide}
        maxWidth="md"
      >
        <div className="flex items-center justify-center">
          <TapestryLogoIcon />
        </div>

        <h1 className="text-gray-text my-6 text-lg">
          Sign into the Developer Portal
        </h1>

        <form onSubmit={handleSubmit(attemptDevMode)}>
          <TextInput
            name="password"
            type="password"
            placeholder="Password"
            register={register}
            errors={errors}
          />

          <ModalActionButton
            isLoading={isValidating}
            primaryButtonText="Submit"
          />
        </form>
      </ModalOverlayAndPanel>
    </OverlayContainer>
  );
};

export default DevModeModal;
