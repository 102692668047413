import React from 'react';
import IIconProp from '../iconInterface';

export const CollectiveLogoIcon = ({
  fillColor = '#000',
  className = 'h-full w-auto',
}: IIconProp) => (
  <svg
    width="131px"
    height="118px"
    viewBox="0 0 131 118"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <title>638eeacfeb1a4d1130d39dcb_collective</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="tapestry-|-Collectives-Materials---2"
        transform="translate(-778.000000, -255.000000)"
        fill={fillColor}
        fillRule="nonzero"
      >
        <g
          id="638eeacfeb1a4d1130d39dcb_collective"
          transform="translate(778.000000, 255.000000)"
        >
          <path
            d="M86.0784169,84.5373134 L46.8642156,84.5373134 C39.6122742,84.5373134 33.6351351,90.5122251 33.6351351,97.712351 L33.6351351,118 L99.1351351,118 L99.1351351,97.712351 C99.143136,90.5122251 93.3223411,84.5373134 86.0784169,84.5373134 Z"
            id="Path"
          ></path>
          <ellipse
            id="Oval"
            cx="66.3851351"
            cy="57.238806"
            rx="18.5878378"
            ry="18.4925373"
          ></ellipse>
          <path
            d="M37.1756757,31.5299476 C37.1756757,27.7292757 33.8576733,24.6567164 29.7463253,24.6567164 L7.51612248,24.6567164 C3.40477457,24.6567164 0,27.7330878 0,31.5299476 L0,42.2686567 L37.1756757,42.2686567 L37.1756757,31.5299476 Z"
            id="Path"
          ></path>
          <ellipse
            id="Oval"
            cx="19.472973"
            cy="10.5671642"
            rx="10.6216216"
            ry="10.5671642"
          ></ellipse>
          <path
            d="M123.633832,24.6567164 L101.281894,24.6567164 C97.1480322,24.6567164 93.8243243,27.7292757 93.8243243,31.5299476 L93.8243243,42.2686567 L131,42.2686567 L131,31.5337598 C131,27.7330878 127.767694,24.6567164 123.633832,24.6567164 Z"
            id="Path"
          ></path>
          <ellipse
            id="Oval"
            cx="113.297297"
            cy="10.5671642"
            rx="10.6216216"
            ry="10.5671642"
          ></ellipse>
          <path
            d="M50.2748533,26.4179104 L84.2656871,26.4179104 C86.6095993,26.4179104 88.5135135,24.4782695 88.5135135,22.0170806 C88.5135135,19.5558916 86.6095993,17.6119403 84.2656871,17.6119403 L50.2748533,17.6119403 C47.9268293,17.6119403 46.027027,19.5558916 46.027027,22.0170806 C46.027027,24.4739591 47.9268293,26.4179104 50.2748533,26.4179104 Z"
            id="Path"
          ></path>
          <path
            d="M36.6454492,77.4925373 C37.4734845,77.4925373 38.3094059,77.2372025 39.0349226,76.6894676 C40.8566002,75.3057168 41.2587888,72.6494096 39.9418183,70.7508712 L25.0766132,49.3150897 C23.7517568,47.4041958 21.2085055,46.9841287 19.3868278,48.363761 C17.5612072,49.7433934 17.1590186,52.4038189 18.4799321,54.3064762 L33.3411941,75.7463755 C34.1416282,76.8912645 35.3876242,77.4925373 36.6454492,77.4925373 Z"
            id="Path"
          ></path>
          <path
            d="M109.624099,51.7637996 C107.706994,50.4641223 105.158843,51.0594583 103.928701,53.071862 L90.9403203,74.3488383 C89.70219,76.3570494 90.2693338,79.040254 92.186438,80.3399318 C92.8734001,80.7927221 93.6402419,81.0149254 94.4150711,81.0149254 C95.7730204,81.0149254 97.1070053,80.318969 97.8898227,79.0192919 L110.874211,57.7507005 C112.104352,55.7424894 111.545197,53.0592845 109.624099,51.7637996 Z"
            id="Path"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
