import { useRouter } from 'next/router';

/**
 * URI encodes the browser location `pathname` and `search` property into a URI string
 *
 * @example
 * `&returnTo=%2Fcollections%3Fpagination%3D1`
 */
export const useURIReturnPath = (): string => {
  const { asPath } = useRouter();
  return `&returnTo=${encodeURIComponent(asPath)}`;
};
