import React, { useRef } from 'react';
import { useButton } from '@react-aria/button';
import { AriaButtonProps } from '@react-types/button';
import { PlusIcon } from '@tapestry/shared/icons';

interface IAddButtonWidgetProps extends AriaButtonProps<'button'> {
  title: string;
}

export const AddButtonWidget = (props: IAddButtonWidgetProps) => {
  const { title } = props;
  const buttonRef = useRef(null);
  const { buttonProps } = useButton(
    {
      ...props,
      elementType: 'button',
    },
    buttonRef
  );

  return (
    <button
      {...buttonProps}
      ref={buttonRef}
      title={title}
      className="grow-on-hover h-15 w-15 ring-orange-hank z-50 flex items-center justify-center rounded-full border border-gray-300 bg-white p-2 shadow-lg transition-all duration-500 ease-in-out hover:bg-gray-300 focus:outline-none focus:ring-2"
    >
      <PlusIcon light />
    </button>
  );
};

export default AddButtonWidget;
