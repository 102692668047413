import React, { ElementType, FC } from 'react';
import clx from 'classnames';
import {
  TailwindBackgroundColorString,
  TailwindBorderColorString,
} from '@tapestry/types';

// TODO: fix type to be more restricted on what `tag` can be pass in
interface IListItemProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  tag?: ElementType;
  padding?: 'md' | 'lg';
  collapse?: boolean;
  align?: 'start' | 'center' | 'end';
  justify?: 'start' | 'center' | 'end' | 'between';
  bgColor?: TailwindBackgroundColorString;
  borderColor?: TailwindBorderColorString;
  borderRadius?: string;
  className?: string;
}

const getPadding = (size: IListItemProps['padding']) => {
  switch (size) {
    case 'md':
      return 'p-4 sm:py-4 sm:px-6';

    case 'lg':
      return 'px-6 py-7';

    default:
      return 'p-2 sm:p-5';
  }
};

const getAlignment = (alignment: IListItemProps['align']) => {
  switch (alignment) {
    case 'start':
      return 'items-start';

    default:
      return 'items-center';
  }
};

const getJustify = (justify: IListItemProps['justify']) => {
  switch (justify) {
    case 'start':
      return 'justify-start';

    case 'center':
      return 'justify-center';

    case 'end':
      return 'justify-end';

    case 'between':
      return 'justify-between';

    default:
      return 'justify-start';
  }
};

export const ListItem: FC<React.PropsWithChildren<IListItemProps>> = ({
  tag: Tag = 'li',
  children,
  padding,
  collapse, // TODO get responsive props
  align,
  justify,
  bgColor = 'bg-white',
  borderColor,
  borderRadius,
  className,
  ...props
}) => {
  const _padding = getPadding(padding);
  const _align = getAlignment(align);
  const _justify = getJustify(justify);

  const layout = collapse ? '' : `flex flex-row ${_justify} ${_align}`;

  return (
    <Tag
      className={clx(
        layout,
        _padding,
        bgColor,
        borderColor,
        'box-border list-none rounded-md border',
        borderRadius,
        className
      )}
      {...props}
    >
      {children}
    </Tag>
  );
};
