import { IsoString } from '@tapestry/types';
import { ICalProps, ISOStringsDateRange } from './Calendar';
import { dateTime } from '@tapestry/shared/utils';

export const parseDateStringToJSDate = (dates: ICalProps['value']) => {
  if (Array.isArray(dates)) {
    const jsDates = dates.map((date) => (date ? new Date(date) : null));
    return jsDates;
  }
  return dates ? new Date(dates) : null;
};

function getStandardTime(where: 'to-start' | 'to-end') {
  return where === 'to-start' ? '00:00:00.000' : '23:59:59.999';
}

const standardiseDate = (
  date: Date,
  where: 'to-start' | 'to-end' = 'to-start'
): IsoString => {
  const parsedDate = dateTime.parse(date);
  const year = parsedDate.format('YYYY');
  const month = parsedDate.format('MM');
  const day = parsedDate.format('DD');

  const standardTime = getStandardTime(where);

  const standardisedString = `${year}-${month}-${day}T${standardTime}Z`;

  return standardisedString as IsoString;
};

export const parseJSDateToISOString = (
  date: Date | [Date, Date]
): IsoString | ISOStringsDateRange => {
  if (Array.isArray(date)) {
    return [
      standardiseDate(date[0], 'to-start'),
      standardiseDate(date[1], 'to-end'),
    ];
  }

  return standardiseDate(date);
};
