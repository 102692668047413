import * as React from 'react';
import { HankHLogoIcon } from '@tapestry/shared/icons';
import clx from 'classnames';

export enum ConversationTypes {
  Conversation = 'conversation',
  System = 'system',
}

interface IMessageContainerProps {
  user: {
    name: string;
    profileImage?: string;
  };
  timestamp: string;
  type?: ConversationTypes;
  isCurrentUserMessage?: boolean;
}

const MessageContainer: React.FC<
  React.PropsWithChildren<IMessageContainerProps>
> = ({
  children,
  user,
  timestamp,
  type = 'System',
  isCurrentUserMessage: isUserMessage = false,
}) => {
  return (
    <div
      className={clx(
        'flex w-full flex-row gap-2',
        isUserMessage ? 'flex-row-reverse' : 'flext-start'
      )}
    >
      {!isUserMessage ? (
        <div className="mt-8 h-10 w-10 flex-shrink-0 overflow-hidden rounded-full">
          {type === ConversationTypes.System ? (
            <div className="bg-button-primary h-full w-full p-2 ">
              <HankHLogoIcon fillColor="white " />
            </div>
          ) : (
            <img
              alt="profile"
              className="h-full w-full"
              src={user.profileImage}
            />
          )}
        </div>
      ) : null}
      <div
        className={clx('flex flex-col gap-2 overflow-hidden', {
          'items-end': isUserMessage,
        })}
      >
        <div className="">
          <span className="text-gray-text text-sm">
            <span
              className={clx({
                'text-orange': type === ConversationTypes.System,
                'text-blue':
                  type === ConversationTypes.Conversation && !isUserMessage,
              })}
            >
              {user.name}{' '}
            </span>
            • {timestamp} •{' '}
            {type === ConversationTypes.System
              ? 'Brighton • Insight'
              : 'Conversation'}
          </span>
        </div>
        <div className="w-full">{children}</div>
        {!isUserMessage ? (
          <div className="flex w-full gap-2 text-sm font-bold ">
            <button type="button" className="hover:text-blue">
              Like
            </button>
            <button type="button" className="hover:text-blue">
              Reply
            </button>
            <button type="button" className="hover:text-blue">
              Add Task
            </button>
          </div>
        ) : null}
      </div>
      <div className="w-4 flex-shrink-0" />
    </div>
  );
};

export { MessageContainer };
