import type { IButton } from './Button.types';

export const getSpacing = (space: string, hasIcon: boolean) => {
  switch (space) {
    case 'none':
      return '';

    case 'small':
      return hasIcon ? 'py-2 px-2' : 'py-2 px-6';

    case 'medium':
      return hasIcon ? 'py-4 px-4' : 'py-4 px-8';

    case 'lg':
      return 'py-4 px-8';

    default:
      return 'py-4 px-4';
  }
};

export const getColors = (status: string) => {
  switch (status) {
    case 'primary':
      return 'bg-blue border-blue hover:bg-blue-dark hover:border-blue-dark focus:border-blue-light text-white';

    case 'secondary':
      return 'bg-white hover:bg-blue text-blue border-blue hover:text-white';

    case 'danger':
      return 'bg-danger border-danger hover:border-danger-hover hover:bg-danger-hover focus:border-red-light text-white';

    case 'basic':
      return 'bg-gray-200 border-gray-200 hover:border-gray-400 hover:bg-gray-400 focus:border-gray-400 focus:bg-gray-400';

    case 'basic-inverted':
      return 'bg-white border-white text-gray-text hover:border-gray-400 hover:bg-gray-400 focus:border-gray-400 focus:bg-gray-400';

    case 'text':
      return 'bg-transparent border-transparent text-black hover:text-blue-dark focus:text-blue-light';

    default:
      return 'bg-gray-200 border-gray-200 hover:border-gray-400 hover:bg-gray-400 focus:border-gray-400 focus:bg-gray-400';
  }
};

export const getFont = (spacing: IButton['spacing'], bold?: boolean) => {
  return spacing === 'lg'
    ? 'font-bold text-xl'
    : `${bold ? 'font-bold tracking-wide' : 'font-medium'} text-sm`;
};
