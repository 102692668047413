// *******************************************************
// WidgetBar
// -------------------------------------------------------
// Component Description
// a Floating area to add widget to any screen
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import React, { FC } from 'react';
import { Circle, Stack } from '@tapestry/weave';

// *******************************************
// Local Interface
// -------------------------------------------

// *******************************************
// Main Component
// -------------------------------------------
export const WidgetBar: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <div className="fixed bottom-0 right-0 z-10 m-4">
      <Stack spacing="small" align="center">
        {children}
      </Stack>
    </div>
  );
};

interface IWidgetBarButtonProps {
  title: string;
  onPress: () => void;
  large?: boolean;
  status?: 'primary';
}

export const WidgetBarButton: FC<
  React.PropsWithChildren<IWidgetBarButtonProps>
> = ({ title, onPress, large, status, children }) => {
  const size = large ? 'h-15 w-15' : 'h-10 w-10';
  const colors =
    status === 'primary'
      ? 'bg-blue border-blue'
      : 'border-white bg-gray-100 group-focus:bg-gray-hover';

  return (
    <button
      title={title}
      className="group focus:outline-none"
      onClick={onPress}
      onKeyPress={({ key }) => {
        if (key === 'Enter') {
          onPress();
        }
      }}
    >
      <Circle
        className={`${colors} grow-on-hover p-3 shadow-lg transition-all duration-500 ease-in-out`}
        size={size}
      >
        <p className="sr-only">Search something</p>
        {children}
      </Circle>
    </button>
  );
};

export default WidgetBar;
