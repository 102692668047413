import { dateTime } from '@tapestry/shared/utils';
import { CalDateRange } from '../../Calendar/Calendar';

export const formatDatesToDisplay = (dates: CalDateRange) => {
  const [startDate, endDate] = dates;
  const start = startDate
    ? dateTime.parseInUTC(startDate).format('DD/MM/YYYY')
    : '';
  const end = endDate ? dateTime.parseInUTC(endDate).format('DD/MM/YYYY') : '';
  return `${start} - ${end}`;
};
