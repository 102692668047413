import { Transition } from '@headlessui/react';
import * as React from 'react';
import { Conversations } from '../Conversations';
import { MessageThread } from '../MessageThread';

interface IInboxProps {
  onClose: () => void;
  messageThreadId?: string;
  messageThreadTitle?: string | undefined;
  messageThreadSubtitle?: string | undefined;
}

const Inbox: React.VFC<IInboxProps> = ({
  onClose,
  messageThreadId,
  messageThreadTitle,
  messageThreadSubtitle,
}) => {
  const [showMessageThread, setShowMessageThread] = React.useState<boolean>(
    !!messageThreadId
  );
  const [_messageThreadTitle, setMessageThreadtitle] = React.useState<
    | {
        threadTitle: string | undefined;
        threadSubtitle?: string | undefined;
      }
    | undefined
  >({ threadTitle: messageThreadTitle, threadSubtitle: messageThreadSubtitle });

  const handleMessageThreadClose = () => {
    if (messageThreadId) {
      return onClose();
    }

    setShowMessageThread(false);
  };

  const handleOnMessageThreadClick = ({
    threadTitle,
    threadSubtitle,
  }: {
    threadTitle: string;
    threadSubtitle: string | undefined;
  }) => {
    setMessageThreadtitle({ threadTitle, threadSubtitle });
    setShowMessageThread(true);
  };

  // in case of a threadId is presented, show the message thread
  // this will skip the conversations list from rendering
  if (messageThreadId) {
    return (
      <MessageThread
        onClose={handleMessageThreadClose}
        title={_messageThreadTitle?.threadTitle}
        subTitle={_messageThreadTitle?.threadSubtitle}
      />
    );
  }

  return (
    <div className="relative h-full w-full">
      <Conversations
        onClose={onClose}
        onMessageThreadClick={handleOnMessageThreadClick}
      />
      <Transition
        show={showMessageThread}
        enter="transition ease-in-out duration-500"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-500"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
        className="absolute top-0 z-10 h-full w-full bg-white"
      >
        <MessageThread
          onClose={handleMessageThreadClose}
          title={_messageThreadTitle?.threadTitle}
          subTitle={_messageThreadTitle?.threadSubtitle}
        />
      </Transition>
    </div>
  );
};

export { Inbox };
