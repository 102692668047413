import { IActiveThreadState, THREAD_TYPE } from '@tapestry/types';
import { Maybe } from 'graphql/jsutils/Maybe';
import pick from 'lodash/pick';

/**
 * ! Amend this if you need more keys
 * ! In reverse order of priority
 */
const queryStringTothreadType: { [index: string]: THREAD_TYPE } = {
  // ! LOWEST PRIORITY
  shopId: THREAD_TYPE.SHOP,
  staffId: THREAD_TYPE.STAFF,
  postcode: THREAD_TYPE.POSTCODE,
  dem: THREAD_TYPE.DEMOGRAPHIC,
  departmentId: THREAD_TYPE.DEPARTMENT,
  supplierId: THREAD_TYPE.SUPPLIER,
  spaceId: THREAD_TYPE.SPACE,
  categoryId: THREAD_TYPE.CATEGORY,
  tagId: THREAD_TYPE.COLLECTION,
  productId: THREAD_TYPE.PRODUCT,
  // ! HIGHEST PRIORITY
};

const queryStringParamKeys = Object.keys(queryStringTothreadType);

/**
 * Calculates the active THREAD_TYPE given a url query string object
 * @see {@link queryStringTothreadType} if you need to add more keys
 *
 * @see {@link THREAD_TYPE} for possible THREAD_TYPE
 */
export const deriveCurrentThreadTypeFromQueryString = (queryStringObject: {
  [index: string]: Maybe<string | number>;
}): IActiveThreadState => {
  // Pick the relevant keys out of the url
  const threadRelatedQueryParams = pick(
    queryStringObject,
    queryStringParamKeys
  );

  const matchThreadTypeToQPId = (thread, key) => {
    const threadId = threadRelatedQueryParams[key];

    if (!threadId) {
      return thread;
    }

    const threadType = queryStringTothreadType[key];
    const transformedThreadId =
      typeof threadId === 'string' && threadId === 'null'
        ? ''
        : String(threadId);

    return {
      type: threadType,
      id: transformedThreadId,
    };
  };

  const activeThread = queryStringParamKeys.reduce(matchThreadTypeToQPId, {
    type: THREAD_TYPE.SHOP,
    id: queryStringObject.shopId ? String(queryStringObject?.shopId) : '',
  });

  return activeThread;
};

export default deriveCurrentThreadTypeFromQueryString;
