import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  VFC,
} from 'react';
import {
  GetPropsCommonOptions,
  useCombobox,
  UseMultipleSelectionGetDropdownProps,
} from 'downshift';

import clx from 'classnames';
import Stack from '../stack/Stack';
import { defaultLabelAccessor, resolveLabelProp } from './combobox-utils';
import { ComboboxInput } from './ComboboxInput';
import { useDebouncedCallback } from 'use-debounce';
import isEmpty from 'lodash/isEmpty';
import { SelectDefaultDropDownListItem } from '../SelectDefaultListItem';
import {
  ComboBoxOptionItem,
  ComboboxOptionsConfig,
  IComboboxCommon,
} from '@tapestry/types';

function getFilteredItems(inputValue) {
  return function itemsFilter(item) {
    return !inputValue || item.label.toLowerCase().includes(inputValue);
  };
}

interface IInternalCombobox extends IComboboxCommon {
  shouldResetInputValue?: boolean;
  // for Combobox
  setSelectedItem?: Dispatch<SetStateAction<ComboBoxOptionItem | null>>;
  // for MultiCombobox
  multiSelectionSelectedItems?: ComboBoxOptionItem[];
  handleSelectedItemSelection?: (item: ComboBoxOptionItem) => void;
  getDropdownProps?: (
    options?: UseMultipleSelectionGetDropdownProps,
    extraOptions?: GetPropsCommonOptions
  ) => any;
}

export const InternalCombobox: VFC<IInternalCombobox> = ({
  options,
  label,
  input: inputRelatedProps,
  getDropdownProps,
  setSelectedItem,
  shouldResetInputValue = false,
  handleSelectedItemSelection,
  multiSelectionSelectedItems = null,
}) => {
  /**
   * props defaults
   */
  const componentLabel = resolveLabelProp(label);
  const items = Array.isArray(options) ? options : options.options;
  const optionsConfig: ComboboxOptionsConfig = Array.isArray(options)
    ? { options }
    : options;
  const labelAccessor = optionsConfig.itemAccessor
    ? optionsConfig.itemAccessor
    : defaultLabelAccessor;
  const isMultiSelect = !!getDropdownProps;

  /**
   * Hooks
   */
  const { callback: debouncedQuery } = useDebouncedCallback(
    optionsConfig?.isQueryable?.query
      ? optionsConfig?.isQueryable?.query
      : // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {},
    300
  );

  const [dropdownOptions, setDropdownOptions] = useState(items);
  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    setInputValue,
    inputValue,
    selectedItem: downshiftSelectedItem,
  } = useCombobox({
    items: dropdownOptions,
    itemToString: (item) => (item ? labelAccessor(item) : ''),
    onInputValueChange: ({ inputValue }) => {
      if (!inputValue) {
        // Resets dropdown
        setDropdownOptions(items);
        // If the items are queryable, we need to trigger the initial cache entry
        debouncedQuery('');
        return;
      }

      if (optionsConfig.isQueryable) {
        debouncedQuery(inputValue);
      }

      setDropdownOptions(items?.filter(getFilteredItems(inputValue)));
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (!selectedItem) return;

      // * blanks out input if render component provided
      if (shouldResetInputValue) {
        setInputValue('');
      }

      if (isMultiSelect && handleSelectedItemSelection) {
        handleSelectedItemSelection(selectedItem);
      } else {
        setSelectedItem && setSelectedItem(selectedItem || null);
      }
    },
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (isMultiSelect) {
            return {
              ...changes,
              ...(changes.selectedItem && {
                isOpen: true,
                highlightedIndex: 0,
              }),
            };
          }

          return changes;
        default:
          return changes;
      }
    },
    defaultHighlightedIndex: 0,
    selectedItem: isMultiSelect ? null : undefined,
  });

  /**
   * Functions
   */
  const getCorrectInputProps = isMultiSelect
    ? () => getInputProps(getDropdownProps({ preventKeyAction: isOpen }))
    : getInputProps;

  /**
   * useEffects
   */
  useEffect(
    function setDropdownItemsOnFirstFetch() {
      if (isEmpty(dropdownOptions) && items && !inputValue) {
        setDropdownOptions(items);
      }
    },
    [items, dropdownOptions, inputValue]
  );

  useEffect(
    function updateDropdownResultIfQueryable() {
      if (!optionsConfig?.isQueryable?.query) return;
      setDropdownOptions(items);
    },
    [optionsConfig?.isQueryable?.query, items]
  );

  return (
    <div>
      <Stack spacing={'xsmall'}>
        <label
          {...getLabelProps()}
          className={clx(
            'block cursor-pointer text-left text-base font-bold capitalize tracking-wide text-black'
          )}
        >
          {componentLabel}
        </label>
        {typeof label !== 'string' && 'sublabel' in label && (
          <p className="text-gray-text text-left text-sm capitalize">
            {label.sublabel}
          </p>
        )}
      </Stack>

      <div className="mt-4">
        <ComboboxInput
          getToggleButtonProps={getToggleButtonProps}
          getInputProps={getCorrectInputProps}
          isFetching={
            optionsConfig?.isFetchable?.loading ||
            optionsConfig?.isQueryable?.loading
          }
          {...inputRelatedProps}
        />
      </div>

      {/* dropdownlist */}
      <ul
        {...getMenuProps()}
        className={clx(
          isOpen ? 'block' : 'hidden',
          'max-h-72 overflow-y-scroll'
        )}
      >
        {isOpen && (
          <Stack spacing={'xxsmall'} hasTopMargin>
            {dropdownOptions.map((item, index) => (
              <li
                key={item.id}
                {...getItemProps({
                  item,
                  index,
                })}
                className="cursor-pointer"
              >
                <SelectDefaultDropDownListItem
                  item={item}
                  isHighlighted={highlightedIndex === index}
                  isChecked={
                    downshiftSelectedItem === item ||
                    (multiSelectionSelectedItems &&
                      multiSelectionSelectedItems.includes(item)) ||
                    false
                  }
                  labelAccessor={labelAccessor}
                />
              </li>
            ))}
          </Stack>
        )}
      </ul>
    </div>
  );
};
