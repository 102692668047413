import { IThreadV4Type } from '@tapestry/shared/graphql';

type IRecommendatedTaskType = IThreadV4Type & { merit: string };

export const MOCK_RECOMMENDED_TASKS: { [k: string]: IRecommendatedTaskType[] } =
  {
    menu: [
      {
        id: 'c0a0fa0c-f62c-4f39-b3ee-7c9a19641a8fmock',
        threadType: 'task',
        title: 'Review zero stock items',
        merit: 'critical',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'Review zero stock items',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
      {
        id: 'c0a0fa0c-f62casda-4f39-b3ee-7c9a19641a8fmock',
        threadType: 'task',
        title: 'Check on-promotion percent',
        merit: 'high',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'my first task',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
      {
        id: 'c0a0fa0c-f62c-4f39-b3ee-7c9a19asda641a8fmock',
        threadType: 'task',
        title: 'Review seasonality index for different categories',
        merit: 'medium',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'my first task',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
    ],
    category: [
      {
        id: 'c0a0fa0c-f62c-4f39-b3ee-7c9a19641a8fmock',
        threadType: 'task',
        title: 'Promote more profitable products in the category',
        merit: 'critical',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'my first task',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
      {
        id: 'c0a0fa0c-f62c-4f39-b3ee-7c9a19641a8fmock',
        threadType: 'task',
        title: "Put more '2 for' promotions on in the category",
        merit: 'critical',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'my first task',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
      {
        id: 'c0a0fa0c-f62c-4f39-b3ee-7c9a19641a8fmock',
        threadType: 'task',
        title: 'Review and increase products with low price elasticity',
        merit: 'high',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'my first task',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
      {
        id: 'c0a0fa0c-f62c-4f39-b3ee-7c9a19641a8fmock',
        threadType: 'task',
        title: 'Premiumise top 30% of Category through Price',
        merit: 'medium',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'my first task',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
      {
        id: 'c0a0fa0c-f62c-4f39-b3ee-7casda9a19641a8fmock',
        threadType: 'task',
        title: 'Lower Price of small pack formats to drive pack mix',
        merit: 'medium',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'my first task',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
      {
        id: 'c0a0fa0c-f62c-4f39-b3ee-7c9a19641a8fmock',
        threadType: 'task',
        title: 'Reduce range on this category',
        merit: 'low',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'my first task',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
    ],
    space: [
      {
        id: 'c0a0fa0c-f62c-4f39-b3ee-7c9a19641a8f-08aasdasmock',
        threadType: 'task',
        title: 'Change product mix in __word__',
        merit: 'critical',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'my first task',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
      {
        id: 'c0a0fa0c-f62c-4f39-b3ee-7c9a19641a8fmoc09721031k',
        threadType: 'task',
        title: 'Add product on promotion to __word__',
        merit: 'critical',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'my first task',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
      {
        id: 'ckashda0a0fa0c-f62c-4f39-b3ee-7c9a19641a8fmock',
        threadType: 'task',
        title:
          "Reduce the amount of profitable product's on promotion in __word__",
        merit: 'high',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'my first task',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
      {
        id: 'kcxhlzghc0a0fa0c-f62c-4f39-b3ee-7c9a19641a8fmock',
        threadType: 'task',
        title: "Put more '2 for' mechanics on in __word__",
        merit: 'medium',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'my first task',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
      {
        id: 'c0a0fa0c-f62c-4f39-b3ee-7casda9ap9172dlh19641a8fmock',
        threadType: 'task',
        title: 'Add promotional ticketing to __word__',
        merit: 'medium',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'my first task',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
      {
        id: 'c0a0fa0c-f62c-4f39-b3ee-7c9a19641a8fmock',
        threadType: 'task',
        title: 'Add promotional ticketing to __word__',
        merit: 'low',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'my first task',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
    ],
    collection: [
      {
        id: 'c0a0afjapoejqw-4f39-b3ee-7c9a19641a8f-08aasdasmock',
        threadType: 'task',
        title: 'Increase supply of products in __word__',
        merit: 'critical',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'my first task',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
      {
        id: 'lkhihohc0a0fa0c-f62c-4f39-b3ee-7c9a19641a8fmoc09721031k',
        threadType: 'task',
        title: 'Review zero stock items in __word__',
        merit: 'critical',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'my first task',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
      {
        id: 'ckashda0a0fa0c-f62c-4f39ihafsfo9-b3ee-7c9a19641a8fmock',
        threadType: 'task',
        title: 'Change relays fro spaces with products in __word__',
        merit: 'high',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'my first task',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
      {
        id: 'kcxhlzgh09124c0a0fa0c-f62c-4f39-b3ee-7c9a19641a8fmock',
        threadType: 'task',
        title: 'Place __word__ on promotion',
        merit: 'medium',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'my first task',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
      {
        id: 'c0a0fa0c-f62c-4f39-b3ee-7casda9ap9172dlh19641a8f83yrofnkvknascn',
        threadType: 'task',
        title: 'Discontinue __word__',
        merit: 'medium',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'my first task',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
    ],
    department: [
      {
        id: 'c0a0fa0c-f62c-4f39-b3e12313e-7c9a19641a8fmock',
        threadType: 'task',
        title: 'Take less profitable products off promotion',
        merit: 'critical',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'my first task',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
      {
        id: 'c0a0123fa0c-f62c-4f39-b3ee-7c9a19641a8fmock',
        threadType: 'task',
        title: 'Advertise Foot Traffic Drivers',
        merit: 'critical',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'my first task',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
      {
        id: '0970c0a0fa0c-f62c-4f39-b3ee-7c9a19641a8fmock',
        threadType: 'task',
        title: 'Send voucher to non-loyal shoppers personas',
        merit: 'high',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'my first task',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
      {
        id: 'c08669a0fa0c-f62c-4f39-b3ee-7c9a19641a8fmock',
        threadType: 'task',
        title: 'Review zero stock items',
        merit: 'medium',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'Review zero stock items',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
      {
        id: 'c0a0fa0c-f62c-4f39-b3ee-7casda9a19641a8fmock09710',
        threadType: 'task',
        title: 'Review Products on promotion',
        merit: 'medium',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'Review Products on promotion',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
      {
        id: 'iuaryab34a3wryanv0ruq3rv0n3281n-2cn1',
        threadType: 'task',
        title: 'Run Cross Category Promotion across __word__',
        merit: 'low',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'Run Cross Category Promotion across __word__',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
    ],
    product: [
      {
        id: 'c0a0fa0c-f62c-4ksjdhglaf39-b3e12313e-7c9a19641a8fmock',
        threadType: 'task',
        title: 'Consider substitution of product',
        merit: 'critical',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'Consider substitution of product',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
      {
        id: 'c0a0123xnvmxnvmxcnvmfa0c-f62c-4f39-b3ee-7c9a19641a8fmock',
        threadType: 'task',
        title: "Add a '2 for' mechanic to this product",
        merit: 'critical',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: "Add a '2 for' mechanic to this product",
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
      {
        id: '097ljkdasjkl0c0a0fa0c-f62c-4f39-b3ee-7c9a19641a8fmock',
        threadType: 'task',
        title: 'Reduce quantity of this product',
        merit: 'high',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'Reduce quantity of this product',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
      {
        id: 'c08669a0fa0c-f62c-4f39-b3ee-7c9a19641a8f98asidihaihdai',
        threadType: 'task',
        title: 'Review placement of this product',
        merit: 'medium',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'Review placement of this product',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
      {
        id: 'c0a0fa0c-f62c-4f39-b3ee-sdh-aspodjapidha7casda9a19641a8fmock09710',
        threadType: 'task',
        title: 'Place product on promotion',
        merit: 'medium',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'Place product on promotion',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
    ],
    all: [
      {
        id: 'c0a0fa0c-f62c-4f39-b3ee-7c9a19641a8fmock',
        threadType: 'task',
        title: 'Code check',
        merit: 'critical',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'Code check',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
      {
        id: 'c289221f-4995-4fc5-bac1-74188d2dc157mock',
        threadType: 'task',
        title: 'Change lightbulbs',
        merit: 'high',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'Change lightbulbs',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
      {
        id: '5d499876-06d1-476c-ae40-f93e068c991dmock',
        threadType: 'task',
        title: 'Test Tasks On Tapestry',
        merit: 'medium',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'Test Tasks On Tapestry',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
      {
        id: '786abc09-12a9-4799-b5c7-b54401d1a476mock',
        threadType: 'task',
        title: 'Test Tasks',
        merit: 'medium',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'Test Tasks',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
      {
        id: '997310da-433e-4080-acaa-abfc0797864emock',
        threadType: 'task',
        title: 'Cook Chickens',
        merit: 'low',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'Cook Chickens',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
      {
        id: 'b52724d6-1fcb-4763-a85b-a38ff8a81febmock',
        threadType: 'task',
        title: 'Review Order Logs',
        merit: 'trivial',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'Review Order Logs',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
      {
        id: '9da11194-bcdd-4204-8877-05dbba8a1ba6mock',
        threadType: 'task',
        title: 'Test Notifications On Production / Repeating Tasks',
        merit: 'critical',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'Test Notifications On Production / Repeating Tasks',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
      {
        id: '5ea05a45-9950-45a7-91c9-02e34e287902mock',
        threadType: 'task',
        title: 'Produce opening procedure',
        merit: 'critical',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'Produce opening procedure',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
      {
        id: '670b1172-1a51-498a-b0ee-99eb1d53f3e4mock',
        threadType: 'task',
        title: 'Store opening procedures',
        merit: 'critical',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'Store opening procedures',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
      {
        id: '93f06517-b69c-4902-b88e-73b8c55c2004mock',
        threadType: 'task',
        title: 'Welcome to HANK Tammy',
        merit: 'critical',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'Welcome to HANK Tammy',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
      {
        id: 'fe7c8165-e260-4a81-9b5a-6a5ee87ab755mock',
        threadType: 'task',
        title: 'Test Repeating Task',
        merit: 'critical',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'Test Repeating Task',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
      {
        id: 'eed69d04-b508-40a9-a239-ef3ad02ccf13mock',
        threadType: 'task',
        title: 'creating a new task',
        merit: 'critical',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'creating a new task',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
      {
        id: 'cf14b99d-2b1c-4e5f-bcf2-5959c1c84e96mock',
        threadType: 'task',
        title: 'Test SubTasks',
        merit: 'critical',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'Test SubTasks',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
      {
        id: 'b9a983ab-fc8a-404c-bd7e-4bfc654e8738mock',
        threadType: 'task',
        title: 'Design error page',
        merit: 'critical',
        twists: [
          {
            config: {},
            enabled: true,
            extraData: null,
            id: 'de5ffc34-2d37-4205-8643-3409a561021d',
            key: 'title',
            permissions: {},
            title: 'Title',
            type: 'thread_header',
            validation: {},
            value: 'Design error page',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '16bf3b09-e095-409e-8307-ca3d7447b241',
            key: 'completed_task',
            permissions: {},
            title: 'Completed Task',
            type: 'hidden',
            validation: {},
            value: true,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '210a5615-bc06-4341-ae1f-474320d97d5b',
            key: 'tags',
            permissions: {},
            title: 'Tags',
            type: 'tag_collection',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '9e3448ca-5b9f-4d06-8644-1de2347d4959',
            key: 'more_information',
            permissions: {},
            title: 'More Information',
            type: 'text',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '36b621b5-d379-46dd-9c27-c744c458398b',
            key: 'due_date',
            permissions: {},
            title: 'Due Date',
            type: 'datetime',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '99be04bc-c81f-4d51-9bbb-3d251b874e20',
            key: 'estimate_duration',
            permissions: {},
            title: 'Estimate Duration Of Task',
            type: 'interval',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '220987df-ec65-4a04-9958-62138ce3c558',
            key: 'sub_tasks',
            permissions: {},
            title: 'Sub Tasks',
            type: 'task_list',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '4bdb7e95-e180-49fa-876b-36427fa29591',
            key: 'attachments',
            permissions: {},
            title: 'Attachments',
            type: 'files',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '075397e2-87f9-4c25-8b2b-da730b377ca1',
            key: 'attach_link',
            permissions: {},
            title: 'Attach Link',
            type: 'link',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: {
              id: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
              title: 'Port Melbourne Grocer',
              thread_type: 'shop',
            },
            id: '0549ead5-3760-4a31-8dc2-e42e5fe2dd0b',
            key: 'shop_id',
            permissions: {},
            title: 'Shop',
            type: 'thread',
            validation: {},
            value: '42958bb0-6f67-487d-8bae-285bbe5f4cf3',
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '0cad936e-e13b-414c-bd43-31b2413dd497',
            key: 'department_id',
            permissions: {},
            title: 'Department',
            type: 'thread',
            validation: {},
            value: null,
          },
          {
            config: {},
            enabled: true,
            extraData: null,
            id: '49761690-e8c2-4c50-814a-d5c75d976b30',
            key: 'assignable_id',
            permissions: {},
            title: 'Assignable',
            type: 'user',
            validation: {},
            value: null,
          },
        ],
      },
    ],
  };
