import React, { FC } from 'react';
import { Twist } from '@tapestry/types';
import { getTwistByKey } from '@tapestry/shared/utils';
import { IThreadV4Type } from '@tapestry/shared/graphql';
import { Maybe } from 'graphql/jsutils/Maybe';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Image from 'next/image';

interface IStoreSelectorDropdownShopListItemProps {
  shop: IThreadV4Type;
  closeDropdownMenu: () => void;
}

interface IDropdownShopsListProps {
  shopsList: Maybe<IThreadV4Type[]>;
  closeDropdownMenu: () => void;
}

const StoreSelectorDropdownShopListItem: FC<
  IStoreSelectorDropdownShopListItemProps
> = ({ shop, closeDropdownMenu }) => {
  const shopLogo = getTwistByKey(Twist.Logo, shop)?.value;
  const {
    pathname,
    query: { groupId, ...queryWithoutGroupId },
  } = useRouter();

  return (
    <Link
      key={shop?.id}
      data-cy={`shop-selector-shop-list-item-${shop?.id}`}
      href={{
        pathname,
        query: {
          ...queryWithoutGroupId,
          shopId: shop?.id,
        },
      }}
      onClick={closeDropdownMenu}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          closeDropdownMenu();
        }
      }}
      className="hover:bg-primary-lightest focus:bg-primary-lightest flex cursor-pointer flex-row items-center rounded-md p-2 text-base font-normal transition-colors duration-150 ease-in-out focus:outline-none sm:border-b-0"
    >
      <span className="min-w-6 mr-4 flex h-6 w-6 items-center justify-center overflow-hidden rounded-full bg-gray-200">
        {shopLogo ? (
          <Image
            src={shopLogo}
            alt="shop's logo"
            className="bg-gray-400"
            width={24}
            height={24}
          />
        ) : null}
      </span>

      <span title={shop?.title || ''} className="truncate">
        {shop?.title || ''}
      </span>
    </Link>
  );
};

export const DropdownShopsList: FC<IDropdownShopsListProps> = ({
  shopsList,
  closeDropdownMenu,
}) => {
  return shopsList ? (
    <ul>
      {shopsList?.map((shop, idx) => (
        <StoreSelectorDropdownShopListItem
          key={shop?.id || idx}
          shop={shop}
          closeDropdownMenu={closeDropdownMenu}
        />
      ))}
    </ul>
  ) : null;
};
