import React, { useEffect } from 'react';
import {
  useQueryParams,
  StringParam,
  getTwistByKey,
} from '@tapestry/shared/utils';
import { useRouter } from 'next/router';
import { useProfile } from '../use-profile/useProfile';
import { Maybe } from 'graphql/jsutils/Maybe';
import { IUserProfile } from '@tapestry/shared/graphql';
import { Twist } from '@tapestry/types';
import find from 'lodash/find';
import { useActiveShop } from '../use-active-shop/use-active-shop';
import { APP_DEFAULT_TIMEZONE } from '@tapestry/shared/constants';
import { useActiveGroup } from '../use-active-group/use-active-group';

const shopIdIsDifferentBetweenURLAndCache = (
  shopIdQueryParam: Maybe<string>,
  profile: Maybe<IUserProfile>
) => {
  return (
    shopIdQueryParam &&
    (shopIdQueryParam !== profile?.active_shop_scope?.id ||
      profile?.active_shop_scope?.type !== 'shop')
  );
};

const groupdIDIsDifferentBetweenURLAndCache = (
  groupId: Maybe<string>,
  profile: Maybe<IUserProfile>
) => {
  return (
    groupId &&
    (groupId !== profile?.active_shop_scope?.id ||
      profile?.active_shop_scope?.type !== 'group')
  );
};

const isPublicRoute = (routes, currentPath: string) => {
  const route = find(routes, ['path', currentPath]);
  return !route?.isPrivate;
};

/**
 * Use it at the apps root level => provide routes
 * Watch the url and Apollo cache to make sure:
 * - there's always a `shopId` param in the URL to make it shareable
 * - They are both in sync with each other
 *
 * @param routes - The app route map (generally in the `routes.ts`)
 *
 * @example
 * const App = () => {
 *  useWatchForShopId(routes)
 *
 *  return (
 *    <div>{...}</div>
 *  )
 * }
 */
export const useWatchForShopId = (): void => {
  const [profile, updateProfile] = useProfile();
  const [
    { shopId: shopIdQueryParam, shop_id: mobileShopId, groupId },
    setUrlQueryState,
  ] = useQueryParams({
    shopId: StringParam,
    shop_id: StringParam,
    groupId: StringParam,
  });
  const { pathname } = useRouter();
  // TODO might not work
  const activeShop = useActiveShop(shopIdQueryParam);
  const activeGroup = useActiveGroup();

  //* 1) if no local or mobile shopId in url, update (replace) with cached id
  useEffect(
    function updateURLFromCache() {
      if (shopIdQueryParam || mobileShopId || groupId) return;
      // ! Commented out as the current implementation in NextJS must be done on a per page basis
      // if (isPublicRoute(routes, pathname)) return;

      if (profile?.active_shop_scope) {
        const { type, id } = profile.active_shop_scope;

        const qp = {
          shopId: type === 'shop' ? id : undefined,
          groupId: type === 'group' ? id : undefined,
        };

        setUrlQueryState(qp, 'replaceIn');
      }
    },
    [
      profile,
      pathname,
      shopIdQueryParam,
      mobileShopId,
      setUrlQueryState,
      groupId,
    ]
  );

  //* 2.a) if coming from mobile => integration patch from mobile url where `shopId` has been coded as `shop_id`...
  useEffect(() => {
    if (mobileShopId) {
      setUrlQueryState({ shopId: mobileShopId, shop_id: undefined });
    }
  }, [mobileShopId, setUrlQueryState]);

  //* 2.b) if coming from web => has a shopId/groupId query param, update backend + cache
  //*  + Keep them in sync whenever local state 'shopId/groupId' changes
  useEffect(
    function updateBackEndAndCache() {
      if (!profile) return;

      if (groupdIDIsDifferentBetweenURLAndCache(groupId, profile)) {
        updateProfile({
          variables: {
            active_shop_scope: {
              type: 'group',
              id: groupId,
              timezone: APP_DEFAULT_TIMEZONE, //TODO will need to have a way to handle default tz for a group
              logo: activeGroup?.logo || null,
            },
          },
        });
      } else if (
        shopIdIsDifferentBetweenURLAndCache(shopIdQueryParam, profile)
      ) {
        const timezone =
          getTwistByKey(Twist.Timezone, activeShop)?.value ||
          APP_DEFAULT_TIMEZONE;
        const logo = getTwistByKey(Twist.Logo, activeShop)?.value || null;

        updateProfile({
          variables: {
            active_shop_scope: {
              type: 'shop',
              id: shopIdQueryParam,
              timezone,
              logo,
            },
          },
        });
      }
    },
    [shopIdQueryParam, updateProfile, groupId, profile]
  );
};

export default useWatchForShopId;
