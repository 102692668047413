import React from 'react';
import {
  FormInputBase,
  IFormInputBaseProps,
} from '../FormInputBase/FormInputBase';
import clx from 'classnames';
import { getBorderColor, getBorderRadius, getTextAlignment } from './utils';

export interface ITextInput extends IFormInputBaseProps {
  name: string;
  type?: string;
  placeholder?: string;
  value?: string | number | undefined;
  textAlign?: 'left' | 'center' | 'right';
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
  clearBorder?: boolean;
  borderRadius?: 'sm' | 'md' | 'lg';
  moreVerticalPadding?: boolean;
  disabled?: boolean;
}

/**
 * Form Input component
 *
 * Alternative: for the an input box
 *
 * @param name - input name.
 * @param errors - error string
 * @param type - html input type
 * @param placeholder - i.e. your name
 * @param textAlign - `left` | `center` | `right`
 * @param clearBorder - for transparent borders
 * @param borderRadius - default: `md`. can be `sm` | `md` | `lg`
 * @param moreVerticalPadding - adds more vertical padding, sometime needed,
 * @param iconLeft - add an icon on the left like so `<SearchIcon />`
 * @param iconRight - add an icon on the right like so `<SearchIcon />`
 *
 */
export const FormTextInput = React.forwardRef<HTMLInputElement, ITextInput>(
  (
    {
      placeholder = '',
      textAlign = 'left',
      clearBorder = false,
      borderRadius = 'md',
      moreVerticalPadding = false,
      iconLeft,
      iconRight,
      ...props
    },
    ref
  ) => {
    const borderColor = getBorderColor(props?.error, clearBorder);
    const _textAlign = getTextAlignment(textAlign);
    const bRad = getBorderRadius(borderRadius);

    const { labelSROnly, ...inputProps } = props;

    return (
      <FormInputBase {...props}>
        <div
          className={clx(
            'relative border py-4',
            bRad,
            borderColor,
            inputProps.disabled ? 'bg-gray-100 cursor-not-allowed' : 'bg-white'
          )}
        >
          {iconLeft && (
            <div className="min-w-8 pointer-events-none absolute left-0 top-1/2 ml-2 flex h-8 w-8 -translate-y-1/2 transform items-center justify-center sm:ml-5">
              {iconLeft}
            </div>
          )}

          <input
            ref={ref}
            className={clx(
              `placeholder-gray-DEFAULT focus:border-orange-hank sm:pr-18 w-full pr-10 text-base font-normal tracking-wider text-black focus:outline-none disabled:bg-gray-100 disabled:cursor-not-allowed disabled:text-gray-600`,
              iconLeft ? 'pl-18' : 'pl-6',
              _textAlign
            )}
            placeholder={placeholder || 'Type something...'}
            {...inputProps}
          />

          {iconRight && (
            <div className="min-w-8 pointer-events-none absolute left-0 top-1/2 ml-2 flex h-8 w-8 -translate-y-1/2 transform items-center justify-center sm:ml-5">
              {iconRight}
            </div>
          )}
        </div>
      </FormInputBase>
    );
  }
);
