import * as React from 'react';
import { useTabListState } from '@react-stately/tabs';
import { useTabList } from '@react-aria/tabs';
import { TabListProps } from '@react-types/tabs';
import clx from 'classnames';

import { Tab } from './Tab';
import { TabPanel } from './TabPanel';

// TOFIX: update a type of children to accept only a collection
// CRITICALITY: medium

interface ITabProps {
  children: TabListProps<Record<string, unknown>>['children'];
  selectedTab?: string;
  onTabChange?: (tabKey: any) => void;
  classes?: {
    root?: string;
    tabList?: string;
    tabItem?: string;
    tabPanel?: string;
  };
}

const Tabs: React.FC<React.PropsWithChildren<ITabProps>> = (props) => {
  const ref = React.useRef<HTMLDivElement | null>(null);

  const state = useTabListState({
    ...props,
    defaultSelectedKey: props.selectedTab,
    onSelectionChange: props.onTabChange,
  });
  const { tabListProps } = useTabList(props, state, ref);

  return (
    <div
      {...tabListProps}
      ref={ref}
      className={clx(props.classes?.root, 'flex w-full flex-col')}
    >
      <div
        className={clx('flex w-full', props.classes?.tabList || 'space-x-4')}
      >
        {[...state.collection].map((item) => (
          <Tab
            key={item.key}
            item={item}
            state={state}
            className={{
              root: clx(props.classes?.tabItem),
            }}
          />
        ))}
      </div>
      <TabPanel
        key={state.selectedItem?.key}
        state={state}
        className={clx(props.classes?.tabPanel, 'w-full')}
      />
    </div>
  );
};

export { Tabs };
