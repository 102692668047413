import React, { FunctionComponent } from 'react';
import IIconProp from '../iconInterface';

export const DeviceIcon = ({ fillColor = '#000' }: IIconProp) => {
  return (
    <svg
      className="w-auto"
      height="38px"
      viewBox="0 0 38 38"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          fill={fillColor}
          d="M29.8854167,6.33333333 L20.78125,6.33333333 C19.7974928,6.33333333 19,7.13082612 19,8.11458333 L19,23.5520833 C19,24.5358405 19.7974928,25.3333333 20.78125,25.3333333 L29.8854167,25.3333333 C30.8691739,25.3333333 31.6666667,24.5358405 31.6666667,23.5520833 L31.6666667,8.11458333 C31.6666667,7.13082612 30.8691739,6.33333333 29.8854167,6.33333333 Z M28.5,22.1666667 L22.1666667,22.1666667 L22.1666667,9.5 L28.5,9.5 L28.5,22.1666667 Z M6.33333333,3.16666667 L22.1666667,3.16666667 L22.1666667,4.75 L25.3333333,4.75 L25.3333333,2.375 C25.3306225,1.06618478 24.2715943,0.00516715991 22.9627865,0 L5.53721354,0 C4.22840571,0.00516715991 3.1693775,1.06618478 3.16666667,2.375 L3.16666667,14.25 L0.791666667,14.25 C0.35444124,14.25 0,14.6044412 0,15.0416667 L0,15.8333333 C0.00379894198,17.5789223 1.41663163,18.9937446 3.16221354,19 L17.4166667,19 L17.4166667,14.25 L6.33333333,14.25 L6.33333333,3.16666667 Z"
          id="DeviceIconpath-1"
        ></path>
      </defs>
      <g
        id="DeviceIcon"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="DeviceIconColours/Black-#000000"
          transform="translate(3.166667, 6.333333)"
        >
          <mask id="DeviceIconmask-2" fill="white">
            <use xlinkHref="#DeviceIconpath-1"></use>
          </mask>
          <use
            id="DeviceIconMask"
            fill={fillColor}
            fillRule="nonzero"
            xlinkHref="#DeviceIconpath-1"
          ></use>
          <g id="DeviceIconGroup" mask="url(#DeviceIconmask-2)">
            <g
              transform="translate(-19.000000, -22.166667)"
              id="DeviceIconColours/Black-#000000"
            >
              <rect
                id="DeviceIconRectangle"
                fill={fillColor}
                x="0"
                y="0"
                width="69.6666667"
                height="69.6666667"
              ></rect>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DeviceIcon;
