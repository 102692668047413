// *******************************************************
// Stack
// -------------------------------------------------------
// Component Description
// Use this Stack component to have visualy balances white space between components
// idea is taken from Braid Design System
//
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import React, { Children, FC, ElementType } from 'react';
import isEmpty from 'lodash/isEmpty';
import { resolveResponsiveProps } from '@tapestry/shared/utils';
import { ResponsiveBreakpoints, SpacingScale } from '@tapestry/types';
import flattenChildren from 'react-flatten-children';
import clx from 'classnames';

// -------------------------------------------
// *******************************************
// Utils Imports
// -------------------------------------------

const getAlignment = (value: string | undefined): string => {
  if (!value) return '';

  switch (value) {
    case 'left':
      return 'flex flex-col';

    case 'center':
      return 'flex flex-col items-center';

    case 'right':
      return 'flex flex-col items-end';

    default:
      return 'flex flex-col';
  }
};

const getVerticalSpacing = (
  spacing: IStackProps['spacing'] | IStackProps['spacing'][]
) => {
  if (!spacing || isEmpty(spacing) || spacing === undefined) {
    return '';
  }

  const sizing = {
    none: 'pt-0',
    xxsmall: 'pt-1',
    xsmall: 'pt-2',
    small: 'pt-4',
    medium: 'pt-8',
    large: 'pt-12',
    xlarge: 'pt-16',
  };

  return resolveResponsiveProps(
    sizing,
    spacing as ResponsiveBreakpoints | ResponsiveBreakpoints[]
  );
};

const getTopMargin = (
  spacing: IStackProps['spacing'] | IStackProps['spacing'][]
) => {
  const marginOptions = {
    none: 'mt-0',
    xxsmall: 'mt-1',
    xsmall: 'mt-2',
    // here you want mt-3
    small: 'mt-4',
    medium: 'mt-8',
    large: 'mt-12',
    xlarge: 'mt-16',
  };
  return resolveResponsiveProps(
    marginOptions,
    spacing as ResponsiveBreakpoints | ResponsiveBreakpoints[]
  );
};

const getBottomSpacing = (
  spacing: IStackProps['spacing'] | IStackProps['spacing'][]
) => {
  const marginOptions = {
    none: 'mb-0',
    xxsmall: 'mb-1',
    xsmall: 'mb-2',
    small: 'mb-4',
    medium: 'mb-8',
    large: 'mb-12',
    xlarge: 'mb-16',
  };
  return resolveResponsiveProps(
    marginOptions,
    spacing as ResponsiveBreakpoints | ResponsiveBreakpoints[]
  );
};

/**
 * if the child is permissionGate, check for permission and null render manually has it creates an error otherwise
 */
// const checkIfIsAPermissionGateAndPermissions = (child) => {
//   if (
//     typeof child.type === 'function' &&
//     child.type.name === 'PermissionGate'
//   ) {
//     // return true
//     const childScope = child.props.scopes;
//     const hasPermissions = hasPermissions(childScope);
//     return hasPermissions ? child : null;
//   }

//   return child;
// };

// *******************************************
// Local Interface
// -------------------------------------------

interface IStackProps {
  spacing?: Omit<SpacingScale, 'xxlarge'> | Omit<SpacingScale, 'xxlarge'>[];
  align?: 'left' | 'center' | 'right';
  withBottomSpacing?: boolean;
  hasTopMargin?: boolean;
  as?: ElementType;
}

/**
 * Stack
 *
 * Use it to vertically space its children by a given amount.
 *
 * @param {string} spacing - default:`medium` - control the spacing between elements. Supports responsive props like so ['small','medium']. See {@link resolveResponsiveProps} for more infoon responsive props
 * @param {string} align controls whihc direction the elements get aligned too
 * @param {boolean} withBottomSpacing if you want spacing at the bottom
 * @default false
 * @param {boolean} hasTopMargin if you want the space at the top as well
 * @default false
 * @param flex - change flex over a certain breakpoint
 *
 */
export const Stack: FC<React.PropsWithChildren<IStackProps>> = ({
  children,
  spacing = 'medium',
  align = undefined,
  hasTopMargin = false,
  withBottomSpacing = false,
  as,
}) => {
  const Component = as || 'div';
  const verticalSpacing = getVerticalSpacing(spacing);
  const topMargin = getTopMargin(spacing);
  const bottomMargin = getBottomSpacing(spacing);
  const alignment = getAlignment(align);
  const nOfChildren = React.Children.count(children);
  const lastIndex = nOfChildren - 1;

  return (
    <Component
      className={clx(
        `w-full`,
        hasTopMargin ? topMargin : '',
        withBottomSpacing ? bottomMargin : '',
        alignment
      )}
    >
      {Children.map(flattenChildren(children), (child, index) => {
        // const checkedChild = checkIfIsAPermissionGateAndPermissions(child);
        // TODO replace `child` below with `checkedChild`

        return child && index > 0 ? (
          <div className={verticalSpacing}>{child}</div>
        ) : (
          child
        );
      })}
    </Component>
  );
};

export default Stack;
