import { useMultipleSelection } from 'downshift';
import isEmpty from 'lodash/isEmpty';
import React, { VFC } from 'react';
import { Pill } from '../../Pill';
import { InternalCombobox } from '../InternalCombobox';
import {
  ComboBoxOptionItem,
  ComboboxOptionsConfig,
  IMultiComboboxProps,
} from '@tapestry/types';
import { defaultLabelAccessor } from '../combobox-utils';

/**
 *
 */
export const MultiCombobox: VFC<IMultiComboboxProps> = (props) => {
  /**
   * prop extraction
   */
  const { onChange, options, defaultOptions } = props;
  const optionsConfig: ComboboxOptionsConfig = Array.isArray(options)
    ? { options }
    : options;
  const labelAccessor = optionsConfig.itemAccessor
    ? optionsConfig.itemAccessor
    : defaultLabelAccessor;

  /**
   * State
   */
  const {
    selectedItems,
    getSelectedItemProps,
    getDropdownProps,
    removeSelectedItem,
    addSelectedItem,
  } = useMultipleSelection({
    defaultSelectedItems: defaultOptions || [],
    onSelectedItemsChange({ selectedItems }) {
      onChange(selectedItems);
    },
  });

  /**
   * utils
   */
  const handleSelectedItemSelection = (selectedItem: ComboBoxOptionItem) => {
    if (selectedItems.some((item) => item.id === selectedItem?.id)) {
      removeSelectedItem(selectedItem);
    } else {
      addSelectedItem(selectedItem);
    }
  };

  return (
    <>
      <InternalCombobox
        multiSelectionSelectedItems={selectedItems}
        handleSelectedItemSelection={handleSelectedItemSelection}
        getDropdownProps={getDropdownProps}
        {...props}
      />

      {!isEmpty(selectedItems) ? (
        <div className="mt-4 flex flex-wrap items-center space-x-4">
          {selectedItems.map((selectedItemToRender, index) => {
            return (
              <span
                {...getSelectedItemProps({
                  selectedItem: selectedItemToRender,
                  index,
                })}
                key={selectedItemToRender?.id}
              >
                <Pill
                  label={labelAccessor(selectedItemToRender)}
                  onClear={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    removeSelectedItem(selectedItemToRender);
                  }}
                />
              </span>
            );
          })}
        </div>
      ) : null}
    </>
  );
};
