import React, { VFC } from 'react';
import { PlusIcon } from '@tapestry/shared/icons';
import { Pill } from '../Pill';
import { useFocusManager } from 'react-aria';
import { ITag } from '@tapestry/shared/graphql';

/**
 * a `Tag` wrapped with keyboard management
 */
export const TagInputTag: VFC<{
  tag: ITag;
  handleRemoveTag: (tagId: string) => void;
}> = ({ tag, handleRemoveTag }) => {
  const focusManager = useFocusManager();

  const handleOnKeyDown = (e: React.KeyboardEvent, tagId: string) => {
    switch (e.key) {
      case 'ArrowRight':
        focusManager.focusNext({ wrap: true });
        break;
      case 'ArrowLeft':
        focusManager.focusPrevious({ wrap: true });
        break;
      case 'Tab':
        e.shiftKey ? focusManager.focusFirst() : focusManager.focusLast();
        break;
      case 'Enter':
        handleRemoveTag(tagId);
        break;
    }
  };

  return (
    <li>
      <Pill
        label={tag.name}
        onKeyDown={(e) => handleOnKeyDown(e, tag.id)}
        onClear={() => handleRemoveTag(tag.id)}
      />
    </li>
  );
};

/**
 * A button styled as a tag that displays the current inputValue
 */
export const InputValueTag: VFC<{
  inputValue: string;
  handleAddAsNewTag: (value: string) => void;
}> = ({ inputValue, handleAddAsNewTag }) => {
  const focusManager = useFocusManager();

  const handleOnKeyDown = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case 'ArrowRight':
        focusManager.focusNext({ wrap: true });
        break;
      case 'ArrowLeft':
        focusManager.focusPrevious({ wrap: true });
        break;
      case 'Tab':
        e.shiftKey ? focusManager.focusFirst() : focusManager.focusLast();
        break;
      case 'Enter':
        handleAddAsNewTag(inputValue);
        break;
    }
  };

  return (
    <li>
      <button
        type="button"
        title="Add as tag"
        onClick={() => handleAddAsNewTag(inputValue)}
        onKeyDown={handleOnKeyDown}
        className="rounded-full transition-shadow duration-150 focus:shadow-md focus:outline-none"
      >
        <Pill
          label={inputValue}
          iconRight={<PlusIcon light fillColor="#fff" />}
          bgColor="bg-orange"
          textColor="text-white"
        />
      </button>
    </li>
  );
};
