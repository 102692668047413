import React, { FC } from 'react';
import { Control } from 'react-hook-form';
// This import is for any use
// import PhoneInput from 'react-phone-number-input';
// This import is used to work with react hook form
import ReactHookFormPhoneInput from 'react-phone-number-input/react-hook-form';

/**
 * Library used
 *
 * https://www.npmjs.com/package/react-phone-number-input?activeTab=readme
 *
 * * stylesheet in app `styles` folder
 */

interface IPhoneNumberInputProps {
  name: string;
  control: Control;
}

/**
 * Input used to pick a flag and mobile number
 * Takes care of all the parsing
 *
 * alternative: for a simple form input use `FormTextInput`
 *
 * @return string in e.614 format "+1123123123133"
 */
export const PhoneNumberInput: FC<IPhoneNumberInputProps> = ({
  name,
  control,
}) => {
  return (
    <ReactHookFormPhoneInput
      placeholder="Enter mobile number"
      defaultCountry="AU"
      name={name}
      control={control}
    />
  );
};

export default PhoneNumberInput;
