import React, { useEffect, useState, FC } from 'react';
import { usePortal, useProfile } from '@tapestry/shared/hooks';
import {
  IShopSelectorGroupType,
  IThreadV4Type,
  useShopSelector,
} from '@tapestry/shared/graphql';
import { getActiveShopFromGroupedShop } from './NavbarSearchUtils';
import { Popover, Transition } from '@headlessui/react';
import Skeleton from 'react-loading-skeleton';
import { ChevronDownIcon, LoadingSpinnerIcon } from '@tapestry/shared/icons';
import { Divider, Logo, Stack } from '@tapestry/weave';
import isEmpty from 'lodash/isEmpty';
import { getTwistByKey, dateTime } from '@tapestry/shared/utils';
import { Twist } from '@tapestry/types';
import { GroupSection } from './NavbarShopSelectorGroupSection';
import { DropdownShopsList } from './NavbarShopSelectorDropdownShopList';
import orderBy from 'lodash/orderBy';
import { useRouter } from 'next/router';
import { Maybe } from 'graphql/jsutils/Maybe';

const getLogo = (
  activeShopOrGroup: IShopSelectorGroupType | IThreadV4Type | null
) => {
  if (!activeShopOrGroup) return null;

  return 'logo' in activeShopOrGroup
    ? activeShopOrGroup?.logo
    : getTwistByKey(Twist.Logo, activeShopOrGroup as IThreadV4Type)?.value;
};
interface INavBarShopSelectorProps {
  invertTextColor?: boolean;
  inHeader?: boolean;
}

export const NavBarShopSelector: FC<INavBarShopSelectorProps> = ({
  invertTextColor,
  inHeader,
}) => {
  const [profile, setProfile, { loading: isUpdatingProfile }] = useProfile();
  const {
    query: { shopId, groupId },
  } = useRouter();
  const { data: shopSelector, loading: isLoadingStoreSelector } =
    useShopSelector({
      pollInterval: 60000,
      onCompleted: ({ shopSelector }) => {
        // TODO to review becuase this will happen every minute
        if (!profile || profile?.active_shop_scope?.type === 'group') return;

        const activeShop = shopSelector?.allShops?.find(
          (shop) => shop.id === profile?.active_shop_scope?.id
        );
        const timezone = getTwistByKey(Twist.Timezone, activeShop)?.value;

        if (timezone) {
          // set default timezone for all new `dayjs()` object
          dateTime.setDefaultTimezone(timezone);
        }
      },
    });
  const groups = shopSelector?.shopSelector?.groups;
  const { Portal, ref, recalculateBoundingBox } =
    usePortal<HTMLButtonElement>();
  const [shopDropdownSearchValue, setShopDropdownSearchValue] = useState('');

  const activeShopOrGroup = getActiveShopFromGroupedShop(
    groups || [],
    shopId as Maybe<string>,
    groupId as Maybe<string>
  );

  const activeStoreLogo = getLogo(activeShopOrGroup);

  const orderByLargestGroupFirst = (
    groups: IShopSelectorGroupType[] | undefined
  ) => {
    return orderBy(groups, ({ shops }) => shops?.length, ['desc']);
  };

  const shopBySearchValue = (shop: IThreadV4Type) => {
    return (
      shop.title
        ?.toLowerCase()
        ?.includes(shopDropdownSearchValue.toLowerCase()) ?? false
    );
  };

  const groupBySearchValue = (group: IShopSelectorGroupType) => {
    const hasMatchingGroupTitle = group.title
      .toLowerCase()
      .includes(shopDropdownSearchValue.toLowerCase());
    const hasMatchingGroupShop = group?.shops?.some(shopBySearchValue);

    return hasMatchingGroupTitle || hasMatchingGroupShop ? true : false;
  };

  useEffect(() => {
    if (!isLoadingStoreSelector && groups) {
      recalculateBoundingBox();
    }
  }, [isLoadingStoreSelector, groups, recalculateBoundingBox]);

  if (isEmpty(groups)) {
    return null;
  }

  return (
    <Popover className="relative">
      {({ open, close: closeDropdownMenu }) => (
        <>
          <Popover.Button
            ref={ref}
            data-cy="nav-shop-selector-button"
            className={`inline-flex flex-grow items-center truncate font-bold focus:outline-none ${
              invertTextColor ? 'text-white' : 'text-black'
            } ${
              inHeader
                ? 'py-4 pr-3 text-base sm:pr-6'
                : 'py-3 pl-4 pr-3 text-sm sm:px-6'
            }`}
          >
            {(isLoadingStoreSelector || isUpdatingProfile) && (
              <Skeleton
                circle
                height="1rem"
                width="1rem"
                className="mr-2 flex-shrink-0"
              />
            )}

            {activeStoreLogo && !isUpdatingProfile && (
              <Logo
                src={activeStoreLogo}
                alt="shop's logo"
                size={inHeader ? 'md' : 'xsmall'}
              />
            )}

            <span className="xs:w-auto ml-2 w-20 truncate">
              {isUpdatingProfile || !activeShopOrGroup ? (
                <Skeleton width={80} />
              ) : (
                activeShopOrGroup?.title
              )}
            </span>

            <ChevronDownIcon
              className={`transition- ml-2 h-3 w-3 flex-shrink-0 duration-300 ${
                open ? ' rotate-180' : ''
              }`}
              fillColor="currentColor"
            />
          </Popover.Button>

          <Portal>
            <Transition
              enter="transition ease-out duration-200"
              enterFrom=" opacity-0 scale-95"
              enterTo=" opacity-100 scale-100"
              leave="transition ease-out duration-200"
              leaveFrom=" opacity-100 scale-100"
              leaveTo=" opacity-0 scale-95"
              className="fixed left-0 right-0 sm:absolute sm:right-auto"
            >
              <Popover.Panel
                className="tapestry-scrollbar max-h-sm md:min-w-72 lg:min-w-xl z-20 mt-4 max-w-xl overflow-y-scroll rounded-md bg-white p-3 shadow-lg md:max-h-80"
                aria-orientation="vertical"
              >
                <Stack spacing="xsmall">
                  {/* * KEEP. @optimistic-updt is experimenting in it's spare time to make our live easier by adding a search 11/04/2022 */}
                  {/* <label
                    htmlFor="dropdown-search"
                    className="sr-only mb-2 block text-left capitalize text-gray-text"
                  >
                    Search
                  </label>

                  <input
                    type="search"
                    name="dropdown-search"
                    id="dropdown-search"
                    className="w-full rounded-md border-2 py-1 px-4 text-sm placeholder-gray-normal transition-colors duration-150 focus:border-orange-hank focus:outline-none"
                    placeholder="Search shops..."
                    value={shopDropdownSearchValue}
                    onChange={(e) => setShopDropdownSearchValue(e.target.value)}
                  /> */}

                  {isLoadingStoreSelector ? (
                    <div className="flex h-4 justify-center">
                      <LoadingSpinnerIcon />
                    </div>
                  ) : (
                    <>
                      {orderByLargestGroupFirst(groups)
                        .filter(groupBySearchValue)
                        ?.map((group, idx) => (
                          <GroupSection
                            key={group?.id}
                            idx={idx}
                            group={group}
                            closeDropdownMenu={closeDropdownMenu}
                            shopBySearchValue={shopBySearchValue}
                          />
                        ))}

                      <Divider />

                      <div className="p-2">
                        <h6 className="text-gray-text pl-2 text-xs font-normal capitalize">
                          All shops
                        </h6>

                        <DropdownShopsList
                          shopsList={shopSelector?.shopSelector?.allShops.filter(
                            shopBySearchValue
                          )}
                          closeDropdownMenu={closeDropdownMenu}
                        />
                      </div>
                    </>
                  )}
                </Stack>
              </Popover.Panel>
            </Transition>
          </Portal>
        </>
      )}
    </Popover>
  );
};
