import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { FC, useMemo } from 'react';
import { DemoModeBanner } from './banners/DemoModeBanner';
import { ShopOfflineBanner } from './banners/ShopOfflineBanner';
import { UserNotRegisteredBanner } from './banners/UserNotRegisteredBanner';

/**
 * Manages displaying banners to ensure there's never two showing at the same time
 */
export const BannerManager: FC<{
  isPublicPage?: boolean;
  userIsNotRegistered?: boolean;
}> = ({ isPublicPage = false, userIsNotRegistered = false }) => {
  const {
    demoMode: hasDemoModeFlag,
    shopOfflineBanner: hasShopOfflineBannerFlag,
  } = useFlags();

  const bannerComponent = useMemo(() => {
    if (hasDemoModeFlag) {
      return <DemoModeBanner />;
    }

    if (userIsNotRegistered) {
      return <UserNotRegisteredBanner />;
    }

    if (hasShopOfflineBannerFlag && !isPublicPage) {
      return <ShopOfflineBanner />;
    }

    return null;
  }, [
    hasDemoModeFlag,
    hasShopOfflineBannerFlag,
    isPublicPage,
    userIsNotRegistered,
  ]);

  return bannerComponent;
};
