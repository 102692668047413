import React from 'react';
import { avatarPlaceholder } from '@tapestry/shared/icons';
import Image from 'next/image';

interface IAvatarProps {
  src?: string | null;
  className?: string;
  alt?: string;
  size?: 'xsmall' | 'sm' | 'md' | 'lg' | '2xl' | '4xl';
}

const resolveSizing = (size: IAvatarProps['size']) => {
  switch (size) {
    case 'xsmall':
      return { pixelSize: 16, tailwindSizeClasses: 'h-4 w-4 min-w-4' };

    case 'sm':
      return { pixelSize: 24, tailwindSizeClasses: 'h-6 w-6 min-w-6' };

    case 'md':
      return {
        pixelSize: 32,
        tailwindSizeClasses: 'h-8 w-8 min-w-8 sm:h-10 sm:w-10 sm:min-w-10',
      };

    case 'lg':
      return { pixelSize: 40, tailwindSizeClasses: 'h-10 w-10 min-w-10' };

    case '2xl':
      return { pixelSize: 80, tailwindSizeClasses: 'h-20 w-20 min-w-20' };

    case '4xl':
      return { pixelSize: 128, tailwindSizeClasses: 'h-32 w-32 min-w-32' };

    default:
      return {
        pixelSize: 32,
        tailwindSizeClasses: 'h-8 w-8 min-w-8 sm:h-10 sm:w-10 sm:min-w-10',
      };
  }
};

/**
 * a simple rounded avatar with default fallback
 *
 * use `Logo` for a logo
 */
const Avatar = ({ src, className, alt, size }: IAvatarProps) => {
  const { pixelSize, tailwindSizeClasses } = resolveSizing(size);

  return (
    <Image
      src={src || avatarPlaceholder}
      alt={alt || 'User avatar'}
      title={alt || ''}
      className={`rounded-full ${tailwindSizeClasses} ${className}`}
      width={pixelSize}
      height={pixelSize}
    />
  );
};

export { Avatar };
