import { AppProps } from 'next/app';
import Head from 'next/head';
import {
  NextTapestryProviders,
  withTapestryLDProvider,
} from '@tapestry/shared/client';
import { BannerManager, NavigationBar } from '@tapestry/shared/components';
import { URL_MESSAGE_ID } from '@tapestry/shared/constants';
import Script from 'next/script';
import { NextRouter } from 'next/router';
import { useRouter } from 'next/router';

import '../styles/index.css';

console.table({
  NX_APP_ENV: process.env.NX_APP_ENV,
  NODE_ENV: process.env.NODE_ENV,
});

export function reportWebVitals(metric) {
  if (process.env.NX_APP_ENV !== 'production') {
    console.log(metric);
  }
}

const userIsNotRegistered = (query: NextRouter['query']) => {
  return query?.msgid === URL_MESSAGE_ID.user_not_registered;
};

function CustomApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  // useEffect(
  //   function reportPageView() {
  //     const handleRouteChange = (url) => {
  //       window.gtag('config', process.env.NX_GOOGLE_TAG_SUPPLY_APP_ID, {
  //         page_path: url,
  //       });
  //     };

  //     router.events.on('routeChangeComplete', handleRouteChange);

  //     return () => {
  //       router.events.off('routeChangeComplete', handleRouteChange);
  //     };
  //   },
  //   [router.events]
  // );

  return (
    <>
      <Head>
        <title>Supplier+</title>
        {/* description ? */}
      </Head>

      {/* <!-- Google Tag Manager --> */}
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
       (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${process.env.NX_GOOGLE_TAG_MANAGER_ID}');
      `}
      </Script>
      {/* <!-- End Google Tag Manager --> */}

      <Script
        src="https://www.bugherd.com/sidebarv2.js?apikey=tqit3vxrjvx3udbunx06ra"
        strategy="lazyOnload"
      />

      <Script id="hotjar" strategy="afterInteractive">
        {`(function (h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: 3063517, hjsv: 6 };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');`}
      </Script>

      <NextTapestryProviders {...pageProps}>
        <BannerManager
          isPublicPage={pageProps.public || false}
          userIsNotRegistered={userIsNotRegistered(router.query)}
        />

        {/* * The navbar is going to require different set of icons per app, you can use pageProps + a new prop to change that */}
        {!pageProps.public && !pageProps.hideNavbar && (
          <NavigationBar noShadow={pageProps?.noShadowOnNavbar || false} />
        )}

        <Component {...pageProps} />
      </NextTapestryProviders>
    </>
  );
}

export default withTapestryLDProvider(CustomApp);
