export const HOURS_IN_DAYS = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
];

export enum MeridiemPeriod {
  AM = 'AM',
  PM = 'PM',
}

export const meridiemPeriods = [MeridiemPeriod.AM, MeridiemPeriod.PM];

export enum WeekDay {
  MON = 'monday',
  TUE = 'tuesday',
  WED = 'wednesday',
  THU = 'thursday',
  FRI = 'friday',
  SAT = 'saturday',
  SUN = 'sunday',
}

export const WEEK_DAYS = [
  WeekDay.MON,
  WeekDay.TUE,
  WeekDay.WED,
  WeekDay.THU,
  WeekDay.FRI,
  WeekDay.SAT,
  WeekDay.SUN,
];

export enum Month {
  JAN = 'january',
  FEB = 'february',
  MAR = 'march',
  APR = 'april',
  MAY = 'may',
  JUN = 'june',
  JUL = 'july',
  AUG = 'august',
  SEP = 'september',
  OCT = 'october',
  NOV = 'november',
  DEC = 'december',
}

export const MONTHS = [
  Month.JAN,
  Month.FEB,
  Month.MAR,
  Month.APR,
  Month.MAY,
  Month.JUN,
  Month.JUL,
  Month.AUG,
  Month.SEP,
  Month.OCT,
  Month.NOV,
  Month.DEC,
];
