import { ResponsiveBreakpoints } from '@tapestry/types';
import isEmpty from 'lodash/isEmpty';

// ! Eventually index: string should be `ResponsiveBreakPoint`
type Options = { readonly [index: string]: string } | string;
type Choice = ResponsiveBreakpoints | ResponsiveBreakpoints[];

/**
 * resolveResponsiveProps
 *
 * Utils function that can enable a simple style prop to behave responsively by provindig an array of value instead of a single value
 *
 * @param options - An object with possible choice where the key is a breakpoint and the value is the value for it
 * @param choice - the prop you are trying to resolve
 */
export const resolveResponsiveProps = (
  options: Options,
  choice: Choice | Choice[]
) => {
  if (!options) {
    throw new Error(
      'resolveResponsiveProps(): Please provide an object of possible options'
    );
  }

  if (!choice || isEmpty(choice)) {
    return '';
  }

  if (typeof choice === 'string') {
    return options[choice] || `${choice}${options}`;
  }

  if (Array.isArray(choice)) {
    const responsivePropArr = choice.map((space, index) => {
      if (index === 1) {
        return `xs:${options[space] || options}`;
      }
      if (index === 2) {
        return `sm:${options[space] || options}`;
      }
      if (index === 3) {
        return `md:${options[space] || options}`;
      }
      if (index === 4) {
        return `lg:${options[space] || options}`;
      }
      if (index === 5) {
        return `xl:${options[space] || options}`;
      }
      if (index === 6) {
        return `xxl:${options[space] || options}`;
      }

      return options[space] || options;
    });

    // return a string `pb-2 sm:pb-12 ...`
    return responsivePropArr.join(' ');
  }
};
