import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import clx from 'classnames';

export interface ILabelProps {
  htmlFor: string;
  isLoading?: boolean;
  size?: 'small' | 'normal' | 'large';
}

const Label: React.FC<React.PropsWithChildren<ILabelProps>> = ({
  children,
  htmlFor,
  isLoading,
  size = 'normal',
}) => {
  return (
    <label
      htmlFor={htmlFor}
      className={clx(
        'block text-base font-bold capitalize tracking-wide text-black',
        {
          'text-sm': size === 'small',
          'text-base': size === 'normal',
          'text-xl': size === 'large',
        }
      )}
    >
      {isLoading ? <Skeleton /> : children}
    </label>
  );
};

export { Label };
