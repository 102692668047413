import dynamic, { DynamicOptions } from 'next/dynamic';
import { LazyLoader } from './lazy-loader';

interface ILoadableProps extends DynamicOptions {
  chunkName: string;
  fallbackComponent?: React.ReactNode;
  delay?: number;
}

const DefaultFallbackComponent = () => <span>Loading Component</span>;

/**
 * @example
 * const MyLazyComponent = loadable(() => import('./lib/my-lazy-component'), {
 *  chunkName: 'lazy-component',
 *  fallbackComponent: <AppletSplashScreen />,
 * })
 */
export const loadable = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends React.ComponentType<React.PropsWithChildren<any>>
>(
  importFunc: () => Promise<{ default: T }>,
  {
    chunkName,
    fallbackComponent = <DefaultFallbackComponent />,
    delay = 1500,
    ...DynamicOptions
  }: ILoadableProps
) => {
  const LazyComponent = dynamic(importFunc, {
    loading: () => <LazyLoader delay={delay} component={fallbackComponent} />,
    ...DynamicOptions,
  });

  return LazyComponent;
};
