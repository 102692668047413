import { useMemo, useEffect } from 'react';
import { animateScroll as scroll } from 'react-scroll';

type UseScrollToTopConfig = {
  when: Element | boolean | number | string;
  duration?: number;
} & Record<string, unknown>;

/**
 * Pass a `when` to trigger a smooth scroll to the top of the page
 */
export const useScrollToTop = (config: UseScrollToTopConfig) => {
  const { when } = config;
  const options = useMemo(() => ({ duration: 500, ...config }), [config]);

  useEffect(() => {
    scroll.scrollToTop(options);
  }, [when, options]);
};
