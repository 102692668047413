import React, { VFC } from 'react';
import { ChevronDownIcon, LoadingSpinnerIcon } from '@tapestry/shared/icons';
import clx from 'classnames';
import {
  GetPropsCommonOptions,
  UseComboboxGetInputPropsOptions,
  UseComboboxGetToggleButtonPropsOptions,
} from 'downshift';
import { IComboboxUserInputProp } from '@tapestry/types';

interface IComboboxInputProps extends IComboboxUserInputProp {
  getToggleButtonProps: (
    options?: UseComboboxGetToggleButtonPropsOptions
  ) => any;
  getInputProps: (
    options?: UseComboboxGetInputPropsOptions,
    otherOptions?: GetPropsCommonOptions
  ) => any;
  isFetching?: boolean;
}

export const ComboboxInput: VFC<IComboboxInputProps> = ({
  iconLeft,
  placeholder = 'Type something...',
  getToggleButtonProps,
  getInputProps,
  isFetching = false,
}) => {
  const IconRightComponent = ChevronDownIcon;

  return (
    <div className="border-gray-border relative rounded-md border py-4">
      {iconLeft && (
        <div className="min-w-8 pointer-events-none absolute left-0 top-1/2 ml-2 flex h-8 w-8 -translate-y-1/2 transform items-center justify-center sm:ml-5">
          {iconLeft}
        </div>
      )}

      <input
        {...getInputProps({ placeholder })}
        placeholder={placeholder}
        className={clx(
          `placeholder-gray-DEFAULT focus:border-orange-hank sm:pr-18 w-full pr-10 text-base font-normal tracking-wider text-black focus:outline-none`,
          iconLeft ? 'pl-18' : 'pl-6'
        )}
      />

      {isFetching && (
        <div className="sm:pr-18 absolute top-1/2 right-0 z-10 h-4 w-4 -translate-y-1/2 transform pr-10">
          <LoadingSpinnerIcon />
        </div>
      )}

      <button
        title="Toggle dropdown menu"
        className="absolute inset-y-0 right-0 rounded-r-md px-5"
        {...getToggleButtonProps({ onClick: (e) => e.preventDefault() })}
      >
        <IconRightComponent className="h-4 w-4" fillColor="#889aa4" />
      </button>
    </div>
  );
};
