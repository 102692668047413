import React, { FC } from 'react';
import { useCheckbox } from '@react-aria/checkbox';
import { useToggleState } from '@react-stately/toggle';
import { ToggleProps } from '@react-types/checkbox';

/* eslint-disable-next-line @typescript-eslint/no-empty-interface */
export interface ICheckboxProps extends ToggleProps {}

/**
 * A Tapestry checkbox component
 */
export const Checkbox: FC<React.PropsWithChildren<ICheckboxProps>> = (
  props
) => {
  const { children } = props;
  const state = useToggleState(props);
  const ref = React.useRef<HTMLInputElement>(null);
  const { inputProps } = useCheckbox(props, state, ref);

  return (
    <label className="block">
      <input {...inputProps} ref={ref} className="mr-2" />
      {children}
    </label>
  );
};

export default Checkbox;
