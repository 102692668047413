import { IThreadV4Type } from '@tapestry/shared/graphql';
import { Twist } from '@tapestry/types';
import { dateTime, getTwistByKey } from '@tapestry/shared/utils';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { captureException } from '@sentry/nextjs';

dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);
dayjs.extend(duration);

export const SHOP_OFFLINE_THRESHOLD_IN_MINUTES = 120; // 2 hours
export const SHOP_OFFLINE_FOR_3HOURS = 180; // 3 hours;

export const calcShopOffline = (minutesSinceLastSynced: null | number) => {
  return (
    minutesSinceLastSynced === null ||
    minutesSinceLastSynced >= SHOP_OFFLINE_THRESHOLD_IN_MINUTES
  );
};

export const calcBannerColor = (minutesSinceLastSynced: null | number) => {
  return minutesSinceLastSynced === null ||
    minutesSinceLastSynced >= SHOP_OFFLINE_FOR_3HOURS
    ? 'bg-red-dark'
    : 'bg-orange-tangerine-dark';
};

export const getReadableDuration = (minutesSinceLastSynced: null | number) => {
  if (minutesSinceLastSynced === null) return 'some time';

  const ONE_HOUR = 60;
  const hours = Math.floor(minutesSinceLastSynced / ONE_HOUR);
  const minutes = minutesSinceLastSynced % ONE_HOUR;

  const format = hours
    ? `H[hr${hours === 1 ? '' : 's'}] m[min]`
    : `m [min${minutes === 1 ? '' : 's'}]`;

  return dayjs
    .duration({
      hours,
      minutes,
    })
    .format(format);
};

export const calcMinutesSinceLastSynced = (
  activeShop: IThreadV4Type | undefined
) => {
  const lastSynced = getTwistByKey(Twist.LastSyncedAt, activeShop)?.value;

  if (lastSynced === null) {
    return null;
  }

  const parsedLastSyncd = dateTime.parse(lastSynced).utc(true);

  const isValid = parsedLastSyncd.isValid();
  if (isValid) {
    const diffInMinutes = dayjs().diff(parsedLastSyncd, 'minute');
    return diffInMinutes || null;
  } else {
    console.error(
      `calcMinutesSinceLastSynced(): Wrong datetime format supplied, ${lastSynced}. Provide ISO format`
    );
    captureException(
      `calcMinutesSinceLastSynced(): Wrong datetime format supplied, ${lastSynced}. Provide ISO format`
    );

    return null;
  }
};
