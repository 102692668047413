import { Url } from '@tapestry/types';

type HanUniUrls = {
  [index: string]: { [index: string]: Url };
};

export const HANK_UNI_URLS = {
  metrics: {
    sales: 'https://hank-university.webflow.io/topics/sales-graph',
    basketValue: 'https://hank-university.webflow.io/topics/basketvalue',
    basketProfit: 'https://hank-university.webflow.io/topics/basket-profit',
    transactions: 'https://hank-university.webflow.io/topics/transactions',
    grossMargin: 'https://hank-university.webflow.io/topics/gross-margin',
    grossProfit: 'https://hank-university.webflow.io/topics/gross-profit',
    basketMargin: 'https://hank-university.webflow.io/topics/basket-margin',
    salesPerLabourHour:
      'https://hank-university.webflow.io/topics/sales-per-labour-hour',
    salesPerSQM:
      'https://hank-university.webflow.io/topics/sales-per-square-metre',
    soldOnPromotionDollar:
      'https://hank-university.webflow.io/topics/sold-on-promotion-dollar',
    soldOnPromotionPercent:
      'https://hank-university.webflow.io/topics/sold-on-promotion-percentage',
    basketSaleContributionDollar:
      'https://hank-university.webflow.io/topics/basket-sales-contribution-dollar',
    basketSaleContributionPercent:
      'https://hank-university.webflow.io/topics/basket-sales-contribution-percentage',
  },
  heartbeat: {
    overview: 'https://hank-university.webflow.io/guides/heartbeat',
    space: 'https://hank-university.webflow.io/topics/top-spaces',
    category: 'https://hank-university.webflow.io/topics/top-categories',
    product: 'https://hank-university.webflow.io/topics/top-products',
    department: 'https://hank-university.webflow.io/topics/top-departments',
    bestTimes: 'https://hank-university.webflow.io/topics/best-times-of-day',
    collections: 'https://hank-university.webflow.io/topics/top-lists',
  },
  agenda: {
    overview: 'https://hank-university.webflow.io/guides/agenda',
  },
  homepage: {
    homepage: 'https://hank-university.webflow.io/guides',
    search: 'https://hank-university.webflow.io/guides/how-to-use-search',
  },
};
