import { ApolloClient, useApolloClient } from '@apollo/client';
import { ROUTE_PATHS, URL_MESSAGE_ID } from '@tapestry/shared/constants';
import { Maybe } from 'graphql/jsutils/Maybe';
import { NextRouter, useRouter } from 'next/router';
import { useEffect, FC } from 'react';
import { useAuthToken } from '../hooks/use-auth-token/use-auth-token';

// this message is used in the email system
const userIsNotRegistered = (query: NextRouter['query']) => {
  return query?.msgid === URL_MESSAGE_ID.user_not_registered;
};

const shouldEnforcePrivateRoute = (
  isPublic = false,
  token: Maybe<string>,
  query: NextRouter['query']
) => {
  const isPrivate = !isPublic;

  if (userIsNotRegistered(query) && isPrivate) {
    return true;
  }

  return isPrivate && !token;
};

const handleRedirects = (router: NextRouter) => {
  if (userIsNotRegistered(router.query)) {
    router.push({
      pathname: ROUTE_PATHS.register,
      query: router.query,
    });
  } else {
    router.push({
      pathname: ROUTE_PATHS.login,
      query: router.query,
    });
  }
};

export const clearBrowserStorage = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('userId');
  localStorage.removeItem('token_type');
  localStorage.removeItem('expiresIn');
};

const clearCaches = async (apolloClient: ApolloClient<object>) => {
  await apolloClient.clearStore();

  if (typeof window !== 'undefined') {
    clearBrowserStorage();
  }
};

export const PrivateRouteProvider: FC<{
  isPublic: boolean;
  children: any;
}> = ({ children, isPublic }) => {
  const router = useRouter();
  const token = useAuthToken();
  const apolloClient = useApolloClient();

  useEffect(
    function enforcePrivateRoutes() {
      if (
        router.isReady &&
        shouldEnforcePrivateRoute(isPublic, token, router.query)
      ) {
        clearCaches(apolloClient);
        handleRedirects(router);
      }
    },
    [router, router.pathname, router.isReady, isPublic, token, apolloClient]
  );

  return children;
};
