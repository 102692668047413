import { TAPESTRY_APP_CONFIGS } from '@tapestry/shared/constants';
import { ChevronDownIcon, TickIcon } from '@tapestry/shared/icons';
import { ITapestryAppConfigurations } from '@tapestry/types';
import { DropdownMenu } from '@tapestry/weave';
import { getCurrentAppInfo } from '@tapestry/shared/utils';
import Link from 'next/link';
import { useRouter } from 'next/router';
import * as React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

const { appInfo, appEnvironment } = getCurrentAppInfo();

const TapestryAppSwitcher = () => {
  const { query } = useRouter();
  const { appSwitcher: hasAppSwitcherFlags } = useFlags();

  const anchorElementRef = React.useRef<HTMLDivElement | null>(null);
  const [isAppSelectorOpen, setIsAppSelectorOpen] = React.useState(false);
  const [selectedApp, setSelectedApp] =
    React.useState<ITapestryAppConfigurations>(appInfo);

  const sortedAppConfigValues = React.useMemo(
    () =>
      Object.values(TAPESTRY_APP_CONFIGS)
        .filter(({ hidden }) => !hidden)
        .sort((a, b) => a.order - b.order),
    []
  );

  const handleAppSelectorOpen = () => {
    setIsAppSelectorOpen(true);
  };

  const handleAppSelectorClose = () => {
    setIsAppSelectorOpen(false);
  };

  const handleAppSelect = (app) => () => {
    if (typeof window === 'undefined') {
      console.log('return nothing cause no window');

      return;
    }

    handleAppSelectorClose();
    setSelectedApp(app);

    window.location.assign(
      `${window.location.protocol}//${app.domains[appEnvironment]}`
    );
  };

  return (
    <div className="relative mr-3 flex-shrink-0" ref={anchorElementRef}>
      <div
        role="button"
        className="hover:bg-gray-hover focus:bg-gray-hover flex h-10 w-auto flex-shrink-0 items-center rounded-full py-2 px-4 text-black transition duration-150 ease-in-out focus:outline-none"
        style={{ backgroundColor: selectedApp.color }}
      >
        <Link
          href={{ pathname: appInfo.homePath, query }}
          className="h-6 w-6"
          aria-label="Go To Real Time Analytics"
        >
          <selectedApp.icon
            fillColor="#FFFFFF"
            className="mx-auto h-full w-full"
          />
        </Link>
        {hasAppSwitcherFlags ? (
          <button
            title="Open App Selector"
            className=""
            aria-label="App Selector"
            aria-haspopup="true"
            onClick={handleAppSelectorOpen}
          >
            <p className="sr-only">Open App Selector</p>
            <ChevronDownIcon fillColor="#FFFFFF" className="ml-4 h-4 w-auto" />
          </button>
        ) : null}
      </div>

      <DropdownMenu
        show={isAppSelectorOpen}
        clickOutsideRef={anchorElementRef}
        anchorElementRef={anchorElementRef}
        onClickOutside={handleAppSelectorClose}
        position="top-left"
        menuClassName="hidden sm:block"
      >
        {sortedAppConfigValues.map((app) => (
          <button
            key={app.order}
            className="my-1 flex w-full items-center justify-between"
            onClick={handleAppSelect(app)}
          >
            <div className="flex items-center ">
              <span
                className="mr-2 block h-8 w-8 rounded-full p-2"
                style={{ backgroundColor: app.color }}
              >
                <app.icon
                  fillColor="#FFFFFF"
                  className="mx-auto h-full w-full"
                />
              </span>
              <span className="font-bold">{app.title}</span>
            </div>
            {selectedApp.domains[appEnvironment] ===
            app.domains[appEnvironment] ? (
              <TickIcon fillColor="#000000" className="h-3" />
            ) : null}
          </button>
        ))}
      </DropdownMenu>
    </div>
  );
};

export { TapestryAppSwitcher };
