import React, { FC } from 'react';
import { FieldError, FieldErrors, UseFormRegister } from 'react-hook-form';

type FieldErrorsValues = FieldErrors[keyof FieldErrors];

const getBorderColor = (
  inputError: FieldError | undefined,
  clearBorder: boolean
) => {
  if (inputError) {
    return 'border-red-light';
  }

  return clearBorder ? 'border-transparent' : 'border-gray-lightest';
};

const getTextAlignment = (alignment: ITextInput['textAlign']) => {
  switch (alignment) {
    case 'left':
      return 'text-left';

    case 'center':
      return 'text-center';

    case 'right':
      return 'text-right';

    default:
      return 'text-left';
  }
};

const getBorderRadius = (br: ITextInput['borderRadius']) => {
  switch (br) {
    case 'sm':
      return 'rounded-sm';

    case 'md':
      return 'rounded-md';

    case 'lg':
      return 'rounded-lg';

    default:
      return 'rounded-sm';
  }
};

// ! one case hasn't be done: icons on both side
const getHorizontalPadding = (hasLeftIcon: boolean, hasRightIcon: boolean) => {
  let hPadding = 'px-3';
  if (hasLeftIcon) {
    hPadding = 'pl-10 pr-3';
  }
  if (hasRightIcon) {
    hPadding = 'pl-3 pr-10';
  }

  return hPadding;
};

interface ITextInput {
  register: UseFormRegister<any>;
  name: string;
  type?: string;
  placeholder?: string;
  textAlign?: 'left' | 'center' | 'right';
  errors: FieldErrors;
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
  clearBorder?: boolean;
  borderRadius?: 'sm' | 'md' | 'lg';
  moreVerticalPadding?: boolean;
}

/**
 * Simple Form Input component
 *
 * Alternative: For a fully fledge tapestry component, look at TapTextComponent
 *
 * @param name - input name. used in `register` method
 * @param register - `useForm` register method
 * @param errors - error object from `useForm`
 * @param type - html input type
 * @param placeholder - i.e. your name
 * @param textAlign - `left` | `center` | `right`
 * @param clearBorder - for transparent borders
 * @param borderRadius - default: `sm`. can be `sm` | `md` | `lg`
 * @param moreVerticalPadding - adds more vertical padding, sometime needed,
 * @param iconLeft - add an icon on the left like so `<SearchIcon />`
 * @param iconRight - add an icon on the right like so `<SearchIcon />`
 *
 */
const TextInput: FC<ITextInput> = ({
  register,
  name,
  type = 'text',
  errors,
  placeholder = '',
  textAlign = 'center',
  clearBorder = false,
  borderRadius = 'sm',
  moreVerticalPadding = false,
  iconLeft,
  iconRight,
}) => {
  const borderColor = getBorderColor(
    errors[name] as FieldError | undefined,
    clearBorder
  );
  const _textAlign = getTextAlignment(textAlign);
  const bRad = getBorderRadius(borderRadius);
  const horizontalPadding = getHorizontalPadding(
    Boolean(iconLeft),
    Boolean(iconRight)
  );
  return (
    <>
      <label
        htmlFor={name}
        className="text-gray-text sr-only mb-2 block text-left capitalize"
      >
        {name}
      </label>

      <div className={`relative ${bRad}`}>
        {iconLeft && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            {React.cloneElement(iconLeft, {
              className: 'h-5 w-5',
              fillColor: '#889AA4',
            })}
          </div>
        )}

        <input
          id={name}
          type={type}
          {...register(name)}
          className={`placeholder-gray-normal focus:border-orange-hank w-full border-2 text-base transition-colors duration-150 focus:outline-none ${
            moreVerticalPadding ? 'py-4' : 'py-3'
          } ${horizontalPadding}
          ${borderColor} ${bRad} ${_textAlign}`}
          placeholder={placeholder}
        />

        {iconRight && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            {React.cloneElement(iconRight, {
              className: 'h-5 w-5',
              fillColor: '#889AA4',
            })}
          </div>
        )}
      </div>

      {errors[name] && (
        <div className="text-red-normal mb-2 mt-0 text-xs">
          {(errors[name]?.message as string) ||
            'Sorry, an error has occured with this field'}
        </div>
      )}
    </>
  );
};

export { TextInput };
