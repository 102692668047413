import React, { FC } from 'react';
import { Button, MainHeading, ModalOverlay } from '@tapestry/weave';
import { OverlayContainer } from '@react-aria/overlays';

interface IHeaderFiltersMobileModal {
  onReset: () => void;
  onDone: () => void;
  hasActiveFilters: boolean;
  onClose: () => void;
}

export const HeaderFiltersMobileModalWrapper: FC<
  React.PropsWithChildren<IHeaderFiltersMobileModal>
> = ({ onReset, onDone, hasActiveFilters, onClose, children }) => {
  return (
    <OverlayContainer>
      <ModalOverlay isOpen title="filters" onClose={onClose}>
        <div className="min-h-screen bg-gray-100 text-center">
          <div className="text-right">
            <button
              title="Close modal"
              aria-label="Close modal"
              className={`p-4 text-sm font-bold uppercase transition-colors duration-150 ease-linear disabled:cursor-wait ${
                hasActiveFilters
                  ? 'text-blue hover:text-blue-dark focus:text-blue-dark'
                  : 'text-gray-text'
              }`}
              onClick={hasActiveFilters ? onDone : onClose}
            >
              Done
            </button>
          </div>

          <div className="pl-4 pb-4 pr-4">
            <MainHeading className="mb-8">Filters</MainHeading>

            {children}

            <div className="mt-10">
              <Button
                status="basic"
                onClick={onReset}
                disabled={!hasActiveFilters}
                fullWidth
              >
                Reset
              </Button>
            </div>
          </div>
        </div>
      </ModalOverlay>
    </OverlayContainer>
  );
};
