import * as React from 'react';
import IIconProp from '../iconInterface';

const RetailLogoIcon: React.VFC<IIconProp> = ({
  className = 'h-full w-auto',
  fillColor = '#000',
}) => (
  <svg
    className={className}
    viewBox="0 0 17 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7883 2.35719L15.3337 4.82415C16.1338 6.10049 15.4242 7.87213 13.9288 8.07215C13.8216 8.08644 13.7145 8.09358 13.6025 8.09358C12.9001 8.09358 12.2762 7.78402 11.8452 7.3054C11.4166 7.78402 10.7927 8.09358 10.0878 8.09358C9.38537 8.09358 8.75911 7.78402 8.33049 7.3054C7.90186 7.78402 7.27798 8.09358 6.57313 8.09358C5.87067 8.09358 5.2444 7.78402 4.81578 7.3054C4.38716 7.78402 3.76328 8.09358 3.05843 8.09358C2.94889 8.09358 2.83936 8.08644 2.7322 8.07215C1.24155 7.86975 0.534319 6.09811 1.33203 4.82415L2.87746 2.35719C3.01557 2.13573 3.26083 2 3.52277 2H13.143C13.4049 2 13.6502 2.13573 13.7883 2.35719ZM12.9048 8.76747C13.1334 8.82224 13.3692 8.85796 13.6073 8.85796C13.7526 8.85796 13.8954 8.84844 14.0407 8.82939C14.174 8.81272 14.3026 8.78176 14.4312 8.74366V13.4299C14.4312 13.8514 14.0907 14.1919 13.6692 14.1919H3.00128C2.5798 14.1919 2.23928 13.8514 2.23928 13.4299V8.74366C2.36549 8.77938 2.49646 8.81034 2.62981 8.82939C2.7703 8.84844 2.91555 8.85796 3.05843 8.85796C3.29655 8.85796 3.5323 8.81986 3.76089 8.76747V11.1439H12.9048V8.76747Z"
      fill={fillColor}
    />
    <mask
      id="mask0_134_407"
      maskUnits="userSpaceOnUse"
      x="1"
      y="2"
      width="15"
      height="13"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7883 2.35719L15.3337 4.82415C16.1338 6.10049 15.4242 7.87213 13.9288 8.07215C13.8216 8.08644 13.7145 8.09358 13.6025 8.09358C12.9001 8.09358 12.2762 7.78402 11.8452 7.3054C11.4166 7.78402 10.7927 8.09358 10.0878 8.09358C9.38537 8.09358 8.75911 7.78402 8.33049 7.3054C7.90186 7.78402 7.27798 8.09358 6.57313 8.09358C5.87067 8.09358 5.2444 7.78402 4.81578 7.3054C4.38716 7.78402 3.76328 8.09358 3.05843 8.09358C2.94889 8.09358 2.83936 8.08644 2.7322 8.07215C1.24155 7.86975 0.534319 6.09811 1.33203 4.82415L2.87746 2.35719C3.01557 2.13573 3.26083 2 3.52277 2H13.143C13.4049 2 13.6502 2.13573 13.7883 2.35719ZM12.9048 8.76747C13.1334 8.82224 13.3692 8.85796 13.6073 8.85796C13.7526 8.85796 13.8954 8.84844 14.0407 8.82939C14.174 8.81272 14.3026 8.78176 14.4312 8.74366V13.4299C14.4312 13.8514 14.0907 14.1919 13.6692 14.1919H3.00128C2.5798 14.1919 2.23928 13.8514 2.23928 13.4299V8.74366C2.36549 8.77938 2.49646 8.81034 2.62981 8.82939C2.7703 8.84844 2.91555 8.85796 3.05843 8.85796C3.29655 8.85796 3.5323 8.81986 3.76089 8.76747V11.1439H12.9048V8.76747Z"
        fill={fillColor}
      />
    </mask>
    <g mask="url(#mask0_134_407)">
      <rect
        x="-6.33331"
        y="-6.66669"
        width="29.3333"
        height="29.3333"
        fill={fillColor}
      />
    </g>
  </svg>
);

export { RetailLogoIcon };
