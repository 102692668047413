import React, { FC } from 'react';
import { useAppMediaQuery } from '@tapestry/shared/hooks';
import { CloseIcon, SlidersIcon } from '@tapestry/shared/icons';
import { Circle, Pill } from '@tapestry/weave';
import clx from 'classnames';

interface IAppletFilterButtonProps {
  activeFiltersCount: number;
  handleFiltersReset: () => void;
  handleOpenModal: () => void;
  disabled?: boolean;
}

export const AppletFilterButton: FC<IAppletFilterButtonProps> = ({
  activeFiltersCount,
  handleOpenModal,
  handleFiltersReset,
  disabled = false,
}) => {
  const { isPhone } = useAppMediaQuery();
  return (
    <button
      title="open filters menu"
      onClick={handleOpenModal}
      onKeyDown={({ key }) => {
        if (key === 'Enter') {
          handleOpenModal();
        }
      }}
      disabled={disabled}
      className="group focus:outline-none"
    >
      <span className="sr-only">Open filters menu</span>

      <Pill
        iconLeft={<SlidersIcon fillColor={disabled ? '#eee' : '#000'} />}
        label={isPhone ? '' : 'Filters'}
        size="xxl"
        textStyle="capitalize"
        bgColor="bg-white"
        className={clx(
          'border-gray-border border text-black transition-colors duration-150 group-hover:border-orange group-focus:border-orange ',
          disabled ? 'text-gray-disabled' : 'text-black'
        )}
        iconRight={
          activeFiltersCount ? (
            <div className="flex items-center space-x-2">
              <Circle size="h-6 w-6" className="bg-red font-normal text-white">
                {activeFiltersCount}
              </Circle>

              <div
                role="button"
                tabIndex={0}
                aria-label="reset"
                className="hover:border-orange ml-4 flex h-6 w-6 items-center justify-center rounded-full border border-transparent"
                onClick={(e) => {
                  e.stopPropagation();
                  handleFiltersReset();
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.stopPropagation();
                    handleFiltersReset();
                  }
                }}
              >
                <Circle size="h-4 w-4" className="bg-black text-white">
                  <CloseIcon className="h-2 w-2" fillColor="currentColor" />
                </Circle>
              </div>
            </div>
          ) : null
        }
      />
    </button>
  );
};
