import * as React from 'react';
import { useRadioGroup } from '@react-aria/radio';
import { useRadioGroupState, RadioGroupState } from '@react-stately/radio';
import { RadioGroupProps, AriaRadioGroupProps } from '@react-types/radio';
import clx from 'classnames';
import { RadioItem } from './RadioItem';
import { Transition } from '@headlessui/react';

export const RadioContext = React.createContext<RadioGroupState>({
  name: '',
  selectedValue: '',
  setSelectedValue: () => null,
  lastFocusedValue: '',
  setLastFocusedValue: () => null,
  isDisabled: false,
  isReadOnly: false,
  validationState: 'valid',
});

interface IRadioGroupProps extends RadioGroupProps, AriaRadioGroupProps {
  label?: string;
  value?: string;
  radioPosition?: 'left' | 'right';
  onChange: (value: string | undefined) => void;
  radioItems: Array<{
    value: string;
    label: string;
  }>;
  itemsPositioning?: 'inline' | 'block';
  classes?: {
    root?: string;
    listContainer?: string;
    input?: {
      root?: string;
      inpot?: string;
    };
  };
  // ! Currently you need to double click it to make it work, @optimistic-updt needs to talk to @maxzitron
  resettable?: boolean;
}

const RadioGroup = React.forwardRef<HTMLDivElement, IRadioGroupProps>(
  (props, ref) => {
    const {
      label,
      radioItems,
      radioPosition,
      classes,
      itemsPositioning = 'block',
      resettable = false,
    } = props;

    const state = useRadioGroupState(props);
    const { radioGroupProps, labelProps } = useRadioGroup(props, state);
    const [triggerRender, setTriggerRender] = React.useState(false);

    const handleResetRadio = () => {
      state.setSelectedValue(undefined as any);
      state.setLastFocusedValue(null as any);
      setTriggerRender(!triggerRender);
    };

    return (
      <div {...radioGroupProps} className={clx(classes?.root)} ref={ref}>
        {label ? <span {...labelProps}>{label}</span> : null}

        <RadioContext.Provider value={state}>
          <div
            className={clx(
              itemsPositioning === 'inline' ? 'flex flex-row items-center' : '',
              classes?.listContainer
            )}
          >
            {radioItems.map(({ value, label }) => (
              <RadioItem
                key={value}
                value={value}
                radioPosition={radioPosition}
                classes={classes?.input}
              >
                {label}
              </RadioItem>
            ))}

            <Transition
              show={
                resettable &&
                state.selectedValue !== undefined &&
                state.selectedValue !== null
              }
              enter="transition-opacity duration-100"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <button
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleResetRadio();
                }}
                onKeyDown={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (e.key === 'Enter') {
                    handleResetRadio();
                  }
                }}
                className="text-gray-text hover:focus:text-blue text-sm font-medium transition-colors duration-150"
                tabIndex={-1}
              >
                Double-click to clear
              </button>
            </Transition>
          </div>
        </RadioContext.Provider>
      </div>
    );
  }
);

export { RadioGroup };
