import { Transition } from '@headlessui/react';
import { CloseIcon } from '@tapestry/shared/icons';
import React, { FunctionComponent } from 'react';

interface IModalPanelProps {
  className?: string;
  show: boolean;
  hide: () => void;
  isLoading?: boolean;
}

const ModalPanel: FunctionComponent<
  React.PropsWithChildren<IModalPanelProps>
> = ({ children, className, show, hide, isLoading = false }) => {
  return (
    <Transition
      show={show}
      enter="transition ease-out duration-300"
      enterFrom="opacity-0 -translate-y-32"
      enterTo="opacity-100 -translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 -translate-y-0"
      leaveTo="opacity-0 -translate-y-32"
      className={`mx-auto max-w-screen-lg px-4  ${className}`}
    >
      <div className="w-full rounded-lg bg-white p-4">
        <div className="text-right">
          <button
            title="Close modal"
            aria-label="Close modal"
            className="hover:bg-gray-hover focus:bg-gray-hover h-4 rounded-md disabled:cursor-wait"
            onClick={hide}
            disabled={isLoading}
          >
            <CloseIcon light />
          </button>
        </div>
        <div className="px-4 text-center">{children}</div>
      </div>
    </Transition>
  );
};

export { ModalPanel };
