import React, { useCallback, useContext } from 'react';
import { IToastNotification } from '@tapestry/types';
import { ToastContext } from './toast-context';
import uuid from 'react-uuid';

export const useToast = (config?: IToastNotification) => {
  const toastContext = useContext(ToastContext);

  if (!toastContext) {
    throw new Error('useToast must be use inside a ToastProvider');
  }

  const [toastList, setToastList] = toastContext;

  /**
   * Methods
   */

  const addToast = useCallback((toast: IToastNotification) => {
    // take the toast and adds an id
    const identifiedToast: IToastNotification = {
      id: uuid(),
      ...toast,
    };

    setToastList((prevList) => [...prevList, identifiedToast]);
  }, []);

  const triggerToast = () => {
    if (!config) {
      throw new Error('No Toast provided at the hook initiation');
    }

    const identifiedToast: IToastNotification = {
      id: uuid(),
      ...config,
    };
    setToastList((prevList) => [...prevList, identifiedToast]);
  };

  // NOTE: As more methods here as we need them...
  // ...
  return { addToast, triggerToast, toastStack: toastList };
};
