import React, {
  Dispatch,
  createContext,
  useContext,
  useReducer,
  useEffect,
  FC,
  ReactNode,
} from 'react';
import {
  IsoString,
  Nullable,
  Env,
  TasksAppletFilters,
  ProductAppletFilters,
  SuppliersAppletFilters,
  DepartmentsAppletFilters,
  CollectionsAppletFilters,
  SpacesAppletFilters,
  CategoriesAppletFilters,
} from '@tapestry/types';
import { hasPermissions } from '@tapestry/shared/utils';
import { HeartbeatChartPeriod } from '@tapestry/shared/constants';

export type UserContextState = {
  agendaAppletFilters: TasksAppletFilters | null;
  productAppletFilters: ProductAppletFilters | null;
  suppliersAppletFilters: Nullable<SuppliersAppletFilters>;
  DepartmentsAppletFilters: Nullable<DepartmentsAppletFilters>;
  collectionsAppletFilter: Nullable<CollectionsAppletFilters>;
  spacesAppletFilters: Nullable<SpacesAppletFilters>;
  categoriesAppletFilters: Nullable<CategoriesAppletFilters>;
  heartbeatUserDefinedDateRange: Nullable<{
    startDate: IsoString;
    endDate: IsoString;
    dateOffset: number;
    duration: HeartbeatChartPeriod;
  }>;
};

export type UserContextAction =
  | { type: 'UPDATE_TASK_FILTERS'; payload: TasksAppletFilters | null }
  | { type: 'UPDATE_PRODUCT_FILTERS'; payload: ProductAppletFilters | null }
  | {
      type: 'UPDATE_SUPPLIERS_FILTERS';
      payload: Nullable<SuppliersAppletFilters>;
    }
  | {
      type: 'UPDATE_CATEGORIES_FILTERS';
      payload: Nullable<CategoriesAppletFilters>;
    }
  | {
      type: 'UPDATE_SPACES_FILTERS';
      payload: Nullable<SpacesAppletFilters>;
    }
  | {
      type: 'SET_HEARTBEAT_USER_DEFINED_DATE_RANGE';
      payload: null | {
        startDate: IsoString | string;
        endDate: IsoString | string;
        dateOffset: number;
      };
    };

export type UserContextValue = [UserContextState, Dispatch<UserContextAction>];

const initialState: UserContextState = {
  agendaAppletFilters: null,
  productAppletFilters: null,
  suppliersAppletFilters: null,
  DepartmentsAppletFilters: null,
  collectionsAppletFilter: null,
  spacesAppletFilters: null,
  categoriesAppletFilters: null,
  heartbeatUserDefinedDateRange: null,
};

const UserContext = createContext<UserContextValue | undefined>(undefined);

const userContextReducer = (
  state: UserContextState | any,
  { type, payload }: UserContextAction
) => {
  switch (type) {
    case 'UPDATE_TASK_FILTERS':
      return { ...state, agendaAppletFilters: payload };

    case 'UPDATE_PRODUCT_FILTERS':
      return { ...state, productAppletFilters: payload };

    case 'UPDATE_SUPPLIERS_FILTERS':
      return { ...state, suppliersAppletFilters: payload };

    case 'UPDATE_SPACES_FILTERS':
      return { ...state, spacesAppletFilters: payload };

    case 'UPDATE_CATEGORIES_FILTERS':
      return { ...state, categoriesAppletFilters: payload };

    case 'SET_HEARTBEAT_USER_DEFINED_DATE_RANGE':
      return { ...state, heartbeatUserDefinedDateRange: payload };

    default:
      return state;
  }
};

export const UserContextProvider: FC<
  React.PropsWithChildren<{ children: ReactNode }>
> = ({ children }) => {
  const [userState, dispatch] = useReducer(userContextReducer, initialState);
  const value: UserContextValue = [
    userState,
    dispatch as Dispatch<UserContextAction>,
  ];

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = (): UserContextValue => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error('useUserContext must be inside UserContextProvider');
  }

  return context;
};
