import Image from 'next/image';
import * as React from 'react';

interface IMessageImageProps {
  title: string;
  imageUrl: string;
}

const MessageImage: React.FC<React.PropsWithChildren<IMessageImageProps>> = ({
  children,
  title,
  imageUrl,
}) => (
  <div className="w-full overflow-hidden rounded-md bg-gray-100">
    {children}
    <Image alt={title} className="w-full" src={imageUrl} />
  </div>
);

export { MessageImage };
