import React, { FC } from 'react';
import { OverlayProvider } from '@react-aria/overlays';
import { UserContextProvider } from '../context/user-context';
import { ToastProvider } from '../context/toast-context';
import { UIContextProvider } from '../context/ui-context';
import { QueryParamProvider } from 'use-query-params';
import queryString from 'query-string';
import { LocationContextProvider } from '../context/location-context';
import 'react-loading-skeleton/dist/skeleton.css';
import { MockedProvider, MockedProviderProps } from '@apollo/client/testing';
import { NextAdapter } from 'next-query-params';

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    Cypress: any;
    dayjs: any; //dayjs;
  }
}

// NOTE: The below is used for our testing environment
// TODO probably rework this
interface IMockedTapestryClientProps {
  apolloProviderProps?: MockedProviderProps;
}

export const MockedTapestryClient: FC<
  React.PropsWithChildren<IMockedTapestryClientProps>
> = ({ children, apolloProviderProps }) => {
  return (
    <MockedProvider {...apolloProviderProps}>
      <LocationContextProvider>
        <QueryParamProvider
          adapter={NextAdapter}
          options={{
            searchStringToObject: queryString.parse,
            objectToSearchString: queryString.stringify,
          }}
        >
          <UIContextProvider>
            <ToastProvider>
              <UserContextProvider>
                <OverlayProvider>{children}</OverlayProvider>
              </UserContextProvider>
            </ToastProvider>
          </UIContextProvider>
        </QueryParamProvider>
      </LocationContextProvider>
      {/* </ErrorView> */}
    </MockedProvider>
  );
};
