import {
  IsoString,
  MeasureSlug,
  MEASURE_SLUGS,
  THREAD_TYPE,
  THREAD_TYPES,
} from '@tapestry/types';
import { encodeString, decodeString } from 'use-query-params';

/**
 * We are using `use-query-params` libray
 * @see https://github.com/pbeshai/use-query-params
 *
 * This module adds more utilities to the base library for our use-cases
 */

//
// Encoding / Decoding function
//
/**
 * use to encode/decode typescript enums
 */
export function decodeEnum<T extends string>(
  input: string | (string | null)[] | null | undefined,
  enumValues: T[]
): T | null | undefined {
  const str = decodeString(input);

  if (str == null) {
    return null;
  }

  return enumValues.includes(str as any) ? (str as T) : undefined;
}

//
// Custom params
// enables us to receive the correct TS type
//
export const ThreadTypeParam = {
  encode: (threadType: THREAD_TYPE | null | undefined) =>
    encodeString(threadType),

  decode: (encodedString: string | (string | null)[] | null | undefined) =>
    decodeEnum<THREAD_TYPE>(encodedString, THREAD_TYPES),
};

export const MeasureParam = {
  encode: (measureSlug: MeasureSlug | null | undefined) =>
    encodeString(measureSlug),

  decode: (encodedString: string | (string | null)[] | null | undefined) =>
    decodeEnum<MeasureSlug>(encodedString, MEASURE_SLUGS),
};

export const IsostringParam = {
  encode: (isoString: IsoString | null | undefined) => encodeString(isoString),

  decode: (encodedString: string | (string | null)[] | null | undefined) =>
    decodeString(encodedString),
};

// re-exports the whole library
export * from 'use-query-params';
