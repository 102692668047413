import {
  IToastNotification,
  ToastNotificationType,
  ToastNotificationTypeOption,
} from '@tapestry/types';
import { dateTime, isISOString } from '@tapestry/shared/utils';
import {
  BellIcon,
  CalendarIcon,
  TasksIcon,
  TickIcon,
  ExclamationIcon,
  InfoIcon,
} from '@tapestry/shared/icons';

export const getText = (
  type: ToastNotificationTypeOption,
  content: IToastNotification['content']
) => {
  switch (type) {
    case ToastNotificationType.TaskCreated:
      return {
        title: 'Task successfully created',
        textContent: content || 'Just now',
      };

    case ToastNotificationType.TaskCompleted:
      return {
        title: 'Mark as completed',
        textContent: content,
      };
    case ToastNotificationType.taskDeleted:
      return {
        title: 'Task successfully deleted',
        textContent: content,
      };

    case ToastNotificationType.TaskOverdue:
      return {
        title: 'Task overdue',
        textContent: content,
      };

    case ToastNotificationType.Reminder:
      return {
        title: 'Reminder set',
        textContent:
          content && isISOString(content) ? dateTime.format(content) : '',
      };

    case ToastNotificationType.Assigned:
      return {
        title: 'Task assigned',
        textContent: `Assigned to ${content || 'user'}`,
      };

    case ToastNotificationType.Info:
      return {
        title: 'Did you know',
        textContent: content,
      };

    case ToastNotificationType.Success:
      return {
        title: 'Success',
        textContent: content || 'An action is successfully',
      };

    case ToastNotificationType.Error:
      return {
        title: 'Something went wrong',
        textContent: content || 'An error has occured',
      };

    default:
      return {};
  }
};

export const getIcon = (type: ToastNotificationTypeOption) => {
  const iconMapper = {
    [ToastNotificationType.TaskCreated]: TasksIcon,
    [ToastNotificationType.TaskCompleted]: TickIcon,
    [ToastNotificationType.taskDeleted]: TickIcon,
    [ToastNotificationType.TaskOverdue]: CalendarIcon,
    [ToastNotificationType.Reminder]: BellIcon,
    [ToastNotificationType.Assigned]: TasksIcon,
    [ToastNotificationType.Error]: ExclamationIcon,
    [ToastNotificationType.Info]: InfoIcon,
    [ToastNotificationType.Success]: TickIcon,
  };
  return iconMapper[type];
};

export const getIconBackgroundColor = (type: ToastNotificationTypeOption) => {
  const colorMapper = {
    [ToastNotificationType.TaskCreated]: 'bg-pink',
    [ToastNotificationType.TaskCompleted]: 'bg-green',
    [ToastNotificationType.taskDeleted]: 'bg-green',
    [ToastNotificationType.TaskOverdue]: 'bg-red',
    [ToastNotificationType.Reminder]: 'bg-pink',
    [ToastNotificationType.Assigned]: 'bg-pink',
    [ToastNotificationType.Error]: 'bg-red',
    [ToastNotificationType.Info]: 'bg-gray-900',
    [ToastNotificationType.Success]: 'bg-green',
  };
  return colorMapper[type];
};
