import {
  SupplyLogoIcon,
  RetailLogoIcon,
  CollectiveLogoIcon,
  ShoppingBagIcon,
} from '@tapestry/shared/icons';
import { TAppName, ITapestryAppConfigurations } from '@tapestry/types';
import { ROUTE_PATHS } from './routes';

/**
 * Really it's 84px on small and 88px on large
 */
export const NAVBAR_HEIGHT = 72;

export const TAPESTRY_APP_CONFIGS: {
  [index in TAppName]: ITapestryAppConfigurations;
} = {
  retail: {
    domains: {
      development: 'localhost:4200',
      staging: 'staging.retail.tapestry.dev',
      production: 'retail.tapestry.ai',
      'pre-release': 'retail.tapestry.ai',
      test: 'staging.retail.tapestry.dev',
    },
    homePath: ROUTE_PATHS.heartbeat,
    slug: 'retail',
    LDAppKey: 'retail-1', //DO NOT MODIFY
    title: 'Retail+',
    icon: RetailLogoIcon,
    color: '#ED7725',
    order: 1,
  },
  supply: {
    domains: {
      development: 'localhost:4201',
      staging: 'staging.supply.tapestry.dev',
      production: 'supply.tapestry.ai',
      'pre-release': 'supply.tapestry.ai',
      test: 'supply.tapestry.ai',
    },
    homePath: ROUTE_PATHS.heartbeat,
    slug: 'supply',
    LDAppKey: 'supply-2', //DO NOT MODIFY
    title: 'Supply+',
    icon: SupplyLogoIcon,
    color: 'rgb(14,115,187)',
    order: 2,
  },
  collective: {
    domains: {
      development: 'localhost:4202',
      staging: 'collective.tapestry.ai',
      production: 'collective.tapestry.ai',
      'pre-release': 'collective.tapestry.ai',
      test: 'collective.tapestry.ai',
    },
    homePath: ROUTE_PATHS.heartbeat,
    title: 'Collective',
    slug: 'collective',
    LDAppKey: 'collective-3', //DO NOT MODIFY
    icon: CollectiveLogoIcon,
    color: '#C33C8E',
    order: 3,
  },
  market: {
    domains: {
      development: 'localhost:4203',
      staging: 'market.tapestry.ai',
      production: 'market.tapestry.ai',
      'pre-release': 'market.tapestry.ai',
      test: 'market.tapestry.ai',
    },
    homePath: ROUTE_PATHS.homepage,
    title: 'Market',
    slug: 'market',
    LDAppKey: 'market-4', //DO NOT MODIFY
    icon: ShoppingBagIcon,
    color: '#A9B313',
    order: 3,
    hidden: true,
  },
};
