import { useMultipleSelection } from 'downshift';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { Pill } from '../Pill';
import { SelectBase } from './SelectBase';
import { defaultLabelAccessor } from './SelectUtils';
import {
  IMultiSelectProps,
  SelectOptionItem,
  SelectOptionsConfig,
} from './types';

export const MultiSelect = React.forwardRef<HTMLDivElement, IMultiSelectProps>(
  ({ onChange, defaultOptions, options, onBlur, ...props }, ref) => {
    const optionsConfig: SelectOptionsConfig = Array.isArray(options)
      ? { options }
      : options;
    const labelAccessor = optionsConfig.itemAccessor
      ? optionsConfig.itemAccessor
      : defaultLabelAccessor;

    const {
      selectedItems,
      getSelectedItemProps,
      getDropdownProps,
      removeSelectedItem,
      addSelectedItem,
    } = useMultipleSelection({
      initialSelectedItems: defaultOptions || [],
      onSelectedItemsChange({ selectedItems }) {
        onChange(selectedItems);
      },
    });

    /**
     * utils
     */
    const handleSelectedItemSelection = (
      selectedItem: SelectOptionItem | undefined | null
    ) => {
      if (!selectedItem) return;

      if (selectedItems.some((item) => item?.id === selectedItem?.id)) {
        removeSelectedItem(selectedItem);
      } else {
        addSelectedItem(selectedItem);
      }
    };

    const handleOnBlur = () => {
      if (onBlur) {
        onBlur(selectedItems);
      }
    };

    return (
      <div onBlur={handleOnBlur}>
        {/* select */}
        <SelectBase
          {...props}
          ref={ref}
          options={options}
          getDropdownProps={getDropdownProps}
          onChange={handleSelectedItemSelection}
          multiSelectSelectedItems={selectedItems}
        />

        {/* list */}
        {!isEmpty(selectedItems) ? (
          <div className="mt-4 flex flex-wrap items-center space-x-4">
            {selectedItems.map((selectedItemToRender, index) => {
              return (
                <span
                  {...getSelectedItemProps({
                    selectedItem: selectedItemToRender,
                    index,
                  })}
                  key={selectedItemToRender.id}
                >
                  <Pill
                    label={labelAccessor(selectedItemToRender)}
                    onClear={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      removeSelectedItem(selectedItemToRender);
                    }}
                  />
                </span>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  }
);
