import { useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import { transformStringShortcutToMap, getEventTouchedKeys } from './utils';

export type KeyboardShortcutHandlers = {
  [k: string]: VoidFunction;
};

/**
 * useKeyboardShortcut
 *
 * A hook to easily register keyboard shortcut and their associated handlers
 *
 * Provide a object where `key` is the shortcut and `value` is the handler function
 *
 * @example
 *  {
 *   'meta k': doThis(),
 *   'e': doThat()
 *  }
 */
export function useKeyboardShortcut(handlers: KeyboardShortcutHandlers): void {
  const allShortcutsAsStrings = Object.keys(handlers);
  const allShortcutMap = allShortcutsAsStrings.map((shortcut) => {
    return transformStringShortcutToMap(shortcut);
  });

  function handleShortcut(ev: KeyboardEvent) {
    const eventTouchedKeys = getEventTouchedKeys(ev);

    const matchingShortcut = allShortcutMap.find((shortcut) => {
      const { id, ...shorcutKeys } = shortcut;
      return isEqual(shorcutKeys, eventTouchedKeys) ? shortcut : false;
    });

    if (matchingShortcut) {
      const correspondingCallback = handlers[matchingShortcut.id];
      if (typeof correspondingCallback === 'function') {
        ev.preventDefault();
        correspondingCallback();
      } else {
        console.error(
          `You haven't provided a callback for the shortcut: ${matchingShortcut.id}`
        );
      }
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleShortcut);
    return () => window.removeEventListener('keydown', handleShortcut);
  }, []);
}
