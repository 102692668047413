import * as React from 'react';
import { useRadio } from '@react-aria/radio';
import { RadioContext } from './RadioGroup';
import { AriaRadioProps } from '@react-types/radio';
import clx from 'classnames';

interface IRadioProps extends AriaRadioProps {
  radioPosition?: 'left' | 'right';
  classes?: {
    root?: string;
    input?: string;
  };
}

const RadioItem: React.FC<React.PropsWithChildren<IRadioProps>> = (props) => {
  const { children, radioPosition = 'left', classes } = props;
  const state = React.useContext(RadioContext);
  const ref = React.useRef<HTMLInputElement | null>(null);
  const { inputProps } = useRadio(props, state, ref);

  return (
    <label
      className={clx(
        'flex items-center',
        classes?.root || '  w-full',
        radioPosition === 'left' ? 'flex-row' : 'flex-row-reverse'
      )}
    >
      <input
        {...inputProps}
        ref={ref}
        className={clx(classes?.input, {
          'mr-2': radioPosition === 'left',
          'ml-2': radioPosition === 'right',
        })}
      />
      {children}
    </label>
  );
};

export { RadioItem };
