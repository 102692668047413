import { MinusIcon, PlusIcon } from '@tapestry/shared/icons';
import React, { VFC } from 'react';
import { ListItem } from '../ListItem';

interface ISelectDefaultDropDownListItemProps {
  item;
  isHighlighted?: boolean;
  isChecked?: boolean;
  labelAccessor: (item) => string;
}

export const SelectDefaultDropDownListItem: VFC<
  ISelectDefaultDropDownListItemProps
> = ({ item, isHighlighted, isChecked = false, labelAccessor }) => {
  return (
    <ListItem
      tag="div"
      bgColor="bg-gray-lightest"
      borderColor={isHighlighted ? 'border-blue' : 'border-gray-lightest'}
      justify="between"
      padding="md"
    >
      <div className="flex items-center overflow-x-hidden">
        {/* <span className="h-7 w-7 rounded-full">
            {renderAvatarOrPlaceholder(item, fullTwist?.avatarPlaceholder)}
          </span> */}
        <p className="ml-2 truncate font-semibold text-black">
          {labelAccessor(item)}
        </p>
      </div>

      <span
        title={`${isChecked ? 'Remove' : 'Add'} selection`}
        className="inline-flex h-7 w-7 items-center justify-center rounded-full bg-white p-1 sm:h-10 sm:w-10 sm:p-2"
      >
        {isChecked ? (
          <MinusIcon fillColor="#0E73BB" light />
        ) : (
          <PlusIcon fillColor="#0E73BB" light />
        )}
      </span>
    </ListItem>
  );
};
