import React, { FC } from 'react';
import { Divider } from '@tapestry/weave';
import { DropdownShopsList } from './NavbarShopSelectorDropdownShopList';
import {
  IShopSelectorGroupType,
  IThreadV4Type,
} from '@tapestry/shared/graphql';
import Link from 'next/link';
import { useRouter } from 'next/router';

interface IGroupSectionProps {
  idx: number;
  group: IShopSelectorGroupType;
  closeDropdownMenu: () => void;
  shopBySearchValue: (shop: IThreadV4Type) => boolean;
}

/**
 * a `NavbarShopSelector` group section displaying a group and it's shops
 */
export const GroupSection: FC<IGroupSectionProps> = ({
  idx,
  group,
  closeDropdownMenu,
  shopBySearchValue,
}) => {
  const {
    pathname,
    query: { shopId, ...queryWithoutShopId },
  } = useRouter();
  return (
    <>
      {idx > 0 && <Divider />}

      <div className="p-2">
        <h6 className="text-gray-text pl-2 text-xs font-normal capitalize">
          Group
        </h6>

        <Link
          href={{
            pathname,
            query: {
              ...queryWithoutShopId,
              groupId: group?.id,
            },
          }}
          onClick={closeDropdownMenu}
          onKeyDown={(e) => {
            if (e.key === 'Enter ') {
              closeDropdownMenu();
            }
          }}
          data-cy={`shop-selector-group-list-item-${group?.title}`}
          className="hover:bg-primary-lightest focus:bg-primary-lightest flex flex-row items-center rounded-md p-2 text-base font-bold transition-colors duration-150 ease-in-out focus:outline-none sm:border-b-0"
        >
          <span className="min-w-6 mr-4 flex h-6 w-6 items-center justify-center overflow-hidden rounded-full bg-gray-200">
            <img
              src={group?.logo || ''}
              alt="group's logo"
              className="bg-gray-400"
            />
          </span>

          <span title={group?.title} className="truncate">
            {group?.title}
          </span>
        </Link>

        <DropdownShopsList
          shopsList={group?.shops?.filter(shopBySearchValue)}
          closeDropdownMenu={closeDropdownMenu}
        />
      </div>
    </>
  );
};
