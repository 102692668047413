import { useState, useEffect, useRef, FC } from 'react';
import { Inbox } from '@poc/ui';
import {
  CogIcon,
  ShopIcon,
  TileMenuIcon,
  ChevronDownWithCircleIcon,
  QuestionMarkCircleIcon,
  InfoCircleIcon,
  PenOnPaperIcon,
  MessagesIcon,
  RepeteIcon,
} from '@tapestry/shared/icons';
import { Button, DropdownMenuItem, DropdownMenu } from '@tapestry/weave';
import { DevModeModal } from './DevModeModal';
import { NavbarSearchInput } from '../navbar-search/NavbarSearchInput';
import { useSlideOver, useUIContext } from '@tapestry/shared/client';
import { useAppMediaQuery, useDevMode } from '@tapestry/shared/hooks';
import { useOverlayTriggerState } from '@react-stately/overlays';
import { HANK_UNI_URLS, ROUTE_PATHS } from '@tapestry/shared/constants';
import { TapestryAppSwitcher } from '@tapestry/shared/app-switcher';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { AvatarLink } from './AvatarLink';

interface INavigationBarProps {
  noShadow?: boolean;
}

const NavigationBar: FC<INavigationBarProps> = ({ noShadow = false }) => {
  const { isPhone } = useAppMediaQuery();
  const navbarDropDownRef = useRef(null);
  const anchorElementRef = useRef<HTMLButtonElement | null>(null);
  const hasNotifications = false;
  const [UIState, dispatch] = useUIContext();
  const [settingIsOpen, setSettingIsOpen] = useState(
    UIState.shouldShowNavbarSettingMenu || false
  );
  const [isDevMode, setDevMode] = useDevMode();
  const DevModeModalState = useOverlayTriggerState({});
  const { pathname, push } = useRouter();
  const isOnMenuPage = pathname === ROUTE_PATHS.menu;

  const { heartbeatInbox: hasHeartbeatInboxFlag } = useFlags();
  const { open: openInbox, close: closeInbox } = useSlideOver(Inbox);

  const handleShouldShowOnboardingAgain = () => {
    dispatch({ type: 'SHOW_ONBOARDING_CAROUSEL' });
  };

  const handleToggleDevMode = () => {
    if (isDevMode) {
      localStorage.removeItem('shouldShowAnnouncement');
      dispatch({
        type: 'UPDATE_SHOULD_SHOW_ANNOUNCEMENT',
        payload: false,
      });

      return setDevMode(false);
    }

    return DevModeModalState.open();
  };

  const handleToggleShowAnnoucement = () => {
    const shouldShowAnnouncement = !UIState.shouldShowAnnouncement;

    dispatch({
      type: 'UPDATE_SHOULD_SHOW_ANNOUNCEMENT',
      payload: shouldShowAnnouncement,
    });
  };

  useEffect(() => {
    setSettingIsOpen(UIState.shouldShowNavbarSettingMenu);
  }, [UIState.shouldShowNavbarSettingMenu]);

  if (UIState.isMobileApp) {
    return null;
  }

  return (
    <nav
      className={`z-navbar sticky top-0 bg-white ${
        noShadow ? 'border-gray-border border-b' : 'shadow-md'
      }`}
    >
      <div className="relative">
        <div className="mx-auto max-w-7xl px-3 sm:px-6">
          <div className="relative flex items-center justify-between py-3 sm:py-4">
            <TapestryAppSwitcher />
            <NavbarSearchInput />

            <div className="flex items-center justify-between space-x-1 md:space-x-3">
              {hasHeartbeatInboxFlag ? (
                <button
                  className="hover:bg-gray-hover focus:bg-gray-hover relative hidden h-8 w-8 flex-shrink-0 items-center justify-center rounded-full p-1 text-black transition duration-150 ease-in-out focus:outline-none md:flex"
                  aria-label="Notifications"
                  onClick={() => {
                    if (!hasHeartbeatInboxFlag) return;
                    openInbox({ onClose: closeInbox });
                  }}
                >
                  <MessagesIcon />
                  {hasNotifications && (
                    <span className="shadow-solid absolute top-0 right-0 block h-2 w-2 rounded-full bg-red-400 text-white" />
                  )}
                </button>
              ) : null}

              <Link
                title="Go to menu"
                href={ROUTE_PATHS.menu}
                className={`${
                  isOnMenuPage
                    ? 'bg-blue hover:bg-blue-dark focus:bg-blue-dark'
                    : 'bg-white hover:bg-gray-300 focus:bg-gray-300'
                } hidden h-8 w-8 items-center justify-center rounded-full text-gray-400 transition  duration-150  ease-in-out focus:outline-none sm:inline-flex`}
                style={{ padding: '0.40rem' }}
                aria-label="Main menu"
              >
                <span className="sr-only">Go to menu</span>
                <TileMenuIcon fillColor={isOnMenuPage ? '#fff' : '#000'} />
              </Link>

              <div
                className="relative mr-3 flex-shrink-0"
                ref={navbarDropDownRef}
              >
                <button
                  title="Open setting menu"
                  className="hover:bg-gray-hover focus:bg-gray-hover relative flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full p-1 text-black transition duration-150 ease-in-out focus:outline-none"
                  aria-label="Setting menu"
                  aria-haspopup="true"
                  onClick={() => setSettingIsOpen(!settingIsOpen)}
                  ref={anchorElementRef}
                >
                  <p className="sr-only">Open setting menu</p>
                  <ChevronDownWithCircleIcon />
                </button>

                <DropdownMenu
                  show={settingIsOpen}
                  anchorElementRef={anchorElementRef}
                  clickOutsideRef={navbarDropDownRef}
                  onClickOutside={() => {
                    setSettingIsOpen(false);
                  }}
                  position="top-right"
                  menuClassName="hidden sm:block"
                >
                  <DropdownMenuItem
                    onClick={() => push(ROUTE_PATHS.homepage)}
                    icon={<ShopIcon />}
                    label="Switch store"
                    subLabel="Switch your current store"
                  />

                  <DropdownMenuItem
                    onClick={handleToggleDevMode}
                    icon={
                      <CogIcon
                        outline
                        fillColor={isDevMode ? '#F28A14' : '#000'}
                      />
                    }
                    label="Developer Mode"
                    subLabel="There is no Ctrl-Z in life."
                  />

                  {isDevMode ? (
                    <DropdownMenuItem
                      onClick={handleToggleShowAnnoucement}
                      icon={
                        <RepeteIcon
                          fillColor={
                            UIState.shouldShowAnnouncement ? '#F28A14' : '#000'
                          }
                        />
                      }
                      label="Show Announce Banner"
                      subLabel="Developer Eyes Only"
                    />
                  ) : null}

                  <DropdownMenuItem
                    onClick={handleShouldShowOnboardingAgain}
                    icon={<PenOnPaperIcon />}
                    label="How Retail+ works"
                    subLabel="Tell me how Retail+ works"
                  />

                  <DropdownMenuItem
                    asLink
                    url={HANK_UNI_URLS.homepage.homepage}
                    icon={<InfoCircleIcon />}
                    label="Learn more about Retail+"
                    subLabel="Go to Retail+ University."
                  />

                  <Button
                    onClick={() => {
                      push(ROUTE_PATHS.logout);
                    }}
                    status={'danger'}
                    fullWidth
                    spacing="small"
                  >
                    Logout
                  </Button>
                </DropdownMenu>
              </div>

              <AvatarLink />
            </div>
          </div>
        </div>

        {/* ! --------------------       MOBILE MENU  -------------------         */}
        {isPhone && (
          <DropdownMenu
            show={settingIsOpen}
            anchorElementRef={anchorElementRef}
            clickOutsideRef={navbarDropDownRef}
            onClickOutside={() => {
              setSettingIsOpen(false);
            }}
            position="top-right"
            fullWidth
            noTopMargin
          >
            <DropdownMenuItem
              onClick={() => push('/')}
              icon={<ShopIcon />}
              label="Switch store"
              subLabel="Switch your current store"
            />

            <DropdownMenuItem
              onClick={handleToggleDevMode}
              icon={
                <CogIcon outline fillColor={isDevMode ? '#F28A14' : '#000'} />
              }
              label="Developer Mode"
              subLabel="There is no Ctrl-Z in life."
            />

            <DropdownMenuItem
              onClick={handleShouldShowOnboardingAgain}
              icon={
                <svg viewBox="0 0 20 20" fill="currentColor">
                  <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                  <path
                    fillRule="evenodd"
                    d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                    clipRule="evenodd"
                  />
                </svg>
              }
              label="How Retail+ works"
              subLabel="Tell me how Retail+ works"
            />

            <DropdownMenuItem
              asLink
              url={HANK_UNI_URLS.homepage.homepage}
              icon={<QuestionMarkCircleIcon />}
              label="Learn more about Retail+"
              subLabel="Go to Retail+ University."
            />

            <Button
              onClick={() => {
                push('/logout');
              }}
              status={'danger'}
              fullWidth
              spacing="small"
            >
              Logout
            </Button>
          </DropdownMenu>
        )}
      </div>

      {DevModeModalState.isOpen && (
        <DevModeModal
          hide={() => DevModeModalState.close()}
          activateDevMode={() => setDevMode(true)}
        />
      )}
    </nav>
  );
};

export { NavigationBar };
