import React, { useRef, FC, useEffect, useState } from 'react';
import clx from 'classnames';
import { SearchIcon } from '@tapestry/shared/icons';
import { useRouter } from 'next/router';
import { ROUTE_PATHS, SHORTCUT } from '@tapestry/shared/constants';
import { useLocationContext } from '@tapestry/shared/client';
import { LocationPathname } from '@tapestry/types';
import { useAppMediaQuery, useKeyboardShortcut } from '@tapestry/shared/hooks';
import { mapAppletRoutesToThreadType } from '@tapestry/shared/utils';
import {
  getSearchPlaceholder,
  LETTERS_AND_NUMBERS_KEYS,
} from './NavbarSearchUtils';
import { NavBarShopSelector } from './NavbarShopSelector';

interface INavbarSearchInputProps {
  label?: string;
  placeholder?: string;
}

export const NavbarSearchInput: FC<
  React.PropsWithChildren<INavbarSearchInputProps>
> = ({ placeholder = 'Search...' }) => {
  /**
   * Hooks
   */
  const { pathname, push, query } = useRouter();
  const onSearchPage = pathname === ROUTE_PATHS.search;
  const appMediaQueries = useAppMediaQuery();
  const { isSmallComputerAndUp } = appMediaQueries;
  const NavbarSearchInputRef = useRef<HTMLInputElement>(null);
  const [{ lastLocationBeforeSearch }, dispatchToLocationContext] =
    useLocationContext();
  const [inputValue, setInputValue] = useState(query.searchTerm || '');
  const [showMobileInput, setShowMobileInput] = useState(false);
  const [inputWidth, setInputWidth] = useState<'w-[139px]' | 'w-full'>(
    'w-[139px]'
  );

  /**
   * Functions
   */
  const currentAppletRelatedThreadObj = mapAppletRoutesToThreadType(
    pathname as LocationPathname
  );

  const dynamicInputPlaceholder = getSearchPlaceholder(
    currentAppletRelatedThreadObj?.label,
    appMediaQueries
  );

  const handleOnChange = (e) => {
    setInputValue(e.currentTarget.value);

    // if the input is empty and there is a last location before search, it redirects to the last location
    const hasClearedInputOnSearchPage = onSearchPage && !e.currentTarget.value;

    if (hasClearedInputOnSearchPage && lastLocationBeforeSearch) {
      push(lastLocationBeforeSearch);
    } else if (!onSearchPage) {
      // if not on the search page, redirects to it with the correct thread type assigned
      push({
        pathname: ROUTE_PATHS.search,
        query: {
          ...query,
          activeThreadType: currentAppletRelatedThreadObj?.type,
        },
      });
    }
  };

  const handleOnFocusRecordLastLocationBeforeSearchToGoBackTo = () => {
    if (onSearchPage) return;

    dispatchToLocationContext({
      type: 'UPDATE_LAST_LOCATION_BEFORE_SEARCH',
      payload: pathname,
    });
  };

  const focusSearchInput = () => {
    if (NavbarSearchInputRef?.current) {
      NavbarSearchInputRef.current.focus();
    }
  };

  /**
   * useEffect
   */
  useKeyboardShortcut({ [SHORTCUT.APP.FOCUS_SEARCH]: focusSearchInput });

  useEffect(
    function setCorrectWidthOnFirstBrowserLoad() {
      const correctInputWidth = isSmallComputerAndUp ? 'w-full' : 'w-[139px]';
      if (correctInputWidth !== inputWidth) {
        setInputWidth(correctInputWidth);
      }
    },
    [isSmallComputerAndUp]
  );

  useEffect(() => {
    const handleFocusNavbarInput = (e: globalThis.KeyboardEvent) => {
      if (LETTERS_AND_NUMBERS_KEYS.includes(e.key)) {
        focusSearchInput();
      }
    };

    if (pathname === ROUTE_PATHS.menu) {
      document.addEventListener('keydown', handleFocusNavbarInput);
    }

    return () => {
      document.removeEventListener('keydown', handleFocusNavbarInput);
    };
  }, [pathname]);

  // * Keeps the focus on the search input even if the component re-renders (i.e changed page)
  useEffect(() => {
    if (NavbarSearchInputRef.current && inputValue) {
      NavbarSearchInputRef.current.focus();
    }
  }, [NavbarSearchInputRef, inputValue]);

  useEffect(
    // * Trying to use the URL query parameter directly as state was creating an issue where trying to type or delete in the middle of the string would make the cursor jump to the end because NextJS would do a page load.
    // * I tried by using `{shallow: true}` routing, I tried using the `next-usequerystate` library, nothing would work suprisingly.
    // * This is currently the only method I could find that showed result
    function updateURLWithInputValue() {
      if (!inputValue && !onSearchPage) return;

      push(
        {
          pathname: ROUTE_PATHS.search,
          query: {
            ...query,
            searchTerm: inputValue,
          },
        },
        undefined,
        { shallow: true }
      );
    },
    [inputValue]
  );

  return (
    <div className="max-w-48 focus-within:border-primary md:min-w-72 lg:min-w-xl overflow-x-hidden rounded-full border-2 border-transparent bg-gray-100 sm:absolute sm:left-1/2 sm:max-w-xl sm:-translate-x-1/2">
      <div
        className="flex items-center justify-start duration-500 ease-in-out"
        style={{
          transform: showMobileInput ? 'translateX(-137px)' : 'translateX(0px)',
        }}
      >
        <NavBarShopSelector />

        <div className="border-primary-lighter h-6 w-px border-r" />

        {/* mobile */}
        <button
          onClick={() => setShowMobileInput(!showMobileInput)}
          title="Open search"
          className="p-4 pl-3 sm:hidden"
        >
          <p className="sr-only">Open search</p>
          <SearchIcon
            className="text-primary h-6 w-6"
            fillColor="currentColor"
          />
        </button>
        {/*  */}

        <div className="sm:relative sm:flex-grow">
          <label htmlFor="search-input" className="sr-only">
            search input
          </label>
          <div className="pointer-events-none inset-y-0 left-0 hidden items-center pl-3 sm:absolute sm:flex">
            <SearchIcon
              className="text-primary-light h-4 w-4"
              fillColor="currentColor"
            />
          </div>
          <input
            id="search-input"
            type="search"
            ref={NavbarSearchInputRef}
            placeholder={
              currentAppletRelatedThreadObj
                ? dynamicInputPlaceholder
                : placeholder
            }
            value={inputValue ?? ''}
            onChange={handleOnChange}
            onFocus={handleOnFocusRecordLastLocationBeforeSearchToGoBackTo}
            className={clx(
              `placeholder-primary-light block rounded-full bg-transparent py-2 pr-4 text-xs font-semibold leading-5 text-black transition duration-150 ease-in-out focus:outline-none disabled:cursor-not-allowed sm:pl-8 sm:text-sm`,
              inputWidth
            )}
          />

          {!inputValue && (
            <span className="border-primary-light text-primary-light inset-y-0 right-0 my-1 mr-4 hidden items-center rounded-md border px-2 text-sm sm:mr-5 md:absolute md:flex ">
              <span className="sr-only">Press </span>
              <kbd className="font-sans">
                <abbr title="Command" className="no-underline">
                  ⌘
                </abbr>
              </kbd>
              <span className="sr-only"> and </span>
              <kbd className="ml-1 font-sans">K</kbd>
              <span className="sr-only"> to search</span>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default NavbarSearchInput;
