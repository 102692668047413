import {
  HexCode,
  TailwindBackgroundColorString,
  TailwindBorderColorString,
  TailwindRingColorString,
  TailwindTextColorString,
  THREAD_TYPE,
} from '@tapestry/types';
import { getCurrentAppInfo } from '@tapestry/shared/utils';

export type ThreadTypeThemeColors = {
  backgroundColor: TailwindBackgroundColorString;
  backgroundColorHover?: TailwindBackgroundColorString;
  altBackgroundColor?: TailwindBackgroundColorString;
  textColor?: TailwindTextColorString;
  iconColor?: HexCode;
  iconBackgroundColor?: TailwindBackgroundColorString;
  textColorHover?: TailwindTextColorString;
  ringColor?: TailwindRingColorString;
  borderColor?: TailwindBorderColorString;
};

const {
  appInfo: { color },
} = getCurrentAppInfo();

export const ThreadTypeToThemeColorMapper: {
  [key in THREAD_TYPE]?: ThreadTypeThemeColors;
} = {
  [THREAD_TYPE.SHOP]: {
    backgroundColor: 'bg-primary',
    borderColor: 'border-primary',
    altBackgroundColor: 'bg-primary-light',
    backgroundColorHover: 'bg-primary',
    iconColor: color as HexCode,
    ringColor: 'ring-primary-light',
    textColor: 'text-primary',
  },
  [THREAD_TYPE.DEPARTMENT]: {
    backgroundColor: 'bg-pear',
    borderColor: 'border-pear',
    altBackgroundColor: 'bg-pear-dark',
    iconColor: '#A1AA1E',
    textColor: 'text-pear',
  },
  [THREAD_TYPE.SUPPLIER]: {
    backgroundColor: 'bg-blue',
    borderColor: 'border-blue',
    altBackgroundColor: 'bg-blue-dark',
    backgroundColorHover: 'bg-blue',
    iconColor: '#0E73BB',
    ringColor: 'ring-blue-light',
    textColor: 'text-blue',
  },
  [THREAD_TYPE.PRODUCT]: {
    backgroundColor: 'bg-purple',
    borderColor: 'border-purple',
    altBackgroundColor: 'bg-purple-dark',
    backgroundColorHover: 'bg-purple',
    iconColor: '#44337a',
    ringColor: 'ring-purple-light',
    textColor: 'text-purple',
  },
  [THREAD_TYPE.COLLECTION]: {
    backgroundColor: 'bg-purple',
    borderColor: 'border-purple',
    altBackgroundColor: 'bg-purple-dark',
    backgroundColorHover: 'bg-purple',
    ringColor: 'ring-purple-light',
    iconColor: '#44337a',
    textColor: 'text-purple',
  },
  [THREAD_TYPE.CATEGORY]: {
    backgroundColor: 'bg-purple',
    borderColor: 'border-purple',
    altBackgroundColor: 'bg-purple-dark',
    backgroundColorHover: 'bg-purple-dark',
    textColor: 'text-purple',
    textColorHover: 'text-purple-dark',
    ringColor: 'ring-purple-light',
    iconColor: '#44337a',
  },
  [THREAD_TYPE.SPACE]: {
    backgroundColor: 'bg-viridian',
    borderColor: 'border-viridian',
    altBackgroundColor: 'bg-viridian-dark',
    iconColor: '#05767F',
    textColor: 'text-viridian',
  },
  [THREAD_TYPE.DEMOGRAPHIC]: {
    backgroundColor: 'bg-cerulean',
    borderColor: 'border-cerulean',
    altBackgroundColor: 'bg-cerulean',
    iconColor: '#23B0E7',
    textColor: 'text-cerulean',
  },
  [THREAD_TYPE.POSTCODE]: {
    backgroundColor: 'bg-viridian',
    borderColor: 'border-viridian',
    altBackgroundColor: 'bg-viridian',
    iconColor: '#0795A0',
    textColor: 'text-viridian',
  },
  [THREAD_TYPE.STAFF]: {
    backgroundColor: 'bg-cerulean',
    borderColor: 'border-cerulean',
    altBackgroundColor: 'bg-cerulean-dark',
    iconColor: '#23B0E7',
    iconBackgroundColor: 'bg-cerulean-light',
    textColor: 'text-cerulean',
  },
};
