import React, { FunctionComponent } from 'react';

export const ChatIcon: FunctionComponent<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <svg
      width="60px"
      height="60px"
      viewBox="0 0 60 60"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <circle id="ChatIcon1" cx="30" cy="30" r="30"></circle>
        <path
          d="M27.125,0 L3.875,0 C1.73769531,0 0,1.73769531 0,3.875 L0,21.3125 C0,23.4498047 1.73769531,25.1875 3.875,25.1875 L9.6875,25.1875 L9.6875,30.2734375 C9.6875,30.8667969 10.365625,31.2119141 10.8439453,30.8607422 L18.40625,25.1875 L27.125,25.1875 C29.2623047,25.1875 31,23.4498047 31,21.3125 L31,3.875 C31,1.73769531 29.2623047,0 27.125,0 Z"
          id="ChatIcon3"
        ></path>
      </defs>
      <g
        id="app-icon-ChatIcon"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Components/icons/circle-coloured-Copy-14">
          <g id="Colours/Pear-#CAD526">
            <mask id="ChatIconMask-2" fill="white">
              <use xlinkHref="#ChatIcon1"></use>
            </mask>
            <circle stroke="#EEEEEE" cx="30" cy="30" r="29.5"></circle>
            <g id="GroupChatIcon" mask="url(#ChatIconMask-2)" fill="#0E73BB">
              <g id="ChatIconColours/Pear-#CAD526">
                <rect
                  id="RectangleChatIcon"
                  x="0"
                  y="0"
                  width="60"
                  height="60"
                ></rect>
              </g>
            </g>
          </g>
          <g id="GroupChatIcon" transform="translate(11.400000, 11.400000)">
            <g
              id="ChatIconColours/Black-#000000"
              transform="translate(3.100000, 3.100000)"
            >
              <mask id="ChatIconMask-4" fill="white">
                <use xlinkHref="#ChatIcon3"></use>
              </mask>
              <use
                id="ChatIconMask"
                fill="#000000"
                fillRule="nonzero"
                xlinkHref="#ChatIcon3"
              ></use>
              <g id="GroupChatIcon" mask="url(#ChatIconMask-4)">
                <g
                  transform="translate(-18.600000, -18.600000)"
                  id="ChatIconColours/Black-#000000"
                >
                  <rect
                    id="RectangleChatIcon"
                    fill="#FFFFFF"
                    x="0"
                    y="0"
                    width="68.2"
                    height="68.2"
                  ></rect>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ChatIcon;
