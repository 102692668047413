// This is the tapestry abstraction on date time
// it allows to create the api we want, a more declarative one
// also if we need to change the library in the future, it's easy

import { IsoString } from '@tapestry/types';
import dayjs, { OpUnitType } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import isoWeek from 'dayjs/plugin/isoWeek';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { APP_DEFAULT_TIMEZONE } from '@tapestry/shared/constants';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(isBetween);
dayjs.extend(isoWeek);
dayjs.extend(customParseFormat);

const setDefaultTimezone = (timezone = APP_DEFAULT_TIMEZONE) => {
  dayjs.tz.setDefault(timezone);
};

const parse = (
  date: string | Date | undefined,
  format?: dayjs.OptionType | undefined
) => {
  return dayjs(date, format);
};

const parseInUTC = (
  date: string | Date | undefined,
  format?: dayjs.OptionType | undefined
) => {
  return dayjs(date, format).utc();
};

/**
 * use to parse with timezone aware
 */
const parseInTimezone = (
  date: string | Date | undefined,
  timezone: string = APP_DEFAULT_TIMEZONE
) => {
  return dayjs.tz(date, timezone);
};

const convertToTimezone = (
  date: string | Date | undefined,
  timezone: string = APP_DEFAULT_TIMEZONE
) => {
  return dayjs(date).tz(timezone);
};

const getCurrentYear = (date: string, format?: string): string => {
  return dayjs(date).format(format || 'YYYY');
};

const getCurrentMonth = (date: string, format?: string): string => {
  return dayjs(date).format(format || 'MMM');
};

const getDay = (date: string): number => {
  return dayjs(date).date();
};

const getHour = (date: string, format?: string): string => {
  return dayjs(date).format(format || 'hh:mm a');
};

const format = (
  date: string | IsoString | Date,
  format = 'dddd, D MMMM YYYY'
) => {
  return dayjs(date).format(format);
};

const fromNow = (datetime: string | IsoString | undefined | null) => {
  if (!datetime || typeof datetime !== 'string') return null;
  return dayjs(datetime).fromNow();
};

const isBeforeNow = (datetime: string | IsoString | undefined | null) => {
  if (!datetime) return false;
  const now = dayjs();
  return dayjs(datetime).isBefore(now);
};

const isBefore = (
  datetime: string | IsoString,
  reference: string | IsoString
) => {
  return dayjs(datetime).isBefore(dayjs(reference));
};

const isAfterNow = (datetime: string | IsoString | undefined | null) => {
  if (!datetime) return false;
  const now = dayjs();
  return dayjs(datetime).isAfter(now);
};

const isAfter = (
  datetime: string | IsoString,
  reference: string | IsoString
) => {
  return dayjs(datetime).isAfter(dayjs(reference));
};

type Inclusivity = '[]' | '()' | '[)' | '(]' | undefined;
const checkIsBetween = (
  toCheck,
  start,
  end,
  limit: OpUnitType = 'day',
  // includes start and end date
  inclusivity: Inclusivity = '[]'
) => {
  return dayjs(toCheck).isBetween(start, end, limit, inclusivity);
};

const now = (utc = false) => {
  return utc ? dayjs().utc() : dayjs();
};

/**
 * provide the timezone of the dates provided for accurate reading
 */
const isSameDate = (
  startDate: string | IsoString | undefined | null,
  endDate: string | IsoString | undefined | null,
  datesTimezone?: string
) => {
  if (!startDate || !endDate) {
    return false;
  }

  const _startDate = datesTimezone
    ? dayjs(startDate).tz(datesTimezone)
    : dayjs(startDate);
  const _endDate = datesTimezone
    ? dayjs(endDate).tz(datesTimezone)
    : dayjs(endDate);

  return _startDate.startOf('date').isSame(_endDate.startOf('date'));
};

/**
 *
 */
const diff = (startDate: string, endDate: string, diffUnit: OpUnitType) => {
  if (!diffUnit) {
    throw new Error('datetime.diff(): diffUnit is required');
  }

  return dayjs(endDate).diff(dayjs(startDate), diffUnit);
};

/**
 *
 */
const trailingMonth = (timezone?: string) => {
  const tz = timezone || APP_DEFAULT_TIMEZONE;
  const endDate = dayjs().tz(tz).endOf('day').format();
  const startDate = dayjs(endDate).tz(tz).subtract(30, 'day').format();

  return {
    startDate,
    endDate,
  };
};

/**
 *
 */
const getCurrentIsoWeek = (timezone?: string) => {
  const _now = now().tz(timezone || APP_DEFAULT_TIMEZONE);
  const startDate = _now.startOf('isoWeek' as OpUnitType).format();
  const endDate = _now.endOf('isoWeek' as OpUnitType).format();

  return {
    startDate,
    endDate,
  };
};

const getUserTimezone = () => {
  return dayjs.tz.guess();
};

export const dateTime = {
  getCurrentMonth,
  getDay,
  getHour,
  format,
  fromNow,
  isBefore,
  isBeforeNow,
  isAfter,
  isAfterNow,
  now,
  checkIsBetween,
  parse,
  parseInTimezone,
  convertToTimezone,
  isSameDate,
  getCurrentYear,
  diff,
  trailingMonth,
  getCurrentIsoWeek,
  setDefaultTimezone,
  getUserTimezone,
  parseInUTC,
};

export const isISOString = (string: string | IsoString) => {
  if (!string) return false;
  return string.endsWith('Z');
};
