import {
  useCallback,
  useReducer,
  useRef,
  Dispatch,
  KeyboardEvent,
} from 'react';

export enum DefaultSortingKey {
  Az = 'a_z',
  Za = 'z_a',
  ValueAsc = 'value_asc',
  ValueDesc = 'value_desc',
}

export type UseSortMenuSortAction<type = DefaultSortingKey> = { type: type };
export type UseSortMenuOpenCloseAction =
  | { type: 'CLOSE_MENU' }
  | { type: 'TOGGLE_MENU' }
  | { type: 'UPDATE_SORT_ORDER'; payload: string };

type SortMenuState = {
  shouldOpenSortMenu: boolean;
  sortBy: any;
};

const initialState: SortMenuState = {
  shouldOpenSortMenu: false,
  sortBy: DefaultSortingKey.ValueDesc,
};

const getInitialState = (
  defaultItem: string = DefaultSortingKey.ValueDesc
): SortMenuState => ({
  shouldOpenSortMenu: false,
  sortBy: defaultItem,
});

const sortReducer = (
  state = initialState,
  action: UseSortMenuSortAction | UseSortMenuOpenCloseAction
): SortMenuState => {
  const { type } = action;
  switch (type) {
    case 'TOGGLE_MENU':
      return { ...state, shouldOpenSortMenu: !state.shouldOpenSortMenu };

    case 'CLOSE_MENU':
      return { ...state, shouldOpenSortMenu: false };

    case 'UPDATE_SORT_ORDER':
      return {
        ...state,
        shouldOpenSortMenu: false,
        sortBy: action.payload,
      };

    case DefaultSortingKey.Az:
      return {
        ...state,
        shouldOpenSortMenu: false,
        sortBy: DefaultSortingKey.Az,
      };

    case DefaultSortingKey.Za:
      return {
        ...state,
        shouldOpenSortMenu: false,
        sortBy: DefaultSortingKey.Za,
      };

    case DefaultSortingKey.ValueAsc:
      return {
        ...state,
        shouldOpenSortMenu: false,
        sortBy: DefaultSortingKey.ValueAsc,
      };

    case DefaultSortingKey.ValueDesc:
      return {
        ...state,
        shouldOpenSortMenu: false,
        sortBy: DefaultSortingKey.ValueDesc,
      };

    default:
      return state;
  }
};

type ISortMenuItemType = {
  id: string;
  label: string;
  shorthand: string;
  onclick?: () => void;
};

const makeSortMenuItems = (
  dispatch: Dispatch<UseSortMenuSortAction>,
  onChange?: (value: DefaultSortingKey) => void
) => {
  const onClickHandler = (sortingKey: DefaultSortingKey) => () => {
    if (onChange) {
      onChange(sortingKey);
    }
    dispatch({ type: sortingKey });
  };

  return [
    {
      label: 'Highest Value First',
      shorthand: 'Highest First',
      onclick: onClickHandler(DefaultSortingKey.ValueDesc),
      id: DefaultSortingKey.ValueDesc,
    },
    {
      label: 'Lowest Value First',
      shorthand: 'Lowest First',
      onclick: onClickHandler(DefaultSortingKey.ValueAsc),
      id: DefaultSortingKey.ValueAsc,
    },
    {
      label: 'Alphabetical A > Z',
      shorthand: 'A > Z',
      onclick: onClickHandler(DefaultSortingKey.Az),
      id: DefaultSortingKey.Az,
    },
    {
      label: 'Alphabetical Z > A',
      shorthand: 'Z > A',
      onclick: onClickHandler(DefaultSortingKey.Za),
      id: DefaultSortingKey.Za,
    },
  ];
};

interface IUseSortMenuProps {
  items?: ISortMenuItemType[];
  defaultItem?: string;
  sortReducer?;
  itemsMergePolicy?: 'override' | 'extend';
  onChange?: (value: string) => void;
}

interface IUseSortMenuReturnType {
  items: ISortMenuItemType[];
  buttonProps;
  dropdownMenuProps;
  getItemProps: (itemConfig: { item: ISortMenuItemType }) => {
    onClick: () => void;
    onKeyDown: (e: KeyboardEvent<HTMLButtonElement>) => void;
  };
  state: SortMenuState & { activeItem: ISortMenuItemType | undefined };
}

/**
 * Provides the logic to create a sort menu
//  * TODO WIP
 */
export const useSortMenu = (
  config?: IUseSortMenuProps
): IUseSortMenuReturnType => {
  const sortDropdownRef = useRef<HTMLDivElement>(null);
  const anchorElementRef = useRef<HTMLButtonElement | null>(null);

  const [state, dispatch] = useReducer(
    sortReducer,
    getInitialState(config?.defaultItem)
  );
  const { shouldOpenSortMenu } = state;

  const SORT_MENU_ITEMS =
    config?.items || makeSortMenuItems(dispatch, config?.onChange);
  const activeItem = SORT_MENU_ITEMS.find((item) => item.id === state.sortBy);

  const buttonProps = {
    ref: anchorElementRef,
    onClick: () => {
      dispatch({ type: 'TOGGLE_MENU' });
    },
    onKeyDown: ({ key }) => {
      if (key === 'Enter') {
        dispatch({ type: 'TOGGLE_MENU' });
      }
    },
  };

  const dropdownMenuProps = {
    show: shouldOpenSortMenu,
    clickOutsideRef: sortDropdownRef,
    onClickOutside: () => {
      dispatch({ type: 'CLOSE_MENU' });
    },
    anchorElementRef: anchorElementRef,
  };

  const getItemProps = useCallback(
    (itemConfig: { item: ISortMenuItemType }) => {
      const { item } = itemConfig;

      return {
        onClick: () => {
          if (config?.onChange) {
            config.onChange(item.id);
          }

          dispatch({ type: 'UPDATE_SORT_ORDER', payload: item.id });
        },
        onKeyDown: ({ key }) => {
          if (key === 'Enter') {
            dispatch({ type: 'UPDATE_SORT_ORDER', payload: item.id });
          }
        },
      };
    },
    []
  );

  return {
    items: SORT_MENU_ITEMS,
    buttonProps,
    dropdownMenuProps,
    getItemProps,
    state: { ...state, activeItem },
  };
};
