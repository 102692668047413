import { DateRangeArray } from '@tapestry/types';
import React from 'react';
import {
  FormInputBase,
  IFormInputBaseProps,
} from '../FormInputBase/FormInputBase';

// !
// !
// !
// ! REDO THIS COMPONENT
// !
// !
// !

interface IDatePickerProps extends IFormInputBaseProps {
  defaultStartDate: string | null;
  // defaultEndDate: string | null;
  // onSelection: (dates: DateRange) => void;
}

const parseJSDateToISOString = (date: Date | null) => {
  if (!date) return null;

  // This currently takes user datetime and set to isostring
  // We might want to take shop timezone or something else in the future.
  // @optimistic-updt needs these filters out to talk to @christopherbartlett
  return date.toISOString();
};

export const FormDatePicker = React.forwardRef<HTMLElement, IDatePickerProps>(
  (props, ref) => {
    /**
     * A the rest of the app deals with isostring, we are parsing the javascript `Date` string returned by `CalendarPicker` into isoString and vice-versa.
     * Eventually this should be done INSIDE CalendarPicker but @optimistic_updt did not have time nor the brain power to go and refactor: a) the `CalendarPicker` & b) the `HeartbeatDateRangeSelector`
     */

    const handleOnSelection = (dates: DateRangeArray) => {
      const [startDate, endDate] = dates;

      const isoStringDateRange = {
        start: parseJSDateToISOString(startDate),
        end: parseJSDateToISOString(endDate),
      };

      // props.onSelection(isoStringDateRange);
    };

    return (
      <FormInputBase {...props}>
        {/* <CalendarPicker
          inline={false}
          {...props}
          onSelection={handleOnSelection}
          defaultStartDate={
            props.defaultStartDate ? new Date(props.defaultStartDate) : null
          }
          defaultEndDate={
            props.defaultEndDate ? new Date(props.defaultEndDate) : null
          }
        /> */}
      </FormInputBase>
    );
  }
);
