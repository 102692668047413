import { ErrorBoundary } from '@tapestry/shared/client';
import { ModalActionButton } from '../modal-action-button/ModalActionButton';
import {
  Button,
  Checkbox,
  MainHeading,
  ModalOverlayAndPanel,
  Stack,
} from '@tapestry/weave';
import React, { FC, FormEventHandler } from 'react';
import { OverlayContainer } from 'react-aria';
import { Control, Controller } from 'react-hook-form';
import clx from 'classnames';
import { useInView } from 'react-intersection-observer';

const OBS_CONFIG = {
  rootMargin: `0px 0px -1px 0px`,
  threshold: 1,
};

interface IAppletFilterModalWrapperProps {
  handleResetFilters: VoidFunction;
  onClose: VoidFunction;
  onSubmit: FormEventHandler<HTMLFormElement>;
  control: Control<{ setHasDefaultFilters: boolean }, any>;
}

export const AppletFilterModalWrapper: FC<
  React.PropsWithChildren<IAppletFilterModalWrapperProps>
> = ({ children, handleResetFilters, onClose, onSubmit, control }) => {
  const [footerRef, notSticky] = useInView(OBS_CONFIG);

  return (
    <ErrorBoundary errorView={null}>
      <OverlayContainer>
        <ModalOverlayAndPanel
          isOpen
          title="filters"
          onClose={onClose}
          spacing="none"
        >
          <form onSubmit={onSubmit}>
            <MainHeading>Filters</MainHeading>

            <div className="px-4 md:px-8">
              <Stack spacing="large" hasTopMargin>
                {children}
              </Stack>
            </div>

            <div
              ref={footerRef}
              className={clx(
                'sticky bottom-0 z-10 translate-y-4 rounded-b-lg bg-white pt-8 transition-shadow duration-300',
                !notSticky && 'shadow-top'
              )}
            >
              <Controller
                control={control}
                name="setHasDefaultFilters"
                render={({ field: { value, onChange } }) => (
                  <Checkbox defaultSelected={value} onChange={onChange}>
                    Set as default filters
                  </Checkbox>
                )}
              />
              <ModalActionButton
                isLoading={false}
                primaryButtonText="Apply Filters"
                horizontal
                secondaryButtonAs={
                  <Button status="basic" onClick={handleResetFilters}>
                    Reset
                  </Button>
                }
              />
            </div>
          </form>
        </ModalOverlayAndPanel>
      </OverlayContainer>
    </ErrorBoundary>
  );
};
