import React from 'react';

/**
 * A simple low black opacity overlay
 *
 * Apply `position:relative` to parent and possible sibling if required
 * @param rounded - use to round the borders to fit parents
 */
export const Overlay = ({
  rounded,
}: {
  rounded?: `rounded-${string}`;
}): JSX.Element => {
  return <div className={`absolute inset-0 bg-black opacity-25 ${rounded}`} />;
};
