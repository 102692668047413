import React, { FC } from 'react';
import { DropdownMenu, Pill } from '@tapestry/weave';
import { SortDownUpIcon } from '@tapestry/shared/icons';
import { useEffectAfterMount, useSortMenu } from '@tapestry/shared/hooks';

interface IHeaderSortFilterProps {
  onChange?: (sort: string) => void;
  sortOptions?: Array<{ label: string; id: string; shorthand: string }>;
}

const SORT_MENU_ITEMS = [
  {
    label: 'Alphabetical A > Z',
    shorthand: 'A > Z',
    id: 'a_z',
  },
  {
    label: 'Alphabetical Z > A',
    shorthand: 'Z > A',
    id: 'z_a',
  },
  {
    label: 'Newest First',
    shorthand: 'Newest First',
    id: 'newest',
  },
  {
    label: 'Oldest First',
    shorthand: 'Oldest First',
    id: 'oldest',
  },
];

export const HeaderSortFilter: FC<IHeaderSortFilterProps> = ({
  onChange,
  sortOptions = SORT_MENU_ITEMS,
}) => {
  const {
    items,
    buttonProps,
    dropdownMenuProps,
    getItemProps,
    state: { sortBy, activeItem },
  } = useSortMenu({
    items: sortOptions,
    defaultItem: sortOptions[0].id,
  });
  const { clickOutsideRef } = dropdownMenuProps;

  useEffectAfterMount(() => {
    onChange && onChange(sortBy);
  }, [sortBy]);

  return (
    <div className="relative" ref={clickOutsideRef}>
      <button {...buttonProps}>
        <span className="sr-only">Sort</span>
        <Pill
          iconLeft={<SortDownUpIcon />}
          label={activeItem?.shorthand || ''}
          size="xxl"
          textStyle="capitalize"
          bgColor="bg-white"
          className="brea border-gray-border whitespace-nowrap border text-black"
        />
      </button>

      <DropdownMenu {...dropdownMenuProps} menuClassName="py-2" noTopMargin>
        <h6 className="text-gray-text mt-1 pl-3 text-left text-sm">Sort by</h6>

        {items.map((item) => (
          <button
            key={item.id}
            className={`hover:bg-gray-hover
                focus:bg-gray-hover active:bg-gray-active w-full rounded-md px-3 py-1 text-left text-base text-black focus:outline-none ${
                  item.id === sortBy ? 'text-orange' : ''
                }`}
            {...getItemProps({ item: item })}
          >
            {item.label}
          </button>
        ))}
      </DropdownMenu>
    </div>
  );
};
