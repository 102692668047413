import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { Env } from '@tapestry/types';
import { hasPermissions } from '@tapestry/shared/utils';
import { SidebarView } from '@tapestry/shared/constants';
import { THREAD_TYPE } from '@tapestry/types';
import {
  ThreadTypeThemeColors,
  ThreadTypeToThemeColorMapper,
} from './thread-color-theme-map';

export type UIContextState = {
  isMobileApp: boolean;
  shouldShowNavbarSettingMenu: boolean;
  shouldShowOnboardingCarousel: boolean;
  threadTypeThemeColors: ThreadTypeThemeColors;
  sidebarIsOpen: SidebarView | null;
  isAppInitialised: boolean;
  shouldShowAnnouncement: boolean;
  showNavbar: boolean;
};

export type UIContextActions =
  | { type: 'SET_MOBILE_BROWSING' }
  | { type: 'UNSET_MOBILE_BROWSING' }
  | { type: 'SHOW_NAVBAR' }
  | { type: 'HIDE_NAVBAR' }
  | { type: 'SHOW_NAVBAR_SETTING_MENU' }
  | { type: 'HIDE_NAVBAR_SETTING_MENU' }
  | { type: 'SHOW_ONBOARDING_CAROUSEL' }
  | { type: 'HIDE_ONBOARDING_CAROUSEL' }
  | { type: 'UPDATE_THREAD_THEME_COLORS'; payload: THREAD_TYPE }
  | { type: 'OPEN_SIDEBAR'; payload: SidebarView }
  | { type: 'CLOSE_SIDEBAR' }
  | {
      type: 'SET_APP_INITIALISED';
      payload?: { shouldShowAnnouncement: boolean };
    }
  | {
      type: 'UPDATE_SHOULD_SHOW_ANNOUNCEMENT';
      payload: boolean;
    };

export type UIContextDispatch = (action: UIContextActions) => void;
type UIContextValue = [UIContextState, UIContextDispatch];

const initialState: UIContextState = {
  isMobileApp: false,
  shouldShowNavbarSettingMenu: false,
  shouldShowOnboardingCarousel: false,
  threadTypeThemeColors: ThreadTypeToThemeColorMapper[THREAD_TYPE.SHOP] || {
    backgroundColor: 'bg-orange',
    altBackgroundColor: 'bg-tangerine',
    backgroundColorHover: 'bg-tangering-dark',
    textColor: 'text-orange',
  },
  sidebarIsOpen: null,
  isAppInitialised: false,
  shouldShowAnnouncement: false,
  showNavbar: true,
};

const UIReducer = (
  state: UIContextState = initialState,
  action: UIContextActions
) => {
  switch (action.type) {
    case 'SET_MOBILE_BROWSING':
      return { ...state, isMobileApp: true };

    case 'UNSET_MOBILE_BROWSING':
      return { ...state, isMobileApp: false };

    case 'SHOW_NAVBAR':
      return { ...state, showNavbar: true };

    case 'HIDE_NAVBAR':
      return { ...state, showNavbar: false };

    case 'SHOW_NAVBAR_SETTING_MENU':
      return { ...state, shouldShowNavbarSettingMenu: true };

    case 'HIDE_NAVBAR_SETTING_MENU':
      return { ...state, shouldShowNavbarSettingMenu: false };

    case 'SHOW_ONBOARDING_CAROUSEL':
      return { ...state, shouldShowOnboardingCarousel: true };

    case 'HIDE_ONBOARDING_CAROUSEL':
      return { ...state, shouldShowOnboardingCarousel: false };

    case 'OPEN_SIDEBAR':
      return { ...state, sidebarIsOpen: action.payload };

    case 'CLOSE_SIDEBAR':
      return { ...state, sidebarIsOpen: null };

    case 'UPDATE_THREAD_THEME_COLORS': {
      let newThemeColors = state.threadTypeThemeColors;
      if (action.payload) {
        newThemeColors =
          ThreadTypeToThemeColorMapper[action.payload] ??
          state.threadTypeThemeColors;
      }

      return {
        ...state,
        threadTypeThemeColors: newThemeColors,
      };
    }

    case 'SET_APP_INITIALISED':
      return {
        ...state,
        isAppInitialised: true,
        ...action.payload,
      };

    case 'UPDATE_SHOULD_SHOW_ANNOUNCEMENT': {
      return {
        ...state,
        shouldShowAnnouncement: action.payload,
      };
    }

    default:
      throw new Error(`UIReducer: Invalid action type`);
  }
};

const UIContext = createContext<UIContextValue | undefined>(undefined);

export const UIContextProvider = ({ children }: any) => {
  const [UIState, dispatch] = useReducer(UIReducer, initialState);
  const value: UIContextValue = [UIState, dispatch as UIContextDispatch];

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
};

export const useUIContext = (): UIContextValue => {
  const context = useContext(UIContext);

  if (context === undefined) {
    throw new Error('useUIContext must be inside UIContextProvider');
  }

  return context;
};
