import { CalendarIcon } from '@tapestry/shared/icons';
import React, { FC, KeyboardEvent, useRef, useState } from 'react';
import { Calendar, ICalProps } from '../../Calendar';
import { usePopper } from 'react-popper';
import { Transition } from '@headlessui/react';
import { dateTime } from '@tapestry/shared/utils';
import { IsoString } from '@tapestry/types';
import { useOnClickOutside } from '@tapestry/shared/hooks';
import { FocusScope } from 'react-aria';

/**
 * TODO
 * - Accessibility - https://www.w3.org/WAI/ARIA/apg/example-index/dialog-modal/datepicker-dialog
 */

interface IInputProps {
  ref: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

interface IToggleButtonProps {
  'aria-label': string;
  onClick: () => void;
  onKeyPress: (e: KeyboardEvent) => void;
  type: 'button';
}

interface IDatePickerProps extends Omit<ICalProps, 'value' | 'onChange'> {
  children?:
    | ((props: {
        inputProps: IInputProps;
        toggleButtonProps: IToggleButtonProps;
      }) => React.ReactNode)
    | React.ReactNode;
  value: IsoString | null;
  onChange: (date: IsoString) => void;
}

const POSSIBLE_DATE_FORMATS = [
  'DD/MM/YYYY',
  'DDMMYYYY',
  'DD/MM/YY',
  'DD/MM/',
  'DD/MM',
  'DD/M',
  'DD/',
  'DD',
  'D',
  'DDM',
  'DDMM',
  'DDMMYY',
];

/**
 * A Calendar picker
 *
 * Use to pick a single date
 *
 * If you are looking to pick a range of dates, use the `DateRangePicker`
 *
 * @return ISOstring - '2022-11-03T00:00:00.000Z'
 */
export const DatePicker: FC<React.PropsWithChildren<IDatePickerProps>> = ({
  children,
  ...calendarProps
}) => {
  const { value, onChange } = calendarProps;
  const [shouldShowCalendar, setShouldShowCalendar] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const wrapperRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(wrapperRef, () => setShouldShowCalendar(false));
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  );
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  // Currently unused, but we may want to use this in the future
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    // modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
  });

  /**
   * Functions
   */

  const handleToggleCalendar = () => {
    setShouldShowCalendar((currState) => !currState);
  };

  const handleTypeDate = (e) => {
    setInputValue(e.target.value);

    const formatedDate = dateTime.parse(e.target.value, POSSIBLE_DATE_FORMATS);
    if (formatedDate.isValid()) {
      onChange(formatedDate.toISOString() as IsoString);
    }
  };

  const handleSelectDate = (date: IsoString) => {
    onChange(date);
    setInputValue(dateTime.format(date, 'DD/MM/YYYY'));
    setShouldShowCalendar(false);
  };

  const inputProps: IInputProps = {
    value: inputValue,
    onChange: handleTypeDate,
    ref: setReferenceElement,
  };

  const toggleButtonProps: IToggleButtonProps = {
    'aria-label': `Change Date, ${value ? new Date(value) : new Date()}`,
    type: 'button',
    onClick: handleToggleCalendar,
    onKeyPress({ key }) {
      if (key === 'Enter') {
        handleToggleCalendar();
      }
    },
  };

  return (
    <div ref={wrapperRef}>
      {/* If children is simple react node, render children */}
      {children && typeof children !== 'function' ? children : null}

      {/* If children are a render prop, call children with internal props */}
      {children && typeof children === 'function'
        ? children({ inputProps, toggleButtonProps })
        : null}

      {/* Else if no children, render default input */}
      {!children ? (
        <div className="relative">
          <input
            type="text"
            placeholder="DD/MM/YYYY"
            className="border-gray-border pl-15 text-gray-text placeholder-gray-text ring-orange-hank focus-within:border-orange-hank relative inline-flex w-full items-center rounded-md border p-3 text-base uppercase focus-within:ring-1 focus:outline-none disabled:cursor-not-allowed disabled:bg-white sm:text-xl"
            {...inputProps}
          />

          <button
            {...toggleButtonProps}
            className="absolute left-0 top-1/2  ml-3 flex h-7 w-7 -translate-y-1/2 items-center justify-center p-2 text-black sm:h-10 sm:w-10 sm:p-3"
          >
            <CalendarIcon fillColor="currentColor" />
          </button>
        </div>
      ) : null}

      {/* Calendar Popover */}
      {shouldShowCalendar && (
        <div
          ref={setPopperElement}
          style={styles.popper}
          className="z-10"
          {...attributes.popper}
        >
          <Transition
            appear
            show={shouldShowCalendar}
            enter="transition transform duration-200"
            enterFrom="opacity-0 scale-90 -translate-y-8"
            enterTo="opacity-100 scale-100 -translate-y-0"
            leave="transition duration-150"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-90"
          >
            {/* TODO TO BE CONTINUED */}
            <FocusScope contain restoreFocus>
              <Calendar
                {...calendarProps}
                value={calendarProps.value as ICalProps['value']}
                onChange={handleSelectDate as ICalProps['onChange']}
              />
            </FocusScope>
          </Transition>
        </div>
      )}
    </div>
  );
};
